import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, Suspense } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import {
  Button,
  Scrollbars,
  Resource,
  ResourceIntro,
  ResourceGroup,
  UtilityInlineGrid,
  Icon,
} from 'rhinostyle';
import { getLoggedInUser } from '../selectors/userSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import ContactFilters from './ContactFilters';
import { PermissionHelpers } from '../helpers';
import ContactListPanel from './ContactListPanel';
import { CONTACT_PAGE_OPTIONS, CONTACT_RESULT_SIZE, BULK_TAG_MAX_CONTACTS } from '../constants/AppConstants';
import PageLoader from './PageLoader';
import { fetchContactList } from '../reducers/userReducer';
import EmptyMessage from './EmptyMessage';
import ContactLibraryPanel from './ContactLibraryPanel';
import BulkSelect from './BulkSelect';
import { getBulkSelectActions } from '../helpers/BulkActionHelpers';
import { PANEL_OPTIONS } from '../constants/ThreadConstants';
import FilterPage from './FilterPage';
import Contact from './Contact';
import BulkTagModal from './BulkTagModal';
import { useContactFilters } from '../hooks';
import { fetchChannelsView } from '../reducers/channelReducer';

const ContactProfilePanel = React.lazy(() => import('./ContactProfilePanel'));

const Contacts = (props) => {
  const {
    contactList,
    isBulkMessagingEnabled,
    listLoading,
    usersLoading,
    isCcr,
    pageLoading,
  } = props;
  const contactBulkActions = getBulkSelectActions(true);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const contactListRef = useRef();
  const allSelected = useRef(null);
  const activeKey = location.pathname.includes('/contacts') ? CONTACT_PAGE_OPTIONS.contacts : CONTACT_PAGE_OPTIONS.contactList;

  const {
    filters,
    filtersApplied,
    handleSetFilters,
    clearAllFilters,
    handleChange,
    fetchAllResults,
    isFetching,
    isLoading,
    searchData: { users, totalCount: totalUserCount } = {},
  } = useContactFilters(activeKey === CONTACT_PAGE_OPTIONS.contactList);

  const activeUser = users?.find((user) => user.id === Number(params?.userId));
  const [selectAll, setSelectAll] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const getAllResults = async (nextPageNumber = 0, searchAfter = null) => {
    try {
      const result = await fetchAllResults(nextPageNumber, searchAfter);
      if (selectAll) {
        setSelectedContacts((prevResults) => [...prevResults, ...result.data?.users]);

        if (result.data.users?.length === BULK_TAG_MAX_CONTACTS) {
          await getAllResults(0, result.data.searchAfter);
        } else {
          setSelectAll(false);
        }
      }
    } catch (error) {
      setSelectAll(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectAll) {
      getAllResults();
    }
  }, [selectAll]);

  function handleSelectAllResults() {
    if (totalUserCount <= CONTACT_RESULT_SIZE) {
      setSelectedContacts(users);
    } else {
      setSelectAll(true);
    }
  }

  const [currentlyOpenSidePanelId, setCurrentlyOpenSidePanelId] = useState(PANEL_OPTIONS.profile);
  const activeList = contactList.filter((list) => list.id === Number(params.contactListId))[0] || {};
  const [panelOpen, setPanelOpen] = useState(!!params.userId || !!params.contactListId);
  const historyProps = {
    location,
    history,
  };
  const path = location.pathname.substring(1).split('/')[0];

  useEffect(() => {
    if (activeKey !== CONTACT_PAGE_OPTIONS.contactList) {
      dispatch(fetchChannelsView());
    } else {
      dispatch(fetchContactList());
    }
  }, []);

  useEffect(() => {
    setPanelOpen(!!params.userId || !!params.contactListId);
    setCurrentlyOpenSidePanelId(PANEL_OPTIONS.profile);
  }, [params.userId, params.contactListId]);

  function handlePagination(type) {
    const nextPageNumber = type === 'previous' ? filters.pageNo - 1 : filters.pageNo + 1;
    handleChange('pageNo', nextPageNumber);
  }

  function isSelected(selected) {
    return !!selectedContacts?.find(((item) => item.id === selected.id));
  }

  function getUnselectedOnPage() {
    return users.filter((selectedContact) => !selectedContacts.some((selected) => selectedContact.id === selected.id));
  }

  function handleSelectAllOnPage() {
    setSelectedContacts((current) => [...new Set([...current, ...users])]);
  }

  function handleSelect(selected) {
    if (isSelected(selected)) {
      setSelectedContacts((current) => current.filter((item) => item.id !== selected.id));
    } else {
      setSelectedContacts((current) => [...new Set([...current, selected])]);
    }
  }

  function handleTogglePanel(id, key) {
    if (typeof key === 'number') {
      const shouldClose = panelOpen && Number(params.contactListId) === id;

      if (shouldClose) {
        history.push('/contact-list');
      } else {
        history.push(`/contact-list/${id}`);
      }
    } else {
      const shouldClose = panelOpen && Number(params.userId) === id;
      if (shouldClose) {
        history.push('/contacts');
      } else {
        setCurrentlyOpenSidePanelId(PANEL_OPTIONS.profile);
        history.push(`/contacts/${id}`);
      }
    }
  }

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': panelOpen,
  });

  const headerClasses = cx('app-page__header', {
    'u-m-b-0': !PermissionHelpers.hasContactImportPermission(),
  });

  const renderContact = (contact) => (
    <Contact
      contactId={contact.id}
      key={contact.id}
      contact={contact}
      hasCheckbox={!!contactBulkActions.length}
      handleSelect={handleSelect}
      isChecked={isSelected(contact)}
      disabled={usersLoading}
      page={filters.pageNo}
    />
  );

  const renderFilters = () => (
    <ContactFilters
      handleChange={handleChange}
      filters={filters}
      disabled={!!selectedContacts.length}
      handleSetFilters={handleSetFilters}
    />
  );
  const renderContacts = () => (
    <div className="list-panel__outer__body">
      {PermissionHelpers.hasContactImportPermission() &&
      (
        <div className="app-page__header__title__action u-m-b">
          <UtilityInlineGrid align="middle">
            {isCcr && (
              <Link to="/contacts/import/appointments">
                <Button reset type="link">
                  Import Appointments
                </Button>
              </Link>
            )}
            <Link to="/contacts/import/users">
              <Button reset type="link" className="u-m-l">
                Import Contacts
              </Button>
            </Link>
            <Link to="/contacts/import/connectedParties">
              <Button reset type="link" className="u-m-l">
                Connect Contacts
              </Button>
            </Link>
          </UtilityInlineGrid>
        </div>
      )}
      {PermissionHelpers.hasContactViewPermission() && (
        <>
          <FilterPage
            header="Total Contacts"
            totalCount={totalUserCount}
            showClearAll={filtersApplied && !selectedContacts.length}
            clearAllFilters={clearAllFilters}
            pageNumber={filters.pageNo}
            loading={usersLoading}
            handlePagination={handlePagination}
            pageItemCount={users?.length}
            type="contacts"
            renderFilters={renderFilters}
            hasPanels
            paginationDisabled={false}
            showPageHeader
          >
            <>
              <BulkSelect
                selectedItems={selectedContacts}
                pageItems={users || []}
                handleSelect={setSelectedContacts}
                totalCount={totalUserCount}
                disabled={usersLoading || isFetching}
                type="contacts"
                pageSize={filters.size}
                handleSelectAllResults={handleSelectAllResults}
                allSelected={allSelected}
                hidden={!contactBulkActions.length || !users?.length}
                handleSelectAll={handleSelectAllOnPage}
                handleUnselectContact={handleSelect}
                getUnselectedOnPage={getUnselectedOnPage}
                isSelectAllResultsEnabled
              />
              <div className="list-panel__body">
                {users?.length > 0 ? (
                  <ResourceGroup>
                    {users?.map(renderContact)}
                  </ResourceGroup>
                ) : (!usersLoading && (
                  <div className="contact-list__nocontacts_wrapper">
                    <EmptyMessage section="Contacts" />
                  </div>
                ))}
              </div>
            </>
          </FilterPage>
        </>
      )}
    </div>
  );

  const renderContactList = (list, key) => (
    <Resource className="list-panel__contact-list" key={key} onClick={() => handleTogglePanel(list.id, key)}>
      <ResourceIntro icon={{ icon: 'user-group' }} title={list.name}>
        Contacts ({list.users.length})
      </ResourceIntro>
    </Resource>
  );

  const renderContactSavedList = () => (
    <div className="list-panel__outer__body">
      {PermissionHelpers.hasContactListViewPermission(isCcr, isBulkMessagingEnabled) && (
        <div className="list-panel__body contact-section">
          {activeKey === CONTACT_PAGE_OPTIONS.contactList && contactList.length > 0 && (
          <Scrollbars className="list-panel__body__scroll" ref={contactListRef}>
            <ResourceGroup>
              {contactList.map(renderContactList)}
            </ResourceGroup>
          </Scrollbars>
          )}
          {!contactList.length && !listLoading && (
          <div className="contact-list__nocontacts_wrapper">
            <EmptyMessage section="ContactList" />
          </div>
          )}
        </div>
      )}
    </div>
  );

  const renderAddContactBtn = () => (
    <div className="app-page__header__action">
      <Button
        type="secondary"
        onClick={() => history.push('/contacts/create')}
        data-cypress="addContact"
      >
        <Icon icon="add" />
      </Button>
    </div>
  );

  const renderAddSavedListBtn = () => (
    <div className="app-page__header__action">
      <Button
        type="secondary"
        onClick={() => history.push('/contact-list/create')}
        data-cypress="createList"
      >
        <Icon icon="add" />
      </Button>
    </div>
  );

  function udpateContactSidePanel() {
    setCurrentlyOpenSidePanelId((current) => (current === PANEL_OPTIONS.profile ? PANEL_OPTIONS.savedContent : PANEL_OPTIONS.profile));
  }
  const renderSidePanels = () => {
    if (activeUser && activeKey === CONTACT_PAGE_OPTIONS.contacts) {
      if (currentlyOpenSidePanelId === PANEL_OPTIONS.profile) {
        return (
          <ContactProfilePanel
            activeUser={activeUser}
            setOpenContactLibraryPanel={udpateContactSidePanel}
            isActivePanel
            {...historyProps}
          />
        );
      } return (
        <ContactLibraryPanel
          handleCloseSavedContentLibrary={udpateContactSidePanel}
          activeUserId={activeUser.id}
        />
      );
    } if (activeList && activeKey === CONTACT_PAGE_OPTIONS.contactList) {
      return <ContactListPanel activeList={activeList} panelOpen={panelOpen} {...historyProps} />;
    } return null;
  };

  if (pageLoading || isLoading) {
    return <PageLoader />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <div className="app-page app-page--contact">
        <div className={appPanelClasses}>
          <div className="list-panel__wrapper">
            <div className="list-panel" data-cypress="listPanel">
              <div className={headerClasses}>
                <div className="app-page__header__title" data-cypress={activeKey === CONTACT_PAGE_OPTIONS.contactList ? 'contactLists' : 'contacts'}>
                  {activeKey === CONTACT_PAGE_OPTIONS.contactList ? 'Contact Lists' : 'Contacts'}
                </div>
                {activeKey === CONTACT_PAGE_OPTIONS.contacts && PermissionHelpers.hasContactCreatePermission() && (renderAddContactBtn())}
                {activeKey === CONTACT_PAGE_OPTIONS.contactList && PermissionHelpers.hasContactListEditPermission(isBulkMessagingEnabled) && (renderAddSavedListBtn())}
              </div>
              {path === 'contacts' ? renderContacts() : renderContactSavedList()}
            </div>
          </div>
          {renderSidePanels()}
        </div>
        <BulkTagModal
          filteredTagIds={filters.tagIds}
          selectedUserIds={selectedContacts.map((contact) => contact.id)}
          clearAllFilters={clearAllFilters}
        />
      </div>
    </Suspense>
  );
};

Contacts.propTypes = {
  contactList: PropTypes.array,
  isBulkMessagingEnabled: PropTypes.bool,
  listLoading: PropTypes.bool,
  pageLoading: PropTypes.bool.isRequired,
  usersLoading: PropTypes.bool,
  users: PropTypes.object,
  currentOrg: PropTypes.number,
  isCcr: PropTypes.bool,
  totalUserCount: PropTypes.number,
  pageNo: PropTypes.number,
  page: PropTypes.number,
};

const mapStateToProps = (state) => {
  const { user, channel } = state;

  return {
    isBulkMessagingEnabled: getCurrentOrg(state)?.isBulkMessagingEnabled,
    currentOrg: state.auth.currentOrg,
    pageLoading: channel.loading,
    users: user.users,
    contactList: user.contactList,
    totalUserCount: user.totalUserCount,
    usersLoading: user.contactUsersLoading,
    isCcr: getLoggedInUser(state)?.isCcr,
    pageNo: user.pageNo,
    page: user.contactPage,
  };
};

export default connect(mapStateToProps)(Contacts);
