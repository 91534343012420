import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import {
  Button,
  DropdownCheckbox,
  Dropdown,
  DropdownMenuItem,
} from 'rhinostyle';
import { MODAL_OPTIONS } from '../constants/AppConstants';
import BulkMessageModal from './BulkMessageModal';
import { toggleModal } from '../reducers/uiReducer';
import { getBulkSelectActions } from '../helpers/BulkActionHelpers';
import { getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';

const BulkSelect = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isBulkMessageModalOpen = useSelector((state) => state.ui.isBulkMessageModalOpen);
  const users = useSelector((state) => state.user.users);
  const maxRhinoblastCount = useSelector(getRhinoblastMaxPageSize);
  const {
    pageItems,
    disabled,
    handleGetContacts,
    handleSelect,
    handleSelectAll,
    handleUnselectContact,
    selectedItems,
    totalCount,
    type,
    selectAllLabel,
    hidden,
    isSelectAllResultsEnabled,
    getUnselectedOnPage,
    handleActionSelect,
  } = props;
  const isContacts = type === 'contacts';
  const isConversationActivity = type === 'conversationActivity';

  const maxExceeded = selectedItems?.length > maxRhinoblastCount;

  const bulkActions = getBulkSelectActions(isContacts, isConversationActivity);

  function handleSelectAllOnPage() {
    if (getIsChecked()) {
      handleClearSelected();
    } else if (handleSelectAll) {
      handleSelectAll();
    } else {
      // Otherwise, select all on page
      handleSelect((current) => [...new Set([...current, ...pageItems])]);
    }
  }

  function handleClearSelected() {
    handleSelect([]);
  }

  function unselectContact(user) {
    if (handleUnselectContact) {
      handleUnselectContact(user);
    } else {
      handleSelect((current) => current.filter((selectedId) => selectedId !== user.id));
    }
  }

  function handleSelectAction(action) {
    if (typeof handleActionSelect === 'function') {
      handleActionSelect(action);
    } else if (action.type === 'modal') {
      dispatch(toggleModal(MODAL_OPTIONS[action.code]));
    }
  }

  function toggleBulkMessageModal() {
    dispatch(toggleModal(MODAL_OPTIONS.bulkMessage));
  }

  function getContacts() {
    if (type === 'appointments' || type === 'prescriptions') {
      return handleGetContacts();
    }
    if (type === 'contacts') {
      return selectedItems;
    }
    const userIds = handleGetContacts ? handleGetContacts(selectedItems) : selectedItems;
    return userIds.map((id) => users[id]);
  }

  function renderLabel() {
    if (isSelectAllResultsEnabled) {
      const selectLabel = selectAllLabel || 'Select All On Page';
      return (
        <div data-feature-tag="bulkSelectText" className="bulk-select__text">
          <span>{selectedItems?.length > 0 ? `${selectedItems.length} of ${totalCount} Selected` : selectLabel}</span>
          {maxExceeded && (<div className="u-text-danger bulk-select__text--info">{`Max of ${maxRhinoblastCount} ${maxExceeded ? 'exceeded' : 'allowed'}`}</div>)}
        </div>
      );
    }
    const contactCount = getContacts().length;
    const labelClass = cx('bulk-select__text--info', {
      'u-text-danger': maxExceeded,
      'u-text-muted': !maxExceeded,
    });
    return (
      <div data-feature-tag="bulkSelectText" className="bulk-select__text">
        <div>{`${contactCount} of ${maxRhinoblastCount} Contacts Selected`}</div>
        <div className={labelClass}>{`Max of ${maxRhinoblastCount} ${maxExceeded ? 'exceeded' : 'allowed'}`}</div>
      </div>
    );
  }

  function renderSelectClearAll() {
    if (totalCount > 0 && selectedItems.length > 0) {
      return (
        <>
          <div className="bulk-select__divider" />
          <Button
            data-feature-tag="bulkSelectClear"
            type="link"
            className="bulk-select__clear"
            onClick={handleClearSelected}
          >
            Clear All Selected
          </Button>
        </>
      );
    } return null;
  }

  const renderSelectActions = () => {
    if (isContacts) {
      return (
        <Dropdown
          label="Select an Action"
          name="bulkActionArray"
          type="primary"
          position="right"
          dataFeatureTag="bulkSelectAction"
          outlined
          wide
          className="bulk-select__action__dropdown"
          disabled={disabled}
        >
          {bulkActions.map((action) => (
            <DropdownMenuItem
              key={action.code}
              id={action.code}
              label={action.label}
              labelDesc={selectedItems?.length > action.maxCount ? action?.errorLabel : ''}
              disabled={selectedItems?.length > action.maxCount}
              onClick={() => handleSelectAction(action)}
              dataCypress={action.dataCypress}
            />
          ))}
        </Dropdown>
      );
    }
    if (isConversationActivity) {
      return (
        <Dropdown
          label="Select an Action"
          name="bulkActionArray"
          type="primary"
          position="right"
          dataFeatureTag="bulkSelectAction"
          outlined
          wide
          className="bulk-select__action__dropdown"
          disabled={disabled}
        >
          {bulkActions.map((action) => (
            <DropdownMenuItem
              key={action.code}
              id={action.code}
              label={action.label}
              labelDesc={selectedItems?.length > action.maxCount ? action?.errorLabel : ''}
              disabled={selectedItems?.length > action.maxCount}
              onClick={() => handleSelectAction(action)}
              dataCypress={action.dataCypress}
            />
          ))}
        </Dropdown>
      );
    }

    const action = bulkActions[0];
    const selectDisabled = getContacts()?.length > action.maxCount;

    return (
      <Button
        className="appointments-button"
        type={disabled ? 'default' : 'primary'}
        disabled={selectDisabled}
        onClick={() => handleSelectAction(action)}
        data-feature-tag="messageContacts"
        outlined
      >
        {action.label}
      </Button>
    );
  };

  const bulkSelectWrapperClassName = cx('bulk-select', {
    'bulk-select--selected': selectedItems.length,
    [`bulk-select--${type}`]: type,
  });

  function getIsChecked() {
    if (selectedItems.length === 0) {
      return false;
    }

    return getUnselectedOnPage ? getUnselectedOnPage().length !== pageItems.length : true;
  }

  return !hidden ? (
    <div className={bulkSelectWrapperClassName}>
      <div className="u-flex-direction-row u-flex u-align-items-center">
        <div className="bulk-select__checkbox">
          <DropdownCheckbox
            name="dropdownCheckbox"
            type="checkbox"
            isCheckbox
            isChecked={getIsChecked()}
            onChange={handleSelectAllOnPage}
            showAssociatedLabel
            checkboxClassName={selectedItems.length === totalCount ? '' : 'partially-checked'}
            activeKey=""
            title="bulkSelect"
            hideCaret
            disabled={disabled}
            dataFeatureTag="bulkSelectCheckbox"
            reset
            labelValueAssociated={renderLabel()}
          />
        </div>
        {renderSelectClearAll()}
      </div>
      {selectedItems.length > 0 && renderSelectActions()}
      <BulkMessageModal
        contacts={getContacts()}
        isModalOpen={isBulkMessageModalOpen}
        handleToggle={toggleBulkMessageModal}
        handleChange={unselectContact}
        location={location}
        type={type}
      />

    </div>
  ) : null;
};

BulkSelect.propTypes = {
  pageItems: PropTypes.array,
  disabled: PropTypes.bool,
  handleGetContacts: PropTypes.func,
  handleSelect: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleUnselectContact: PropTypes.func,
  selectedItems: PropTypes.array,
  totalCount: PropTypes.number,
  type: PropTypes.string,
  selectAllLabel: PropTypes.string,
  hidden: PropTypes.bool,
  isSelectAllResultsEnabled: PropTypes.bool,
  getUnselectedOnPage: PropTypes.func.isRequired,
  handleActionSelect: PropTypes.func,
};

export default BulkSelect;
