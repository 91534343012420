import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  RadioGroup,
  Radio,
  Date,
  DropdownMenuDivider,
  DropdownMenuItemWild,
  ResourceRight,
  Button,
} from 'rhinostyle';
import moment from 'moment-timezone';
import { DATE_FORMAT_DEFAULT } from '../constants/DateConstants';

const DATE_DIRECTION_OPTIONS = {
  GTE: 'gte',
  LTE: 'lte',
  BETWEEN: 'between',
};

const ASSIGNMENT_DATE_DIRECTION = {
  [DATE_DIRECTION_OPTIONS.GTE]: {
    label: 'Newer than',
    filterLabel: 'Newer than',
  },
  [DATE_DIRECTION_OPTIONS.LTE]: {
    label: 'Older than',
    filterLabel: 'Older than',
  },
  [DATE_DIRECTION_OPTIONS.BETWEEN]: {
    label: 'Between',
    filterLabel: 'Between',
  },
};

const AssignmentDateDropdown = ({
  disabled,
  name,
  handleChange,
  label,
  dataFeatureTag,
  fromDate,
  toDate,
  selectedLabel = label,
}) => {
  const now = useMemo(() => moment(), []);
  const dropdownRef = useRef(null);
  const [startDate, setStartDate] = useState(fromDate ? moment(fromDate) : undefined);
  const [endDate, setEndDate] = useState(toDate ? moment(toDate) : undefined);
  const [betweenDateStart, setBetweenDateStart] = useState(null);
  const [betweenDateEnd, setBetweenDateEnd] = useState(null);

  const [dateDirection, setDateDirection] = useState(DATE_DIRECTION_OPTIONS.GTE);
  const dropdownStyle = fromDate || toDate ? 'primary' : 'input';
  const dateDirectionName = `${name}Direction`;
  const startDateName = `${name}Start`;
  const endDateName = `${name}End`;
  const isApplyEnabled = dateDirection === DATE_DIRECTION_OPTIONS.BETWEEN && betweenDateStart && betweenDateEnd;

  useEffect(() => {
    if (!toDate && !fromDate && (startDate || endDate || betweenDateStart || betweenDateEnd)) {
      handleResetDates();
    }
  }, [toDate, fromDate]);

  function handleResetDates() {
    setStartDate(null);
    setEndDate(null);
    setBetweenDateStart(null);
    setBetweenDateEnd(null);
  }

  function handleApplyBetweenDates() {
    handleChange(name, {
      [startDateName]: betweenDateStart.format(DATE_FORMAT_DEFAULT),
      [endDateName]: betweenDateEnd.format(DATE_FORMAT_DEFAULT),
    });
  }

  function handleSetDateDirection(value) {
    handleResetDates();
    handleChange(name, {
      [startDateName]: null,
      [endDateName]: null,
    });
    setDateDirection(value);
  }

  function getDateValue(date, fallbackDate) {
    if (date?.isValid()) {
      return moment(date);
    }
    if (fallbackDate) {
      return moment(fallbackDate);
    }
    return null;
  }

  const handleSetStartDate = useCallback(
    (date) => {
      const updatedDate = getDateValue(date, startDate);
      setStartDate(updatedDate);
      handleChange(name, {
        [startDateName]: updatedDate?.format(DATE_FORMAT_DEFAULT) || null,
      });
    },
    [name, startDate, startDateName, handleChange],
  );

  const handleSetEndDate = useCallback(
    (date) => {
      const updatedDate = getDateValue(date, endDate);
      setEndDate(updatedDate);
      handleChange(name, {
        [endDateName]: updatedDate?.format(DATE_FORMAT_DEFAULT) || null,
      });
    },
    [name, endDate, endDateName, handleChange],
  );

  const handleBetweenDateStartChange = (date) => {
    const from = getDateValue(date, betweenDateStart);
    let to = betweenDateEnd ? moment(betweenDateEnd) : moment(from);
    setBetweenDateStart(from);

    if (from.isAfter(to)) {
      to = from.clone();
      setBetweenDateEnd(to);
    }
  };

  const handleBetweenDateEndChange = (date) => {
    const to = getDateValue(date, betweenDateEnd);
    setBetweenDateEnd(to);
    let from = betweenDateStart ? moment(betweenDateStart) : moment(to);

    if (from.isAfter(to)) {
      from = to.clone();
      setBetweenDateStart(from);
    }
  };

  function getLabel() {
    if (fromDate || toDate) {
      const dateDirectionLabel = ASSIGNMENT_DATE_DIRECTION[dateDirection]?.filterLabel || '';

      if (dateDirectionLabel === 'Between' && fromDate && toDate) {
        return `${selectedLabel || label}: ${moment(fromDate).format('MMM DD, YYYY')} - ${moment(toDate).format('MMM DD, YYYY')}`;
      } else if (fromDate && dateDirectionLabel === 'Newer than') {
        return `${selectedLabel || label}: ${dateDirectionLabel} ${moment(fromDate).format('MMM DD, YYYY')}`;
      } else if (toDate && dateDirectionLabel === 'Older than') {
        return `${selectedLabel || label}: ${dateDirectionLabel} ${moment(toDate).format('MMM DD, YYYY')}`;
      }
    } return label;
  }

  const renderDatePicker = ({ selected, onChange, datePickerName }) => (
    <Date
      selected={selected}
      onChange={onChange}
      name={datePickerName}
      shouldCloseOnSelect
      placeholderText="Select date"
      format={DATE_FORMAT_DEFAULT}
      maxDate={now}
    />
  );

  const renderDateSelect = () => {
    if (dateDirection === DATE_DIRECTION_OPTIONS.BETWEEN) {
      return (
        <>
          <div className="date-range__label">
            Custom Range
          </div>
          <div className="row date-range__buttons">
            <div className="column-6@small">
              {renderDatePicker({ selected: betweenDateStart, onChange: handleBetweenDateStartChange, datePickerName: 'betweenDateStart' })}
            </div>
            <div className="column-6@small">
              {renderDatePicker({ selected: betweenDateEnd, onChange: handleBetweenDateEndChange, datePickerName: 'betweenDateEnd' })}
            </div>
          </div>
          <div className="menu__divider">
            <ResourceRight>
              <Button
                data-cypress="apply"
                type="primary"
                size="small"
                disabled={!isApplyEnabled}
                onClick={handleApplyBetweenDates}
              >
                Apply
              </Button>
            </ResourceRight>
          </div>
        </>
      );
    }
    if (dateDirection === DATE_DIRECTION_OPTIONS.LTE) {
      return renderDatePicker({ selected: endDate, onChange: handleSetEndDate, datePickerName: 'endDate' });
    }
    return renderDatePicker({ selected: startDate, onChange: handleSetStartDate, datePickerName: 'startDate' });
  };

  return (
    <div className="daterange__dropdown">
      <Dropdown
        label={getLabel()}
        type={dropdownStyle}
        outlined={fromDate || toDate}
        className="filter-page__dropdown filter-page__dropdown--date-range"
        disabled={disabled}
        name={name}
        blur
        autoFocusInput={false}
        position="left"
        ref={dropdownRef}
        dataFeatureTag={dataFeatureTag}
      >
        <DropdownMenuItemWild>
          <RadioGroup
            name={dateDirectionName}
            onChange={handleSetDateDirection}
            selectedValue={dateDirection}
          >
            {Object.entries(ASSIGNMENT_DATE_DIRECTION).map(([key, value]) => (
              <Radio
                value={key}
                label={value.label}
                key={key}
                className="u-p-t-small"
                dataCypress={`birthday-type-${key}`}
              />
            ))}
          </RadioGroup>
        </DropdownMenuItemWild>
        <DropdownMenuDivider />
        <DropdownMenuItemWild>
          {renderDateSelect()}
        </DropdownMenuItemWild>
      </Dropdown>
    </div>
  );
};

AssignmentDateDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  dataFeatureTag: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  selectedLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AssignmentDateDropdown;
