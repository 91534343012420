import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import NotificationService from '../services/NotificationService';
import * as UIActionTypes from '../constants/UIActionTypes';
import { getInboxContext } from '../selectors/inboxSelectors';
import threadService from '../services/threadService';

const setError = createAction(UIActionTypes.setError);

export function createBulkGroupAssignments(payload) {
  return axios.post('/bulkactions/assignGroup', payload)
    .catch((err) => console.error(err.response || err));
}

export function createBulkMemberAssignments(payload) {
  return axios.post('/bulkactions/assignMember', payload)
    .catch((err) => console.error(err.response || err));
}

export function createBulkSelfAssignments(payload) {
  return axios.post('/bulkactions/assignSelf', payload)
    .catch((err) => console.error(err.response || err));
}

export function closeAssignmentsBulk(payload) {
  return axios.post('/bulkactions/done', payload)
    .catch((err) => console.error(err.response || err));
}

export function createAssignmentMember(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignMember', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function createAssignmentGroup(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignGroup', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function createAssignmentSelf(payload, notify) {
  return (dispatch) =>
    axios.post('/assignment/assignSelf', payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        if (notify) {
          NotificationService('updateAssignment', response);
        }
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        if (notify) {
          NotificationService('updateAssignment', err.response);
        }
      });
}

export function closeAssignment(payload) {
  const route = payload.assigned ? 'closeAssigned' : 'closeDefault';
  return (dispatch) =>
    axios.post(`/assignment/${route}`, payload)
      .then((response) => {
        dispatch(threadService.util.invalidateTags(['Thread']));
        NotificationService(payload.conversationActionType, response);
      })
      .catch((err) => {
        console.error(err.response || err);

        dispatch(setError(err.response || err));

        NotificationService('updateAssignment', err.response);
      });
}

export function shapeInboxContentForCompletion(options, groupId) {
  return (dispatch, getState) => {
    const inboxContext = getInboxContext(getState());
    const payload = {
      ...options,
    };
    if (inboxContext === 'assigned') {
      payload.assigned = true;
    } else if (inboxContext === 'group') {
      payload.groupId = Number(groupId);
    } else if (inboxContext === 'direct') {
      payload.direct = true;
    }
    return dispatch(closeAssignment(payload));
  };
}

export function createBulkAssignmentSelf(payload) {
  return createBulkSelfAssignments(payload)
    .then((response) => {
      const updatedInboxCount = payload?.length || 0;
      NotificationService('bulkAssignment', response, updatedInboxCount);
    })
    .catch((err) => {
      NotificationService('bulkAssignment', err.response);
    });
}

export function createBulkAssignmentGroup(payload) {
  return createBulkGroupAssignments(payload)
    .then((response) => {
      const updatedInboxCount = payload?.length || 0;
      NotificationService('bulkAssignment', response, updatedInboxCount);
    })
    .catch((err) => {
      NotificationService('bulkAssignment', err.response);
    });
}

export function createBulkAssignmentMember(payload) {
  return createBulkMemberAssignments(payload)
    .then((response) => {
      const updatedInboxCount = payload?.length || 0;
      NotificationService('bulkAssignment', response, updatedInboxCount);
    })
    .catch((err) => {
      NotificationService('bulkAssignment', err.response);
    });
}

export function closeAssignmentsBulkAction(payload) {
  return closeAssignmentsBulk(payload)
    .then((response) => {
      const updatedInboxCount = payload?.length || 0;
      NotificationService('closeBulkConversations', response, updatedInboxCount);
    })
    .catch((err) => {
      NotificationService('closeBulkConversations', err.response);
    });
}
