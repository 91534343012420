import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as organizationSelectors from '../selectors/organizationSelectors';
import { useLastEvent, useActiveUser, useInboxParams, useSelectThreadEvents } from '.';
import { EventHelpers, ChannelHelpers, DataHelpers } from '../helpers';
import * as Types from '../constants/Types';
import { EventModelType } from '../constants/v3';
import { setInboxCommunicationContext } from '../reducers/inboxReducer';
import { isPhoneChannelTypeId, isMessageChannel } from '../helpers/ChannelHelpers';

function isSecureChannel(secureChannel) {
  if (!secureChannel) return false;
  return (secureChannel.typeId === Types.TYPE_SECURE && !secureChannel.deleted);
}

const useInboxCommunicationContext = () => {
  const dispatch = useDispatch();
  const { groupId } = useInboxParams();
  const location = useLocation();
  const userOrganization = useSelector((state) => organizationSelectors.getCurrentOrg(state));
  const { mostRecentEvent } = useLastEvent();
  const activeUser = useActiveUser();
  const loggedInUser = useSelector((state) => state.user.users[state.auth.currentUser]);
  let context;
  const channels = useSelector((state) => state.channel.channels);
  const groups = useSelector((state) => state.group.groups);
  const threadFromChannelIds = useSelector((state) => state.inbox.threadFromChannelIds);
  const phones = Object.values(useSelector((state) => state.phone.phones));
  const facebooks = useSelector((state) => state.facebook.facebooks);
  const instagrams = useSelector((state) => state.instagram.instagrams);
  const rhinograms = useSelector((state) => state.rhinogram.rhinograms);
  const selectedEventsQueryResult = useSelectThreadEvents();

  const secureEventIds = selectedEventsQueryResult?.data?.ids?.filter((id) => {
    const event = selectedEventsQueryResult?.data?.entities?.[id];
    return EventHelpers.isSecureEvent(event);
  });

  const messageEventIds = selectedEventsQueryResult?.data?.ids?.filter((id) => {
    const event = selectedEventsQueryResult?.data?.entities?.[id];
    return EventHelpers.isMessageEvent(event);
  });
  useEffect(() => {
    if (DataHelpers.hasData(context)) {
      dispatch(setInboxCommunicationContext(context));
    }
  }, [context, activeUser, mostRecentEvent?.id]);
  const mostRecentMessageEvent = EventHelpers.shapeEventToV3(selectedEventsQueryResult?.data?.entities?.[messageEventIds?.[messageEventIds.length - 1]]);
  const mostRecentSecureEvent = EventHelpers.shapeEventToV3(selectedEventsQueryResult?.data?.entities?.[secureEventIds?.[secureEventIds.length - 1]]);

  let activeToChannelId;
  let activeFromChannelId;
  let activeSecureFromChannelId;

  if (!activeUser) return null;
  const phonesSubset = phones.filter((phone) => activeUser.phones?.includes(phone.id));
  const activeUserOwnedPhones = phonesSubset.filter((phone) => phone.ownerId === activeUser.id && !phone.hasOptedOut);
  if (mostRecentMessageEvent) {
    // Events sent by the system such as opt out messaging don't contain a from or external endpoint field.
    // In this case, populate the from field based on the event's channel.
    const mostRecentChannel = channels[mostRecentMessageEvent.channelId];
    if (mostRecentChannel.typeId === Types.TYPE_SMS && !mostRecentMessageEvent?.from && !mostRecentMessageEvent?.externalEndpoint?.id) {
      // Find the channel for the message event sent by the system and use the phone number of that channel as the from value.
      // If for some reason the channel phone can't be found, we don't attempt to set the active channel via the from field and we exit.
      if (activeUserOwnedPhones.length) {
        const channelPhone = phones.find((phone) => phone.id === mostRecentChannel.details?.phone);
        if (channelPhone?.value && activeUserOwnedPhones.length) {
          mostRecentMessageEvent.from = channelPhone.value;
        }
      } else return null;
    }
    // to
    if (mostRecentMessageEvent.from) { // set to phone incoming message
      const activeToChannel = activeUserOwnedPhones.find((phone) => phone?.value === mostRecentMessageEvent.from);
      activeToChannelId = activeToChannel?.id;
      if (activeToChannelId) activeToChannelId = `sms-${activeToChannelId}`;
    } else if (mostRecentMessageEvent.phoneId) { // set to phone for outgoing message
      activeToChannelId = `sms-${mostRecentMessageEvent.phoneId}`;
    } else if (mostRecentChannel.typeId === Types.TYPE_SMS && mostRecentMessageEvent?.externalEndpoint) {
      activeToChannelId = `sms-${mostRecentMessageEvent?.externalEndpoint?.id}`;
    } else if (mostRecentChannel.typeId === Types.TYPE_FACEBOOK) {
      activeToChannelId = activeUser.facebooks.find((id) => facebooks[id]?.value === mostRecentMessageEvent.facebookExternalId);
      if (!activeToChannelId) activeToChannelId = activeUser.facebooks.find((id) => id === mostRecentMessageEvent.facebookId);
      // solves issue for merged users not finding correct to channel
      if (!activeToChannelId) activeToChannelId = activeUser.facebooks.find((id) => facebooks[id].channelId === mostRecentChannel.id);
      if (activeToChannelId) activeToChannelId = `facebook-${activeToChannelId}`;
    } else if (mostRecentChannel.typeId === Types.TYPE_INSTAGRAM) {
      activeToChannelId = activeUser.instagrams.find((id) => instagrams[id]?.value === mostRecentMessageEvent.instagramExternalId);
      if (!activeToChannelId) activeToChannelId = activeUser.instagrams.find((id) => id === mostRecentMessageEvent.instagramId);
      // solves issue for merged users not finding correct to channel
      if (!activeToChannelId) activeToChannelId = activeUser.instagrams.find((id) => instagrams[id].channelId === mostRecentChannel.id);
      if (activeToChannelId) activeToChannelId = `instagram-${activeToChannelId}`;
    } else if (mostRecentChannel?.typeId === Types.TYPE_CLASS_RHINOGRAM) {
      if (!activeToChannelId) activeToChannelId = activeUser.rhinograms.find((id) => rhinograms[id].channelId === mostRecentChannel.id);
      if (activeToChannelId) activeToChannelId = `rhinogram-${activeToChannelId}`;
    }
    // from
    if (mostRecentChannel && threadFromChannelIds.includes(mostRecentChannel.id) && isMessageChannel(mostRecentChannel)) {
      activeFromChannelId = mostRecentChannel.id;
    }
    // if last event is closed and is any default channel then set that default channel as active from.
    if (mostRecentEvent?.isComplete && threadFromChannelIds.find((id) => channels[id].isDefault === 1 && !channels[id].deleted)) {
      activeFromChannelId = threadFromChannelIds.find((id) => channels[id].isDefault === 1 && !channels[id].deleted);
    }
  }

  if (mostRecentSecureEvent) { // secure mode
    const mostRecentSecureChannel = channels[mostRecentSecureEvent.channelId];
    // from
    if (mostRecentSecureChannel
      && threadFromChannelIds.includes(mostRecentSecureChannel.id)
      && mostRecentSecureChannel.typeId === Types.TYPE_SECURE
      && !mostRecentSecureChannel.deleted
    ) {
      activeSecureFromChannelId = mostRecentSecureChannel.id;
    }
  }
  // Showing member's default channel everytime for Assign to Me inbox.
  if (location?.pathname?.includes('inbox/user') && loggedInUser?.defaultChannelId && threadFromChannelIds.includes(loggedInUser?.defaultChannelId)) {
    const { defaultChannelId } = loggedInUser;
    const channel = channels[defaultChannelId];
    if (channel) {
      if (isMessageChannel(channel)) activeFromChannelId = defaultChannelId;
    }
  }

  // all route
  // If restarting a conversation, or there has not been any so far, attempt to use the members default channel id if present and valid.
  if (!groupId && loggedInUser?.defaultChannelId && threadFromChannelIds.includes(loggedInUser?.defaultChannelId) && (mostRecentEvent?.isComplete || !mostRecentEvent)) {
    const { defaultChannelId } = loggedInUser;
    const channel = channels[defaultChannelId];
    if (channel) {
      if (isMessageChannel(channel)) activeFromChannelId = defaultChannelId;
      else if (isSecureChannel(channel)) activeSecureFromChannelId = defaultChannelId;
    }
  }

  // default if we don't have channels set by this point
  if (!activeSecureFromChannelId) activeSecureFromChannelId = threadFromChannelIds.find((id) => channels[id].typeId === Types.TYPE_SECURE && !channels[id].deleted);
  if (!activeToChannelId && activeUserOwnedPhones.length > 0) {
    // for new conversations, we want to default to a cell if available
    const toCell = activeUserOwnedPhones.find((phone) => phone && phone.typeId === Types.TYPE_CELL);
    activeToChannelId = `sms-${toCell?.id || activeUserOwnedPhones[0]?.id}`;
  }
  if (!activeToChannelId && activeUser.facebooks?.length > 0) activeToChannelId = `facebook-${activeUser.facebooks[0]}`;
  if (!activeToChannelId && activeUser.instagrams?.length > 0) activeToChannelId = `instagram-${activeUser.instagrams[0]}`;
  if (!activeToChannelId && activeUser.rhinograms?.length > 0) activeToChannelId = `rhinogram-${activeUser.rhinograms[0]}`;
  const toCommunicationId = activeToChannelId && ChannelHelpers.getCommunicationId(activeToChannelId);
  const toChannelClass = activeToChannelId ? ChannelHelpers.getChannelClass(activeToChannelId) : null;

  if (groupId) { // if in a group inbox, then try to use that group's route
    if (!activeSecureFromChannelId) {
      if (!activeToChannelId && activeUserOwnedPhones.length > 0) {
        // for new conversations, we want to default to a cell if available
        const toCell = activeUserOwnedPhones.find((phone) => phone && phone.typeId === Types.TYPE_CELL);
        activeToChannelId = `sms-${toCell.id || activeUserOwnedPhones[0]?.id}`;
      }
      activeSecureFromChannelId = threadFromChannelIds.find((id) => (
        channels[id].typeId === Types.TYPE_SECURE
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
      ));
    }

    if (userOrganization?.isDefaultGroupOutboundChannelEnabled) {
      const routedChannelId = threadFromChannelIds.find((id) => (
        isPhoneChannelTypeId(channels[id].typeId)
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
      ));

      // If restarting a conversation, or there has not been any so far, attempt to use the group's default channel id if present and valid.
      if (groups[groupId]?.defaultChannelId &&
        (mostRecentEvent?.isComplete || !mostRecentEvent || mostRecentEvent.modelType !== EventModelType.Message ||
          (mostRecentEvent.modelType === EventModelType.Message && routedChannelId !== mostRecentEvent?.channelId))) {
        const { defaultChannelId } = groups[groupId];
        const channel = channels[defaultChannelId];

        if (channel) {
          if (isMessageChannel(channel)) activeFromChannelId = defaultChannelId;
        }
      }
    }

    if (!activeFromChannelId && activeUserOwnedPhones.length > 0) {
      activeFromChannelId = threadFromChannelIds.find((id) => (
        isPhoneChannelTypeId(channels[id].typeId)
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
      ));
    }
    if (!activeFromChannelId && toChannelClass === 'facebook') {
      activeFromChannelId = threadFromChannelIds?.find((id) => (
        channels[id].typeId === Types.TYPE_FACEBOOK
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
        && toCommunicationId
        && facebooks[toCommunicationId]
        && facebooks[toCommunicationId].channelId === id
      ));
    }

    if (!activeFromChannelId && toChannelClass === 'instagram') {
      activeFromChannelId = threadFromChannelIds.find((id) => (
        channels[id].typeId === Types.TYPE_INSTAGRAM
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
        && toCommunicationId
        && instagrams[toCommunicationId]
        && instagrams[toCommunicationId].channelId === id
      ));
    }
    if (!activeFromChannelId && toChannelClass === 'rhinogram') {
      activeFromChannelId = threadFromChannelIds.find((id) => (
        channels[id].typeId === Types.TYPE_RHINOGRAM
        && channels[id].route.groupId === groupId
        && !channels[id].deleted
      ));
    }
  }
  // Try for default channel then landline, then sms, then facebook (facebook can only send with one to/from combo, so need to filter for that)
  if (!activeFromChannelId && activeUserOwnedPhones.length > 0) {
    if (!activeFromChannelId) activeFromChannelId = threadFromChannelIds.find((id) => channels[id].isDefault === 1 && !channels[id].deleted);
    if (!activeFromChannelId) activeFromChannelId = threadFromChannelIds.find((id) => channels[id].typeId === Types.TYPE_SMS && !channels[id].deleted);
    if (!activeFromChannelId) activeFromChannelId = threadFromChannelIds.find((id) => channels[id].typeId === Types.TYPE_TWILIO && !channels[id].deleted);
  }

  if (!activeFromChannelId && toChannelClass === 'facebook') {
    activeFromChannelId = threadFromChannelIds.find((id) => (
      channels[id].typeId === Types.TYPE_FACEBOOK
      && !channels[id].deleted
      && toCommunicationId
      && facebooks[toCommunicationId]
      && facebooks[toCommunicationId].channelId === id
    ));
  }

  if (!activeFromChannelId && toChannelClass === 'instagram') {
    activeFromChannelId = threadFromChannelIds.find((id) => (
      channels[id].typeId === Types.TYPE_INSTAGRAM
      && !channels[id].deleted
      && toCommunicationId
      && instagrams[toCommunicationId]
      && instagrams[toCommunicationId].channelId === id
    ));
  }
  if (!activeFromChannelId && toChannelClass === 'rhinogram') {
    activeFromChannelId = threadFromChannelIds.find((id) => (
      channels[id].typeId === Types.TYPE_RHINOGRAM
      && !channels[id].deleted
    ));
  }

  let activeSecureNotificationToChannelId = toChannelClass === 'sms' ? activeToChannelId : null;
  if (!activeSecureNotificationToChannelId && activeUserOwnedPhones.length > 0) {
    const toCell = activeUserOwnedPhones.find((phone) => phone && phone.typeId === Types.TYPE_CELL);
    activeSecureNotificationToChannelId = `sms-${toCell?.id || activeUserOwnedPhones[0].id}`;
  }

  let activeSecureNotificationFromChannelId =
    activeFromChannelId && isPhoneChannelTypeId(channels[activeFromChannelId].typeId) ?
      activeFromChannelId :
      userOrganization.defaultChannelId;

  if (!activeSecureNotificationFromChannelId && activeUserOwnedPhones.length > 0) {
    if (!activeSecureNotificationFromChannelId) {
      activeSecureNotificationFromChannelId =
        threadFromChannelIds.find((id) => channels[id].isDefault === 1 && !channels[id].deleted);
    }
    if (!activeSecureNotificationFromChannelId) {
      activeSecureNotificationFromChannelId =
        threadFromChannelIds.find((id) => channels[id].typeId === Types.TYPE_SMS && !channels[id].deleted);
    }
    if (!activeSecureNotificationFromChannelId) {
      activeSecureNotificationFromChannelId =
        threadFromChannelIds.find((id) => channels[id].typeId === Types.TYPE_TWILIO && !channels[id].deleted);
    }
  }
  context = {
    activeToChannelId,
    activeFromChannelId,
    activeSecureFromChannelId,
    activeSecureNotificationToChannelId,
    activeSecureNotificationFromChannelId,
  };

  return context;
};

export default useInboxCommunicationContext;
