import PropTypes from 'prop-types';
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { normalize } from 'normalizr';
import cx from 'classnames';

import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import HeaderContainer from '../containers/HeaderContainer';
import SessionTimeoutModal from './SessionTimeoutModal';
import SystemAlertMessage from './SystemAlertMessage';
import NavigationWrapper from './NavigationWrapper';
import OverlayContainer from '../containers/OverlayContainer';
import calculateViewportHeight from '../helpers/ViewportHelpers';
import StorageService from '../services/StorageService';
import WebSocketService from '../services/WebSocketService';
import routes from '../routes';
import { systemAlertSelectors } from '../selectors';
import { userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { isMobile, isMobileSafari, isTouchDevice } from '../helpers/BrowserHelpers';
import * as AuthReducer from '../reducers/authReducer';
import store from '../store';
import { BILLING_VIEW, CONTACT_VIEW, CONTACT_EDIT, RHINOPAY_MANAGER_VIEW, CONVERSATION_CONTACT_MOBILE } from '../constants/UserPermissionsConstants';
import * as PayReducer from '../reducers/payReducer';
import * as BillingReducer from '../reducers/billingReducer';
import * as VideoReducer from '../reducers/rhinovideoReducer';
import * as UserReducer from '../reducers/userReducer';
import { user as userSchema } from '../actions/NormalizrSchema';
import PostMessageService from '../services/PostMessageService';
import AppUpdateService from '../services/AppUpdateService';
import * as FormReducer from '../reducers/formReducer';
import * as RhinocallReducer from '../reducers/rhinocallReducer';
import * as UIReducer from '../reducers/uiReducer';
import RhinocallModal from './RhinocallModal';
import PageLoader from './PageLoader';
import { getHasActiveMobileCall } from '../selectors/rhinocallSelector';
import { isAxiumLoginPage } from '../helpers/AxiumHelpers';

class App extends Component {
  state = {
    isInitialDataFetched: false,
  }

  componentDidMount() {
    // Set history length manually to check if we can go back in history
    this.props.history.listen(() => {
      let { historyUrls } = this.props.history;
      if (historyUrls === undefined) {
        historyUrls = [];
      }
      if (this.props.history.action === 'PUSH') {
        historyUrls.push(this.props.history.location.pathname);
      }
      if (this.props.history.action === 'POP') {
        historyUrls.pop();
      }
      if (historyUrls.length > 5) {
        historyUrls = historyUrls.slice(1);
      }
      this.props.history.historyUrls = historyUrls;
      this.props.history.canGoBack = historyUrls.length > 1;
    });
    this.intercept();
    this.refreshPageIfAppUpdateAvailable();
    if (!this.state.isInitialDataFetched) {
      this.getInitialView();
    }
    calculateViewportHeight();
    this.addWindowEventListeners();
    this.addAccesibeListener();
  }

  shouldComponentUpdate(nextProps) {
    return !((this.props.location.pathname === '/auth' || this.props.location.pathname === '/login') && nextProps.location.pathname === '/signup');
  }

  componentDidUpdate() {
    calculateViewportHeight();
  }

  addWindowEventListeners() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'org' && e.newValue === null) {
        store.dispatch(this.props.unsetUser());
      }
    });

    const events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    events.forEach((event) => window.addEventListener(event, () => WebSocketService.resetPresenceTimeout()));
    const teardownEvent = isMobileSafari() ? 'pagehide' : 'beforeunload';
    window.addEventListener(teardownEvent, () => {
      if (isMobile() || isTouchDevice()) {
        WebSocketService.setOfflinePresence();
      } else {
        WebSocketService.teardown();
      }
    });
  }

  addAccesibeListener() {
    const observer = new MutationObserver(() => {
      calculateViewportHeight(true);
    });
    const appContainer = document.getElementById('app');
    observer.observe(appContainer, { attributes: true, attributeFilter: ['style'] });
  }

  // A child page is rendered inside the app navigation while a parent page is not
  isChildPage() {
    const currentPath = this.props.location.pathname;
    const parentPagePaths = [
      '/axium/login',
      '/login',
      '/auth',
      '/forgot-password',
      '/notfound',
      '/signup',
      '/change-password',
      '/diagnostics',
      '/check-number',
      '/rhinopay',
      '/callback',
      '/video',
      '/memberVideo',
    ];

    return !parentPagePaths.some((path) => currentPath.includes(path));
  }

  // Refresh the page if an app update is available
  refreshPageIfAppUpdateAvailable() {
    this.props.history.block(() => {
      if (AppUpdateService.isUpdateAvailable()) {
        window.location.reload(true);
        return false;
      } else {
        return true;
      }
    });
  }

  getInitialView() {
    const userId = StorageService.readEntry('user');
    const orgId = StorageService.readEntry('org');
    const isPatientExperience = StorageService.readEntry('patientExperience');
    const authType = StorageService.readEntry('authType');
    const accessToken = localStorage.getItem('access_token');
    const axiumLogin = sessionStorage.getItem('axiumLogin');
    if (userId && !isAxiumLoginPage(this.props.location)) {
      if ((!['legacy', 'axium'].includes(authType) && !accessToken) || (authType === 'axium' && !axiumLogin)) {
        store.dispatch(this.props.logout())
          .then((response) => {
            if (response === 'legacy') {
              this.props.history.push('/login?legacy=true');
            }
            this.setState({ isInitialDataFetched: true });
          });
      } else {
        this.props.getUser(userId, orgId, isPatientExperience)
          .then((response) => {
            if (response && response.data) {
              const normalized = normalize(response.data, userSchema);
              const { isCcr } = response.data;
              store.dispatch(this.props.setUser(this.props.getUserPayload(normalized)));
              store.dispatch(this.props.setOrg(orgId));
              this.fetchServices({ isCcr, orgId, userId });
              store.dispatch(this.props.fetchInitialData(this.props.location.pathname))
                .then(() => {
                  if (!isCcr) {
                    WebSocketService.setup();
                  }
                  const nextPath = store.dispatch(this.props.redirectUser());
                  this.props.history.push(nextPath);
                })
                .then(() => {
                  this.setState({ isInitialDataFetched: true });
                });
            }
          })
          .catch((err) => {
            store.dispatch(this.props.logout())
              .then((response) => {
                if (response === 'legacy') {
                  this.props.history.push('/login?legacy=true');
                }
              });
            console.error(err.response || err);
          });
      }
    } else {
      this.setState({ isInitialDataFetched: true });
    }
    PostMessageService.initEventListener(this.props.history, this.props.location);
  }

  fetchServices({ orgId, isCcr, userId }) {
    // Rhinoform
    store.dispatch(FormReducer.fetchOrgConfiguration(orgId));
    // Billing
    if (userHasAnyOfPermissions([BILLING_VIEW])) store.dispatch(this.props.fetchBilling());
    const mobilePermissions = (userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]) ||
      userHasAllOfPermissions([CONTACT_EDIT, CONVERSATION_CONTACT_MOBILE]) ||
      userHasAnyOfPermissions([RHINOPAY_MANAGER_VIEW]));
    // Rhinopay
    if (isCcr) {
      store.dispatch(this.props.fetchMerchantCcr(orgId));
    } else if (isMobile() ? mobilePermissions : userHasAnyOfPermissions([CONTACT_VIEW, CONTACT_EDIT, RHINOPAY_MANAGER_VIEW])) {
      store.dispatch(this.props.fetchMerchant(orgId));
    }
    // Rhinovideo
    if (!window.location.pathname.startsWith('/video') && !window.location.pathname.startsWith('/memberVideo')) {
      store.dispatch(this.props.fetchRhinoVideoConfiguration(orgId, isCcr));
    }
    // Rhinocall
    store.dispatch(this.props.fetchRhinoCallOrganizationConfiguration(orgId, userId));
    store.dispatch(UIReducer.hideSessionTimeoutModal());
  }

  intercept() {
    axios.interceptors.response.use(undefined, (err) => {
      if (!window.location.href.includes('/login')) { // only intercept if you aren't on login
        if (err.response && err.response.status === 401) {
          store.dispatch(this.props.logout(null, null, false))
            .then((response) => {
              if (response === 'legacy') {
                this.props.history.push('/login?legacy=true');
              }
            });
        }
      }

      return Promise.reject(err);
    });
  }

  render() {
    const appContainerClasses = cx('app-reactroot', {
      'is-dragging': this.props.isDragging,
    });
    let markup;
    const { location, loggedInUser, systemAlert, history, isRhinocallModalVisible, hasActiveMobileCall, isLimitedProvider } = this.props;
    const userIsLoggedInOrCcr = (loggedInUser || StorageService.readEntry('multiOrgs'));
    const appWrapperClass = isLimitedProvider ? 'app-wrapper-limited' : 'app-wrapper';
    if (!this.state.isInitialDataFetched) {
      markup = <PageLoader />;
    } else if (this.isChildPage() && userIsLoggedInOrCcr) {
      markup = (
        <>
          <div className={appContainerClasses} data-vh="100">
            {(isRhinocallModalVisible || hasActiveMobileCall) && <RhinocallModal />}
            {systemAlert && <SystemAlertMessage location={location} history={history} />}
            <div className="app-wrapper__container">
              <NavigationWrapper location={location} history={history} />
              <OverlayContainer location={location} history={history} />
              <div className={appWrapperClass}>
                <SessionTimeoutModal location={location} history={history} />
                <HeaderContainer location={location} history={history} />
                {routes}
              </div>
            </div>
            {/* )} */}
          </div>
        </>
      );
    } else {
      markup = (
        <>
          {routes}
        </>
      );
    }
    return markup;
  }
}

App.propTypes = {
  location: PropTypes.object,
  loggedInUser: PropTypes.object,
  systemAlert: PropTypes.object,
  history: PropTypes.object,
  logout: PropTypes.func,
  setUser: PropTypes.func,
  setOrg: PropTypes.func,
  fetchInitialData: PropTypes.func,
  redirectUser: PropTypes.func,
  getUser: PropTypes.func,
  fetchMerchantCcr: PropTypes.func,
  fetchMerchant: PropTypes.func,
  fetchBilling: PropTypes.func,
  fetchRhinoVideoConfiguration: PropTypes.func,
  getUserPayload: PropTypes.func,
  fetchRhinoCallOrganizationConfiguration: PropTypes.func,
  isRhinocallModalVisible: PropTypes.bool,
  isDragging: PropTypes.bool,
  hasActiveMobileCall: PropTypes.bool,
  isLimitedProvider: PropTypes.bool,
  unsetUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loggedInUser: getLoggedInUser(state),
  ui: state.ui,
  systemAlert: systemAlertSelectors.getActiveSystemAlert(state),
  updateUser: UserReducer.updateUserEmail,
  getUser: UserReducer.getUser,
  getUserPayload: UserReducer.getUserPayload,
  setUser: AuthReducer.setUser,
  setOrg: AuthReducer.setOrg,
  fetchInitialData: AuthReducer.fetchInitialData,
  redirectUser: AuthReducer.redirectUser,
  logout: AuthReducer.logout,
  fetchMerchant: PayReducer.fetchMerchant,
  fetchMerchantCcr: PayReducer.fetchMerchantCcr,
  fetchBilling: BillingReducer.fetchBilling,
  fetchRhinoVideoConfiguration: VideoReducer.fetchRhinoVideoConfiguration,
  fetchRhinoCallOrganizationConfiguration: RhinocallReducer.fetchRhinoCallOrganizationConfiguration,
  isRhinocallModalVisible: state.rhinocall.isRhinocallModalVisible,
  isDragging: state.rhinocall.isDragging,
  hasActiveMobileCall: getHasActiveMobileCall(state),
  isLimitedProvider: userHasLimitedProviderRole(state),
});

const actions = {
  unsetUser: AuthReducer.unsetUser,
};

export default withRouter(connect(mapStateToProps, actions)(App));
