import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Dropdown,
  RadioGroup,
  Radio,
  DropdownMenuItemWild,
  DropdownMenuDivider,
} from 'rhinostyle';
import TagsDropdown from './TagsDropdown';
import GroupsDropdown from './GroupsDropdown';
import UserSearchContainer from '../containers/UserSearchContainer';

const ASSIGNMENT_FILTER_TYPES = [
  'Members',
  'Groups',
  'Tags',
];

const AssignmentFilterDropdown = (props) => {
  const {
    disabled,
    handleChange,
    filters: {
      filterMemberIds,
      filterGroupIds,
      filterTagIds,
    },
  } = props;
  const filters = {
    filterMemberIds,
    filterGroupIds,
    filterTagIds,
  };
  const [assignmentType, setassignmentType] = useState('Members');

  const hasOpenConversationFilters = Object.keys(filters).some((key) => filters[key].length > 0);

  const handleUpdateSelectedIds = (selectedId, key) => {
    const selectedIds = filters[key].includes(selectedId)
      ? filters[key].filter((id) => id !== selectedId)
      : [...filters[key], selectedId];
    handleChange(key, selectedIds);
  };

  const getDropdownLabel = () => {
    if (filterMemberIds.length > 0) {
      return `${filterMemberIds.length} ${filterMemberIds.length > 1 ? 'Members' : 'Member'} Selected`;
    }
    if (filterGroupIds.length > 0) {
      return `${filterGroupIds.length} ${filterGroupIds.length > 1 ? 'Groups' : 'Group'} Selected`;
    }
    if (filterTagIds.length > 0) {
      return `${filterTagIds.length} ${filterTagIds.length > 1 ? 'Tags' : 'Tag'} Selected`;
    }
    return 'Assignment';
  };

  const name = 'assignment-filter';
  return (
    <div className="daterange__dropdown">
      <Dropdown
        label={getDropdownLabel()}
        type={hasOpenConversationFilters ? 'primary' : 'input'}
        outlined={hasOpenConversationFilters}
        className="filter-page__dropdown filter-page__dropdown--date-range"
        disabled={disabled}
        name="assignmentType"
        blur
        autoFocusInput={false}
        position="left"
        assignmentType={assignmentType}
        wide
        dataFeatureTag="assignmentType-assignment-dropdown"
      >
        <div>
          <DropdownMenuItemWild className="u-p-a-0">
            <DropdownMenuItemWild>
              <RadioGroup
                name="assignmentType"
                onChange={setassignmentType}
                selectedValue={assignmentType}
              >
                {ASSIGNMENT_FILTER_TYPES.map((filterType) => (
                  <Radio
                    value={filterType}
                    label={filterType}
                    key={filterType}
                    dataCypress={`${name}-date-${filterType}`}
                    dataFeatureTag={`${name}-date-${filterType}`}
                    className="u-p-t-small"
                  />
                ))}
              </RadioGroup>
            </DropdownMenuItemWild>
            <DropdownMenuDivider />
            {assignmentType === 'Members' && (
            <DropdownMenuItemWild className="u-p-t-0">
              <UserSearchContainer
                interfaceMode="checkbox"
                type="preloadedMembers"
                selectedUserIds={filterMemberIds}
                handleSelectAll={(all) => handleChange('filterMemberIds', all)}
                handleSearchSelect={(selectedId) => handleUpdateSelectedIds(selectedId, 'filterMemberIds')}
                name="filterMemberIds"
                handleClearAll={() => handleChange('filterMemberIds', [])}
                showViewSelected
              />

            </DropdownMenuItemWild>
            )}
            {assignmentType === 'Groups' && (
            <DropdownMenuItemWild className="u-p-a-0">
              <div className="filter-page__dropdown--inline">
                <GroupsDropdown
                  handleChange={(selectedId, value) => handleChange('filterGroupIds', value)}
                  value={filterGroupIds}
                  name="filterGroupIds"
                  inline
                />
              </div>
            </DropdownMenuItemWild>

            )}
            {assignmentType === 'Tags' && (
            <DropdownMenuItemWild className="u-p-a-0">
              <div className="filter-page__dropdown--inline">
                <TagsDropdown
                  handleChange={(selectedId, value) => handleChange('filterTagIds', value)}
                  value={filterTagIds}
                  name="filterTagIds"
                  inline
                />
              </div>
            </DropdownMenuItemWild>
            )}
          </DropdownMenuItemWild>
        </div>
      </Dropdown>
    </div>
  );
};

AssignmentFilterDropdown.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default AssignmentFilterDropdown;
