import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Select,
  Input,
} from 'rhinostyle';

import {
  getRhinocallFromChannelOptions,
  getRhinocallToChannelOptions,
  getRhinocallAudioInputOptions,
  getRhinocallAudioOutputOptions,
} from '../selectors/rhinocallSelector';
import { initiateCall, handleHangup, setSelectedAudioOutputDeviceId, resetRhinocallState, setRhinocallFromChannelId, setRhinocallToChannelId, initialiseSession } from '../reducers/rhinocallReducer';
import { MaskingHelpers, PhoneHelpers, BrowserHelpers } from '../helpers';
import OutboundPostMessageService from '../services/OutboundPostMessageService';

const RhinocallPreconfig = (props) => {
  // Declarations
  const {
    activeFromChannelId,
    activeToChannelId,
    activeUserId,
    rhinocallFromChannelOptions,
    rhinocallToChannelOptions,
    currentUserId,
    phones,
    organizationId,
    channels,
    isCallConnecting,
    location,
    lastMemberNumber,
    channelsLoading,
    rhinocallAudioInputOptions,
    rhinocallAudioOutputOptions,
    selectedAudioOutputDeviceId,
    androidPhoneNumber,
    lastMemberNumberLoading,
    isNativeApp,
    rhinocallFromChannelId,
    rhinocallToChannelId,
  } = props;
  const dispatch = useDispatch();

  // State
  const [toMemberNumber, setToMemberNumber] = useState();
  const [contactId, setContactId] = useState();
  const [selectedAudioInputId, setSelectedAudioInputId] = useState();
  const [audioInputActive, setAudioInputActive] = useState(true);
  const showAudioDeviceSelection = !BrowserHelpers.isMobile() && BrowserHelpers.isChromeBrowser();
  const isAndroidApp = isNativeApp && BrowserHelpers.isAndroid();
  // Mobile only
  useEffect(() => {
    if (!lastMemberNumberLoading) {
      if (PhoneHelpers.isPhoneNumberValid(lastMemberNumber)) {
        // If member has placed a mobile call before, use most recent number
        setToMemberNumber(lastMemberNumber);
      } else if (isAndroidApp) {
        // Otherwise, if member is on Android mobile app pull number from device
        OutboundPostMessageService.postMessage({
          type: 'getAndroidPhoneNumber',
        });
      }
    }
  }, [lastMemberNumber, lastMemberNumberLoading]);

  // Mobile only
  useEffect(() => {
    if (isAndroidApp && PhoneHelpers.isPhoneNumberValid(androidPhoneNumber)) {
      setToMemberNumber(androidPhoneNumber);
    }
  }, [androidPhoneNumber]);

  // Desktop Only
  useEffect(() => {
    if (showAudioDeviceSelection && rhinocallAudioInputOptions && !selectedAudioInputId) {
      setSelectedAudioInputId(rhinocallAudioInputOptions[0]?.id);
    }
  }, [rhinocallAudioInputOptions]);

  // Desktop Only
  useEffect(() => {
    if (showAudioDeviceSelection && rhinocallAudioOutputOptions && !selectedAudioOutputDeviceId) {
      dispatch(setSelectedAudioOutputDeviceId(rhinocallAudioOutputOptions[0]?.id));
    }
  }, [rhinocallAudioOutputOptions]);

  useEffect(() => {
    const fromNumber = getFromChannelNumber(rhinocallFromChannelId);
    if (fromNumber) {
      console.log('**********initialiseSession', fromNumber); // eslint-disable-line no-console
      dispatch(initialiseSession({ fromNumber }));
    }
  }, [rhinocallFromChannelId]);

  useEffect(() => {
    if (!channelsLoading && !rhinocallToChannelId && activeToChannelId) {
      const activeToChannel = rhinocallToChannelOptions.find((o) => o.id === activeToChannelId) || rhinocallToChannelOptions[0];
      if (activeToChannel) {
        handleToChannelSelect();
        dispatch(setRhinocallToChannelId(activeToChannel.id));
      }
    }
  }, [activeToChannelId, channelsLoading]);

  useEffect(() => {
    if (!channelsLoading && !rhinocallFromChannelId && activeFromChannelId) {
      const fromChannel = rhinocallFromChannelOptions.find((o) => o.id === activeToChannelId) || rhinocallFromChannelOptions[0];
      dispatch(setRhinocallFromChannelId(fromChannel.id));
    }
  }, [activeFromChannelId, channelsLoading]);

  useEffect(() => {
    if (!contactId && activeUserId) {
      setContactId(activeUserId);
    } else if (((contactId && activeUserId !== contactId) || !location.pathname.includes('inbox')) && !isCallConnecting) {
      handleCancelClick();
    }
  }, [activeUserId, location, isCallConnecting]);

  // Handlers
  function handleFromChannelSelect(name, fromChannelId) {
    dispatch(setRhinocallFromChannelId(fromChannelId));
  }

  function handleToChannelSelect(name, toChannelId) {
    dispatch(setRhinocallToChannelId(toChannelId));
  }

  function handleAudioInputSelect(name, audioDeviceId) {
    setSelectedAudioInputId(audioDeviceId);
  }

  function handleAudioOutputSelect(name, audioDeviceId) {
    dispatch(setSelectedAudioOutputDeviceId(audioDeviceId));
  }

  function getPhoneNumber(options, phoneId) {
    return PhoneHelpers.normalizePhone(options.find((o) => o.id === phoneId).name);
  }

  function getFromChannelNumber(fromChannelId) {
    const fromPhoneId = channels[fromChannelId]?.details?.phone;
    return phones[fromPhoneId]?.value;
  }

  const handleCallClick = () => {
    const toNumber = getPhoneNumber(rhinocallToChannelOptions, rhinocallToChannelId);
    const fromNumber = getFromChannelNumber(rhinocallFromChannelId);
    const data = {
      fromNumber,
      toNumber,
      contactId,
      organizationId,
      userId: currentUserId,
      ...BrowserHelpers.isMobile() && {
        memberNumber: PhoneHelpers.normalizePhone(toMemberNumber),
      },
      ...showAudioDeviceSelection && {
        deviceId: selectedAudioInputId,
      },
      ...showAudioDeviceSelection && {
        inputDeviceId: selectedAudioInputId,
      },
    };
    dispatch(initiateCall(data));
  };

  function handleCancelClick() {
    if (!isCallConnecting) {
      dispatch(resetRhinocallState());
    } else {
      dispatch(handleHangup());
    }
  }

  const handlePhoneNumberChange = (name, rawValue) => {
    setToMemberNumber(rawValue);
  };

  function getIsCallDisabled() {
    return !rhinocallToChannelId || !rhinocallFromChannelId || (BrowserHelpers.isMobile() && (!toMemberNumber || !PhoneHelpers.isPhoneNumberValid(toMemberNumber)));
  }

  return (
    <div className="rhinocall__form">
      <div className="rhinocall__title__preconfig">Select the numbers to connect</div>
      <div>
        {BrowserHelpers.isMobile() && (
          <>
            <Input
              type="tel"
              label="Your Mobile Number:"
              format={MaskingHelpers.phone}
              name="toMemberNumber"
              placeholder="This number will be hidden"
              onChange={handlePhoneNumberChange}
              required
              initialValue={PhoneHelpers.formatPhone(toMemberNumber)}
              className="rhinocall__input"
              dataFeatureTag="toMemberNumber"
            />
          </>
        )}
        <Select
          label="To:"
          position="top"
          name="to-channel"
          selected={rhinocallToChannelId || ''}
          options={rhinocallToChannelOptions}
          onSelect={handleToChannelSelect}
          className="rhinocall__input"
          data-cypress="toNumber"
          required
        />
        <Select
          position="top"
          name="from-channel"
          label="From/Display:"
          selected={rhinocallFromChannelId || ''}
          options={rhinocallFromChannelOptions}
          onSelect={handleFromChannelSelect}
          className="rhinocall__input"
          data-cypress="fromNumber"
          required
        />
        {showAudioDeviceSelection && (
          <>
            <div className="u-m-t u-m-b-small rhinocall__audio-select">
              <Button
                reset
                className={audioInputActive ? 'u-text-white' : ''}
                onClick={() => setAudioInputActive(true)}
              >
                Audio Input
              </Button>
              <Button
                reset
                className={!audioInputActive ? 'u-text-white' : ''}
                onClick={() => setAudioInputActive(false)}
              >Audio Output
              </Button>
            </div>
            {audioInputActive ? (
              <Select
                position="top"
                name="audio-input"
                selected={selectedAudioInputId}
                options={rhinocallAudioInputOptions}
                onSelect={handleAudioInputSelect}
                className="rhinocall__input"
                data-cypress="selectedAudioDevice"
              />
            ) : (
              <Select
                position="top"
                name="audio-output"
                selected={selectedAudioOutputDeviceId}
                options={rhinocallAudioOutputOptions}
                onSelect={handleAudioOutputSelect}
                className="rhinocall__input"
                data-cypress="selectedAudioDevice"
              />
            )}
          </>
        )}
      </div>
      <div className="rhinocall__footer">
        <Button
          className="u-m-r"
          block
          type="danger"
          onClick={handleCancelClick}
          data-cypress="cancelCall"
        >
          Cancel
        </Button>
        <Button
          loading={isCallConnecting}
          disabled={getIsCallDisabled()}
          block
          type="secondary"
          onClick={handleCallClick}
          data-cypress="startCall"
        >
          Call
        </Button>
      </div>
    </div>
  );
};

RhinocallPreconfig.propTypes = {
  activeToChannelId: PropTypes.string,
  activeFromChannelId: PropTypes.number,
  rhinocallToChannelOptions: PropTypes.array.isRequired,
  rhinocallFromChannelOptions: PropTypes.array.isRequired,
  activeUserId: PropTypes.number,
  currentUserId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  phones: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  isCallConnecting: PropTypes.bool,
  lastMemberNumber: PropTypes.string,
  channelsLoading: PropTypes.bool,
  rhinocallAudioInputOptions: PropTypes.array,
  rhinocallAudioOutputOptions: PropTypes.array,
  selectedAudioOutputDeviceId: PropTypes.string,
  androidPhoneNumber: PropTypes.string,
  isNativeApp: PropTypes.bool,
  lastMemberNumberLoading: PropTypes.bool,
  rhinocallFromChannelId: PropTypes.number,
  rhinocallToChannelId: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  const { inbox, phone, channel, rhinocall, nativeApp, auth } = state;
  return {
    phones: phone.phones,
    activeToChannelId: inbox.activeToChannelId,
    activeUserId: state.inbox.userId,
    activeFromChannelId: inbox.activeFromChannelId,
    rhinocallToChannelOptions: getRhinocallToChannelOptions(state, props.location.pathname),
    rhinocallFromChannelOptions: getRhinocallFromChannelOptions(state, props.location.pathname),
    organizationId: auth.currentOrg,
    currentUserId: auth.currentUser,
    rhinocallAudioInputOptions: getRhinocallAudioInputOptions(state),
    rhinocallAudioOutputOptions: getRhinocallAudioOutputOptions(state),
    channels: channel.channels,
    isCallConnecting: rhinocall.isCallConnecting,
    lastMemberNumber: state.rhinocall.lastMemberNumber,
    channelsLoading: channel.loading,
    selectedAudioOutputDeviceId: rhinocall.selectedAudioOutputDeviceId,
    androidPhoneNumber: rhinocall.androidPhoneNumber,
    lastMemberNumberLoading: rhinocall.lastMemberNumberLoading,
    isNativeApp: nativeApp.isNativeApp,
    rhinocallFromChannelId: state.rhinocall.rhinocallFromChannelId,
    rhinocallToChannelId: state.rhinocall.rhinocallToChannelId,
  };
};

export default connect(mapStateToProps)(RhinocallPreconfig);
