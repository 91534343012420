import PropTypes from 'prop-types';
import React from 'react';
import AssignmentFilterDropdown from './AssignmentFilterDropdown';
import AssignmentDateDropdown from './AssignmentDateDropdown';

const ConversationActivityFilters = (props) => {
  const {
    disabled,
    handleChange,
    filters,
  } = props;

  return (
    <>
      <AssignmentDateDropdown
        handleChange={handleChange}
        label="Time Open"
        date
        fromDate={filters.timeOpen?.timeOpenStart}
        toDate={filters.timeOpen?.timeOpenEnd}
        name="timeOpen"
      />
      <AssignmentDateDropdown
        handleChange={handleChange}
        label="Last Message - Date"
        selectedLabel="Last Message"
        date
        fromDate={filters.lastMessage?.lastMessageStart}
        toDate={filters.lastMessage?.lastMessageEnd}
        name="lastMessage"
      />
      <AssignmentFilterDropdown
        filters={filters}
        handleChange={handleChange}
        disabled={disabled}
      />
    </>
  );
};

ConversationActivityFilters.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default ConversationActivityFilters;
