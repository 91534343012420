import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  SmartTable,
  Button,
  Alert,
  Checkbox,
  NavTabs,
  NavTabsItem,
} from 'rhinostyle';
import { fetchAppointmentStatuses } from '../reducers/appointmentReducer';
import { fetchProviders } from '../reducers/providerReducer';
import { fetchAppointmentTypes } from '../reducers/appointmentTypesReducer';
import { fetchMappedIntegrationOffices } from '../reducers/officeReducer';
import { fetchAppointmentCampaigns } from '../reducers/appointmentCampaignReducer';
import PageLoader from './PageLoader';
import {
  CHECKBOX_COLUMN_WIDTH,
} from '../constants/AppConstants';
import {
  TYPE_APPOINTMENT_STATUS_CONFIRMED,
  TYPE_APPOINTMENT_STATUS_CANCELLED,
  NAVIGATION_TABS,
} from '../constants/AppointmentManagerConstants';
import { FORM_VIEW } from '../constants/UserPermissionsConstants';
import EmptyMessage from './EmptyMessage';
import FilterPage from './FilterPage';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { appointmentSelectors, userSelectors } from '../selectors';
import BulkSelect from './BulkSelect';
import AppointmentManagerFilters from './AppointmentManagerFilters';
import {
  formatUserLabel,
} from '../helpers/UserHelpers';
import {
  convertUtcStringToLocalMoment,
  convertDateInTimeZone,
} from '../helpers/DateHelpers';
import {
  getDefaultDates,
  formatApptManagerStartDate,
} from '../helpers/AppointmentHelpers';
import { useAppointments, useQueryParams } from '../hooks';
import { hasBulkMessagePermission } from '../helpers/PermissionHelpers';
import AppointmentForms from './AppointmentForms';
import AppointmentManagerStatusRow from './AppointmentManagerStatusRow';

const AppointmentManager = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const timeZone = moment.tz.guess();
  const {
    appointmentIntegrationStatus,
    appointmentTypeIds,
    appointmentTypes,
    currentOrganization,
    isCcr,
    officeIds,
    offices,
    providers,
    types,
    providerIds,
    appointmentIntegrationStatusIds,
    hasRhinoformPermission,
  } = props;

  const initialValue = {
    ...getDefaultDates(true),
    activeKey: 3,
    activeTab: NAVIGATION_TABS.upcomingAppointment,
    appointmentStatusTypeIds: [],
    appointmentTypeIds: [],
    integrationStatusTypeIds: [],
    officeIds: [],
    pageNumber: 0,
    providerIds: [],
  };
  const [filters, onSetFilters] = useQueryParams({ initialValue });
  const [selectedAppointments, setSelectedAppointments] = useState([]);

  useEffect(() => {
    dispatch(fetchMappedIntegrationOffices(currentOrganization.id));
    dispatch(fetchAppointmentTypes(currentOrganization.id));
    dispatch(fetchAppointmentStatuses(currentOrganization.id));
    dispatch(fetchAppointmentCampaigns({ orgId: currentOrganization.id, pageNumber: 0 }));
    dispatch(fetchProviders(currentOrganization.id));
  }, []);
  const {
    isFetching,
    isLoading: pageLoading,
    appointments,
    totalAppointmentCount,
    lastUpdatedDate,
    pageSize,
  } = useAppointments(filters);

  function navigateToConversation(userId) {
    history.push(`/inbox/all/user/${userId}`);
  }

  function updateActiveTab(key) {
    const payload = {
      ...initialValue,
      activeTab: key,
      ...getDefaultDates(key === NAVIGATION_TABS.upcomingAppointment),
    };
    setSelectedAppointments([]);
    handleFilterChange(payload);
  }

  function handleSelectRow(appointment) {
    if (isAppointmentSelected(appointment)) {
      setSelectedAppointments((current) => current.filter((item) => item.appointmentId !== appointment.appointmentId));
    } else {
      setSelectedAppointments((current) => [...new Set([...current, appointment])]);
    }
  }

  function handleSelectAllOnPage() {
    setSelectedAppointments((current) => {
      if (getUnselectedOnPage().length === appointments.length) {
        return [...new Set([...current, ...appointments])];
      } return current.filter((item) => !appointments.some((appointment) => appointment.appointmentId === item.appointmentId));
    });
  }

  function getUnselectedOnPage() {
    return appointments.filter((appointment) => !selectedAppointments.some((selected) => appointment.appointmentId === selected.appointmentId));
  }

  function handleFilterChange(changeObj) {
    const updatedFilters = {
      ...changeObj,
      pageNumber: changeObj.pageNumber || 0,
    };
    onSetFilters(updatedFilters);
  }

  function clearAllFilters() {
    handleFilterChange({
      ...initialValue,
      activeTab: filters.activeTab,
    });
  }

  function handlePagination(direction) {
    const nextPage = direction === 'previous' ? filters.pageNumber - 1 : filters.pageNumber + 1;
    handleFilterChange({ pageNumber: nextPage || 0 });
  }

  function mapTypeIdWithValue(typeId) {
    const typeData = Object.values(types).find((type) => type.id === typeId);
    let labelClass = '';

    if (typeId === TYPE_APPOINTMENT_STATUS_CONFIRMED) {
      labelClass = 'u-text-success';
    } else if (typeId === TYPE_APPOINTMENT_STATUS_CANCELLED) {
      labelClass = 'u-text-danger';
    }
    return {
      status: typeData.value,
      statusClass: typeData.value.toLowerCase(),
      labelClass,
    };
  }
  const appointmentFiltersVisible = Object.keys(offices)?.length || appointmentTypeIds?.length;
  const maxColumnWidth = appointmentFiltersVisible ? 200 : 300;

  // set Appointment format for bulk message
  const shapeAppointment = (appointment) => {
    const appointmentDetail = {
      appointmentId: appointment.appointmentId,
      id: appointment.contactDetails.id,
      firstName: appointment.contactDetails.firstName,
      lastName: appointment.contactDetails.lastName,
      phones: appointment.contactDetails.phones,
      tags: appointment.contactDetails.tags,
      profileImageUrl: appointment.contactDetails.profileImageUrl,
    };
    return appointmentDetail;
  };
  const shapeAppointmentList = () => {
    const shapedAppointment = [...selectedAppointments].map((appointment) => {
      if (appointment?.contactDetails) {
        return shapeAppointment(appointment);
      }
      return appointment;
    });
    return shapedAppointment;
  };

  function isAppointmentSelected(appointment) {
    return selectedAppointments.some((item) => item?.appointmentId === appointment?.appointmentId);
  }

  const appointmentsColumns = [
    {
      Header: () => '',
      accessor: 'read',
      sortable: false,
      fixed: 'left',
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-a-0',
      width: 20,
      Cell: (row) => (
        <>
          {!row.original.read && (<span style={{ margin: 'auto' }} className="appointments__circle--unread u-font-weight-bold" />)}
        </>
      ),
    }, {
      Header: () => 'DATE',
      accessor: 'appointmentStartDate',
      sortable: false,
      fixed: 'left',
      headerClassName: 'u-p-l-0',
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-l-0',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <>
          <span className={`
            ${appointmentFiltersVisible ? 'analytics__date-row' : ''}
            ${row.original.read ? '' : 'u-font-weight-bold'}
          `}
          >
            {row.value ? formatApptManagerStartDate(convertDateInTimeZone(row.value, row.original.timeZone || timeZone,
              row.original.observesDst || true)) : ''}
          </span>
        </>
      ),
    },
    {
      Header: () => 'COMMUNICATION',
      accessor: 'createdMessageStatus',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => <AppointmentManagerStatusRow row={row} />,
    }, {
      Header: () => 'STATUS',
      accessor: 'appointmentStatus',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <div className={`${mapTypeIdWithValue(row.value).labelClass} ${row.original.read ? '' : 'u-font-weight-bold'} row__text`}>
          <span className={`${mapTypeIdWithValue(row.value).statusClass}`} />
          <span className="appointments__status-label row__text">{mapTypeIdWithValue(row.value).status}</span>
        </div>
      ),
    }, {
      Header: () => 'CONTACT',
      accessor: 'contactDetails',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-a-0',
      Cell: (row) => (
        <Button
          type="link"
          size="small"
          className="analytics__button-link u-text-primary u-p-l-small"
          onClick={() => navigateToConversation(row.value.id)}
          data-cypress={formatUserLabel(row.value)}
        >
          <span className="u-text-overflow">
            {formatUserLabel(row.value)}
          </span>
        </Button>
      ),
    },
  ];

  const renderAppointmentType = (rowValue) => {
    if (appointmentTypeIds.includes(rowValue) && appointmentTypes[rowValue]) {
      return appointmentTypes[rowValue]?.alias || appointmentTypes[rowValue]?.name;
    } return '';
  };

  if (providerIds?.length) {
    appointmentsColumns.splice(2, 0, {
      Header: () => 'PROVIDER',
      accessor: 'externalProviderId',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <div className={row.original.read ? '' : 'u-font-weight-bold'}>
          <span className="row__text" data-cypress="appointments__provider-row">
            {providers[row.value]?.alias ?? ''}
          </span>
        </div>
      ),
    });
  }
  if (appointmentTypeIds?.length) {
    appointmentsColumns.splice(2, 0, {
      Header: () => 'TYPE',
      accessor: 'appointmentTypeId',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <div className={row.original.read ? '' : 'u-font-weight-bold'}>
          <span className="row__text" data-cypress="appointments__apptTypes-row">
            {renderAppointmentType(row.value)}
          </span>
        </div>
      ),
    });
  }

  if (officeIds?.length) {
    appointmentsColumns.splice(1, 0, {
      Header: () => 'LOCATION',
      accessor: 'officeId',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <div className={row.original.read ? '' : 'u-font-weight-bold'}>
          <span className="row__text" data-cypress="appointments__office-row">
            {offices[row.value]?.name ?? ''}
          </span>
        </div>
      ),
    });
  }

  if (appointmentIntegrationStatusIds?.length) {
    appointmentsColumns.splice(appointmentsColumns.length - 1, 0, {
      Header: () => 'PMS/EHR STATUS',
      accessor: 'externalAppointmentStatusId',
      sortable: false,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      maxWidth: maxColumnWidth,
      Cell: (row) => (
        <div className={row.original.read ? '' : 'u-font-weight-bold'}>
          <span className="row__text" data-cypress="appointments__externalStatus-row">
            {appointmentIntegrationStatus[row.value]?.name ?? ''}
          </span>
        </div>
      ),
    });
  }

  if (hasBulkMessagePermission(isCcr, currentOrganization.isBulkMessagingEnabled)) {
    appointmentsColumns.unshift({
      Header: () => '',
      accessor: 'appointmentId',
      sortable: false,
      fixed: 'left',
      width: CHECKBOX_COLUMN_WIDTH,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-l-0 u-p-r-',
      Cell: (row) => (
        <span className="appointments-checkbox" key={row.original.appointmentId}>
          <Checkbox
            name="appontmentCheckbox"
            dataFeatureTag={`appontmentCheckbox-${row.index}`}
            isChecked={isAppointmentSelected(row.original)}
            onChange={() => handleSelectRow(row.original)}
            label="checkbox"
          />
        </span>
      ),
    });
  }

  if (hasRhinoformPermission) {
    appointmentsColumns.splice(appointmentsColumns.length - 1, 0, {
      Header: () => 'RHINOFORMS',
      accessor: 'appointmentId',
      sortable: false,
      maxWidth: maxColumnWidth,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex u-p-l-0 u-p-r-',
      Cell: (row) => (
        <div className={row.original.read ? '' : 'u-font-weight-bold'}>
          <AppointmentForms appointmentHash={row.original.currentHash} appointmentTypeId={row.original.appointmentTypeId} />
        </div>
      ),
    });
  }

  const renderAppointments = () => (
    <div className="filter-page__table">
      {totalAppointmentCount > 0 ? (
        <SmartTable
          data={appointments}
          columns={appointmentsColumns}
          showPagination={false}
          minRows={0}
          sortable={false}
          sticky
          striped
          defaultPageSize={pageSize}
        />
      ) : <EmptyMessage dataCypressAttr="noUpcomingAppointments" section="Appointment Manager" />}
    </div>
  );

  const renderFilters = () => (
    <AppointmentManagerFilters
      filters={filters}
      handleFilterChange={handleFilterChange}
      isUpcoming={filters.activeTab === NAVIGATION_TABS.upcomingAppointment}
      disabled={selectedAppointments.length > 0}
    />
  );

  function showClearAll() {
    const filteredKeys = Object.keys(filters).filter((key) => !['activeTab', 'pageNumber'].includes(key) && location.search.includes(key));
    return filteredKeys.length > 0;
  }

  const renderAppointmentsDashboard = () => (
    <div>
      <FilterPage
        header={filters.activeTab === 1 ? 'Total Upcoming' : 'Total Past'}
        totalCount={totalAppointmentCount}
        showClearAll={!selectedAppointments.length && showClearAll()}
        clearAllFilters={clearAllFilters}
        pageNumber={filters.pageNumber}
        loading={isFetching}
        handlePagination={handlePagination}
        pageItemCount={appointments.length}
        type="appointments"
        renderFilters={renderFilters}
        displayBottomPagination
        showPageHeader
      >
        <>
          {hasBulkMessagePermission(isCcr, currentOrganization.isBulkMessagingEnabled) && appointments.length > 0 && (
          <BulkSelect
            selectedItems={selectedAppointments}
            pageItems={appointments}
            handleSelectAll={handleSelectAllOnPage}
            handleSelect={setSelectedAppointments}
            totalCount={totalAppointmentCount}
            handleUnselectContact={handleSelectRow}
            type="appointments"
            handleGetContacts={shapeAppointmentList}
            isSelectAllResultsEnabled
            getUnselectedOnPage={getUnselectedOnPage}
          />
          )}
          {renderAppointments()}
        </>
      </FilterPage>
    </div>
  );

  return (
    <div className="app-page__container appointments">
      <div className="u-m-t-large u-text-center">
        <h3>Appointment Manager</h3>
      </div>
      {pageLoading ? <PageLoader /> : (
        <>
          <div className="u-text-center">
            <div className="appointments-header__notification-panel">
              <Alert type="info" className="u-m-b">Last data sync from PMS/EHR:
                <strong>&nbsp;{`${convertUtcStringToLocalMoment(lastUpdatedDate).format('MM/DD/YY hh:mm a')}`}</strong>
              </Alert>
            </div>
          </div>
          <div className="convo__tabs nav-tabs__wrapper">
            <NavTabs activeKey={filters.activeTab} onSelect={updateActiveTab}>
              <NavTabsItem className="nav-tabs__item--header" id={NAVIGATION_TABS.upcomingAppointment}>Upcoming Appointments</NavTabsItem>
              <NavTabsItem className="nav-tabs__item--header" id={NAVIGATION_TABS.pastAppointment}>Past Appointments</NavTabsItem>
            </NavTabs>
          </div>
          <div>
            {renderAppointmentsDashboard()}
          </div>
        </>
      )}
    </div>
  );
};

AppointmentManager.propTypes = {
  appointmentIntegrationStatus: PropTypes.object,
  appointmentTypeIds: PropTypes.array,
  appointmentTypes: PropTypes.object,
  currentOrganization: PropTypes.object,
  isCcr: PropTypes.bool,
  officeIds: PropTypes.array,
  offices: PropTypes.object.isRequired,
  providers: PropTypes.object,
  types: PropTypes.object,
  providerIds: PropTypes.array,
  appointmentIntegrationStatusIds: PropTypes.array,
  hasRhinoformPermission: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { appointment, type, office, appointmentTypes, provider } = state;
  const isRhinoformEnabled = !!state.form.org?.isRhinoformEnabled;
  return {
    appointmentIntegrationStatus: appointment.appointmentIntegrationStatus,
    appointments: appointmentSelectors.getAppointmentsWithTimezone(state),
    appointmentTypeIds: appointmentTypes.appointmentTypeIds,
    appointmentTypes: appointmentTypes.appointmentTypes,
    currentOrganization: getCurrentOrg(state),
    isCcr: userSelectors.getLoggedInUser(state).isCcr,
    officeIds: office.mappedOfficeIds,
    offices: office.mappedOffices,
    providers: provider.providers,
    providerIds: provider.providerIds,
    appointmentIntegrationStatusIds: appointment.appointmentIntegrationStatusIds,
    types: type.types,
    hasRhinoformPermission: !!(isRhinoformEnabled && userSelectors.getLoggedInUserPermissionNames(state)?.includes(FORM_VIEW)),
  };
};

export default connect(mapStateToProps)(AppointmentManager);
