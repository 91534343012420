import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Button,
  SmartTable,
  UtilityInlineGrid,
  DateRangeDropdown,
  Scrollbars,
  Dropdown,
  DropdownMenuItem,
  Checkbox,
} from 'rhinostyle';
import FilterPage from './FilterPage';
import FormsMultiSelect from './FormsMultiSelect';
import { formatDateForTable,
  formatDateForTableShorthand,
  convertUnixTimestampMsToUTCEndDateString,
  convertUnixTimestampMsToUTCStartDateString,
  calculatePastDateRange,
} from '../helpers/DateHelpers';
import PageLoader from './PageLoader';
import SmartTableHeader from './SmartTableHeader';
import { getLoggedInUser } from '../selectors/userSelectors';
import {
  FILE_BASE_URL,
  CHECKBOX_COLUMN_WIDTH,
} from '../constants/AppConstants';
import { TYPE_EVENT_STATUS_DELIVERED } from '../constants/Types';
import EmptyMessage from './EmptyMessage';
import {
  handleClearAllFilters,
} from '../reducers/analyticsReducer';
import { getContactForms, fetchFormManagerView } from '../reducers/formReducer';
import {
  FORMS_MANAGER_HEADERS_CONFIG,
  PATIENT_FORMS_STATUS_OPTIONS,
  DATAPICKER_DROPDOWN_LABELS,
  FORM_STATUS_COMPLETED,
} from '../constants/RhinoformConstants';
import { getDefaultSortKey, cloneDeep, updateArrayById } from '../helpers/DataHelpers';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import FormActionButton from './FormActionButton';
import { getCurrentOrg, getRhinoblastMaxPageSize } from '../selectors/organizationSelectors';
import { isWriteBackEnabled } from '../helpers/OrganizationHelpers';
import { validateQueryStringValue } from '../helpers/FormHelpers';
import { formatNameOrUnknown, userHasAnyOfPermissions, userHasAllOfPermissions } from '../helpers/UserHelpers';
import { isMobile } from '../helpers/BrowserHelpers';
import { useQueryParams } from '../hooks';
import BulkSelect from './BulkSelect';

const FormsManager = (props) => {
  const {
    contactForms,
    currentOrganization,
    contactFormsLoading,
    isOrgWriteBackEnabled,
    pageNumber,
    totalCount,
    users,
    contactFormIds,
    pageSize,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultSortKey = getDefaultSortKey(FORMS_MANAGER_HEADERS_CONFIG);
  const defaultSortOrder = FORMS_MANAGER_HEADERS_CONFIG[defaultSortKey].direction === 1 ? 'asc' : 'desc';
  const dateDates = calculatePastDateRange(6);
  const initialValue = {
    startDate: dateDates.startDate,
    endDate: dateDates.endDate,
    activeKey: 3,
    sortBy: defaultSortKey,
    sortOrder: defaultSortOrder,
    pageNumber: 0,
    status: '',
    titles: [],
    headersConfig: cloneDeep(FORMS_MANAGER_HEADERS_CONFIG),
  };
  const [filters, onSetFilters] = useQueryParams({ initialValue, validateQueryStringValue, datePickerValues: DATAPICKER_DROPDOWN_LABELS });

  const [activeFormId, setActiveFormId] = useState(null);
  const [selectedForms, setSelectedForms] = useState([]);
  const isFormsBulkSelectActive = !!selectedForms.length || contactFormsLoading;
  const currentUser = useSelector((state) => getLoggedInUser(state));
  const { isCcr } = currentUser;
  const canSendBulkMessage = totalCount > 0 && currentOrganization.isBulkMessagingEnabled && !isCcr && userHasAnyOfPermissions(['BULK_MESSAGING_EDIT']) &&
    (isMobile() ? userHasAllOfPermissions(['THREAD_MESSAGE_CREATE', 'CONVERSATION_CONTACT_MOBILE']) :
      userHasAnyOfPermissions(['THREAD_MESSAGE_CREATE']));

  const maxDate = moment().endOf('day');
  const minDate = moment().subtract(3, 'years').startOf('day');

  useEffect(() => {
    dispatch(fetchFormManagerView(formattedFormsRequestData(filters || initialValue)));
  }, []);

  function handleFormCheckboxClick(formId) {
    setSelectedForms((current) => updateArrayById(current, formId));
  }

  function isFormSelected(formId) {
    return selectedForms.includes(formId);
  }

  function handleBulkMessageRemoveContact(contact) {
    setSelectedForms(selectedForms.filter((formId) => contactForms[formId].createdFor !== contact.id));
  }

  function sortTable({ sorted }) {
    if (sorted && sorted.length > 0) {
      const sortKey = sorted[0].id;
      let updatedSortOrder = 'asc';
      if (sortKey === 'contact' || sortKey === 'formInternalData') {
        return;
      }
      if (filters.sortBy === sortKey) {
        updatedSortOrder = filters.sortOrder === 'asc' ? 'desc' : 'asc';
      }
      handleChange({ sortBy: sortKey, sortOrder: updatedSortOrder });
    }
  }

  function handlePagination(direction) {
    const nextPage = direction === 'previous' ? pageNumber - 1 : pageNumber + 1;
    handleChange({ pageNumber: nextPage || 0 });
  }

  async function selectDate(date) {
    if (date.startDate <= date.endDate) {
      handleChange(date);
    }
  }

  function navigateToConversation(userId) {
    history.push(`/inbox/all/user/${userId}`);
  }

  function clearAllFilters() {
    dispatch(handleClearAllFilters());
    handleChange(null);
  }

  function handleChange(changeObj) {
    onSetFilters(changeObj);
    dispatch(getContactForms(formattedFormsRequestData(changeObj || initialValue)));
  }

  function formattedFormsRequestData(filterObj) {
    return {
      from: convertUnixTimestampMsToUTCStartDateString(moment(filterObj?.startDate ? filterObj.startDate : filters.startDate)),
      to: convertUnixTimestampMsToUTCEndDateString(moment(filterObj?.endDate ? filterObj.endDate : filters.endDate)),
      sortBy: filterObj?.sortBy ? filterObj.sortBy : filters.sortBy,
      order: filterObj?.sortOrder ? filterObj.sortOrder : filters.sortOrder,
      pageSize,
      pageNumber: filterObj?.pageNumber ? filterObj.pageNumber : 0,
      status: typeof filterObj?.status !== 'undefined' ? filterObj.status : filters.status,
      titles: Array.isArray(filterObj?.titles) ? JSON.stringify(filterObj.titles) : JSON.stringify(filters.titles),
      writebackEnabled: isOrgWriteBackEnabled,
    };
  }

  function isDelivered(savedContentSent) {
    return savedContentSent.eventStatusTypeId === TYPE_EVENT_STATUS_DELIVERED;
  }

  // set contact list format
  function getContactList(formIds) {
    return [...new Set([...formIds.filter((formId) => contactForms[formId]?.createdFor).map((formId) => contactForms[formId].createdFor)])];
  }

  function getUnselectedOnPage() {
    return contactFormIds.filter((formId) => !selectedForms.includes(formId));
  }

  const renderFilters = () => (
    <div className="analytics__medicaid">
      <UtilityInlineGrid>
        <DateRangeDropdown
          position="center"
          name="Analytics Date Picker"
          startDate={filters.startDate}
          endDate={filters.endDate}
          selectDate={selectDate}
          minDate={minDate}
          maxDate={maxDate}
          activeKey={filters.activeKey}
          dropdownMenuItems={DATAPICKER_DROPDOWN_LABELS}
          selectDateRange={calculatePastDateRange}
          isCustomDate
          dataCypress="formDateFilter"
          disabled={isFormsBulkSelectActive}
        />
        <FormsMultiSelect
          updateFilters={(titles) => handleChange({ titles })}
          selectedIds={filters.titles}
          disabled={isFormsBulkSelectActive}
          name="titles"
        />
        <Dropdown
          position="bottom"
          activeKey={filters.status}
          label="Status"
          type={filters.status ? 'primary' : 'input'}
          outlined={!!filters.status}
          name="statuses"
          onSelect={(status) => handleChange({ status })}
          dataCypress="formStatusFilter"
          disabled={isFormsBulkSelectActive}
        >
          {PATIENT_FORMS_STATUS_OPTIONS.map((option) => (
            <DropdownMenuItem
              key={option.id}
              id={option.id}
              label={option.value ? option.value : null}
            />
          ))}
        </Dropdown>
      </UtilityInlineGrid>
    </div>
  );

  function getForm(row) {
    return contactForms[row.original];
  }

  const contactFormsColumns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Date Sent"
          sortKey="createdAt"
          sortDirection={filters.headersConfig.createdAt.direction}
          headers={filters.headersConfig}
          isCaretDisabled={isFormsBulkSelectActive}
        />
      ),
      sortable: !isFormsBulkSelectActive,
      accessor: 'createdAt',
      Cell: (row) => (<div className="analytics__date-row">{formatDateForTable(getForm(row).createdAt)}</div>),
      fixed: 'left',
      maxWidth: 300,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Form Name"
          sortKey="title"
          sortDirection={filters.headersConfig.title.direction}
          headers={filters.headersConfig}
          isCaretDisabled={isFormsBulkSelectActive}
        />
      ),
      sortable: !isFormsBulkSelectActive,
      headerStyle: {
        maxWidth: 200,
      },
      accessor: 'title',
      Cell: (row) => (<div className="u-p-l-small" data-cypress="formTitle">{getForm(row).title}</div>),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Status"
          sortKey="status"
          sortDirection={filters.headersConfig.status.direction}
          headers={filters.headersConfig}
          isCaretDisabled={isFormsBulkSelectActive}
        />
      ),
      sortable: !isFormsBulkSelectActive,
      accessor: 'status',
      Cell: (row) => (
        <div className="u-p-l-small">
          <div className={`status status--circle status--${getForm(row).status.toLowerCase()}`} />
          <span data-cypress="formStatus" className="status status--text">{getForm(row).status}</span>
        </div>
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Contact"
          sortKey="contact"
          headers={filters.headersConfig}
          isCaretDisabled
        />
      ),
      sortable: false,
      accessor: 'contact',
      Cell: (row) => (
        <Button
          data-cypress="createdForButton"
          type="link"
          size="small"
          className="analytics__button-link u-text-primary u-p-l-small"
          onClick={() => navigateToConversation(getForm(row).createdFor)}
        >
          {`${formatNameOrUnknown(users[getForm(row).createdFor])}`}
        </Button>
      ),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="FORM VIEW"
          sortKey="formInternalData"
          headers={filters.headersConfig}
          isCaretDisabled
        />
      ),
      sortable: false,
      width: 120,
      style: {
        padding: 0,
      },
      accessor: 'formInternalData',
      Cell: (row) => getForm(row).formInternalData && (
        <FormActionButton
          icon="pdfFile"
          dataCypress="togglePdf"
          toggleButton={() => setActiveFormId(getForm(row).formInternalData)}
        />
      ),
    },
  ];

  if (canSendBulkMessage) {
    contactFormsColumns?.unshift({
      Header: () => '',
      accessor: '',
      sortable: false,
      fixed: 'left',
      minWidth: CHECKBOX_COLUMN_WIDTH,
      className: 'u-flex-align-items-center u-flex-direction-row u-flex',
      Cell: (row) => (
        <span className="forms-manager__checkbox">
          <Checkbox name="formSelect" isChecked={isFormSelected(row.original)} onChange={() => handleFormCheckboxClick(row.original)} label="checkbox" dataFeatureTag="formSelect" />
        </span>
      ),
    });
  }
  if (isOrgWriteBackEnabled) {
    contactFormsColumns?.splice(3, 0, {
      Header: () => (
        <SmartTableHeader
          sortable={false}
          headerName="Sent To EHR"
          sortKey="sentToEhr"
          headers={filters.headersConfig}
          isCaretDisabled
        />
      ),
      sortable: false,
      accessor: 'sentToEhr',
      Cell: (row) => (
        <div className="u-p-l-small">
          {getForm(row).status === FORM_STATUS_COMPLETED && getForm(row).savedContentSent && (
          <>
            <div className={`status status--circle status--${isDelivered(getForm(row).savedContentSent) ? 'completed' : 'sent'}`} />
            <span data-cypress="formSentToEhr" className="status status--text status--sent">{isDelivered(getForm(row).savedContentSent) ? formatDateForTableShorthand(getForm(row).savedContentSent.lastUpdatedAt) : 'Sending'}</span>
          </>
          )}
        </div>
      ),
    });
  }

  const renderSmartTable = (columns) => (
    contactFormIds?.length > 0 ? (
      <SmartTable
        data={contactFormIds}
        showPagination={false}
        onFetchData={sortTable}
        minRows={0}
        sticky
        defaultPageSize={pageSize}
        columns={columns}
        sortable
        manual
        striped
      />
    ) : (
      <EmptyMessage section="RhinoForms Manager" />
    )
  );

  return (
    <>
      <Scrollbars>
        <div className="app-page__container analytics">
          <div className="app-page__container__inner analytics__container">
            <div className="app-page__header">
              <div className="app-page__header__title analytics__header u-m-t-large">
                <h3>
                  RhinoForms Manager
                </h3>
              </div>
            </div>
            <div className="contact-section">
              <FilterPage
                header="Total Forms"
                totalCount={totalCount}
                showClearAll={(!!location.search && !isFormsBulkSelectActive)}
                clearAllFilters={clearAllFilters}
                pageNumber={pageNumber}
                loading={contactFormsLoading}
                handlePagination={handlePagination}
                pageItemCount={contactFormIds.length}
                renderFilters={renderFilters}
                type="forms"
                showPageHeader
              >
                <>
                  {canSendBulkMessage && (
                  <BulkSelect
                    selectedItems={selectedForms}
                    pageItems={contactFormIds}
                    handleSelect={setSelectedForms}
                    totalCount={totalCount}
                    handleGetContacts={getContactList}
                    handleUnselectContact={handleBulkMessageRemoveContact}
                    selectAllLabel="Select All Contacts on Page"
                    type="forms"
                    getUnselectedOnPage={getUnselectedOnPage}
                  />
                  )}
                  {contactFormsLoading ? <PageLoader /> : (
                    <div className="forms-manager__table">
                      {renderSmartTable(contactFormsColumns, sortTable)}
                    </div>
                  )}
                </>
              </FilterPage>
            </div>
          </div>
        </div>
      </Scrollbars>
      <PdfViewerContainer
        open={!!activeFormId}
        attachmentUrl={activeFormId && `${FILE_BASE_URL}${activeFormId}`}
        fileName={contactFormIds?.find((formId) => contactForms[formId]?.formInternalData === activeFormId)?.title || ''}
        handlePdfViewerClose={() => setActiveFormId(null)}
      />
    </>
  );
};

FormsManager.propTypes = {
  contactForms: PropTypes.array,
  contactFormsLoading: PropTypes.bool,
  isOrgWriteBackEnabled: PropTypes.bool,
  currentOrganization: PropTypes.object.isRequired,
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
  users: PropTypes.object,
  contactFormIds: PropTypes.array,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { form, user } = state;

  return {
    contactForms: form.contactForms,
    contactFormIds: form.contactFormIds,
    pageNumber: form.pageNumber,
    totalCount: form.totalContactFormCount,
    contactFormsLoading: form.contactFormsLoading,
    isOrgWriteBackEnabled: isWriteBackEnabled(getCurrentOrg(state)),
    currentOrganization: getCurrentOrg(state),
    pageSize: getRhinoblastMaxPageSize(state),
    users: user.users,
  };
};

export default connect(mapStateToProps)(FormsManager);
