import React, { useState } from 'react';
import {
  Button,
  Icon,
} from 'rhinostyle';
import cx from 'classnames';

const ExpandableBox = ({
  startOpen = false,
  children,
  position = 'left',
  title,
  subtitle,
  className,
  description,
  dataFeatureTag = `${title}-expand`,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  const collapseClass = cx('box__collapse', {
    'box__collapse--right': position === 'right',
    'box__collapse--left': position === 'left',
  });

  const wrapperClass = cx('box', {
    [`${className}`]: className,
  });

  const boxTitleClass = cx('box__title-wrapper', {
    'u-m-b-0': !isOpen,
  });

  const visibiltyClasses = cx('', {
    'u-visibility--hidden': !isOpen,
    'u-visibility--visible': isOpen,
  });

  return (
    <div className={wrapperClass}>
      <div className={boxTitleClass}>
        <div className="u-position-relative">
          <Button
            reset
            className={collapseClass}
            onClick={toggleIsOpen}
            data-feature-tag={dataFeatureTag}
          >
            {isOpen ? 'Collapse' : 'Expand'}
            <Icon
              icon={isOpen ? 'minus' : 'add'}
              style={{ cursor: 'pointer' }}
              className="u-p-l-small icon-stroke"
            />
          </Button>
        </div>
        <div className="box__title">{title}</div>
        <div className="box__subtitle">{subtitle}</div>
      </div>
      {!isOpen && description && (
        <div className="u-p-a u-text-center">
          {description}
        </div>
      )}
      <div className={visibiltyClasses}>
        {children}
      </div>
    </div>
  );
};

export default ExpandableBox;
