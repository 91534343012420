import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  RhinoSwitch,
  SmartTable,
  Tooltip,
  Icon,
  NavTabs,
  NavTabsItem,
  Scrollbars,
} from 'rhinostyle';
import { connect, useDispatch } from 'react-redux';
import { fetchAppointmentTypes, updateAppointmentTypes } from '../reducers/appointmentTypesReducer';
import { fetchProviders, updateProviders } from '../reducers/providerReducer';
import { getLoggedInUser } from '../selectors/userSelectors';
import { compareObjectByKey } from '../helpers/DataHelpers';
import EmptyMessage from './EmptyMessage';
import PageLoader from './PageLoader';
import {
  APPOINTMENT_TYPE_EDIT,
} from '../constants/UserPermissionsConstants';
import { userHasAllOfPermissions } from '../helpers/UserHelpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import LazyLoader from './LazyLoader';
import { PROVIDER_PAGE_SIZE } from '../constants/AppConstants';

const AppointmentDataSetup = (props) => {
  const {
    appointmentTypes,
    loading,
    providerLoading,
    currentUser,
    providers,
    total,
    pageLoading,
  } = props;

  const NAVIGATION_TABS = {
    appointmentType: 1,
    provider: 2,
  };

  const canEditAppointmentTypes = userHasAllOfPermissions([APPOINTMENT_TYPE_EDIT]);
  const dispatch = useDispatch();
  const scrollContainerRef = useRef();
  const [appointmentTypesCopy, setAppointmentTypes] = useState([]);
  const [providersCopy, setProviders] = useState(providers);
  const [errors, setErrors] = useState({});
  const [formInProgress, setFormInProgress] = useState(false);
  const [selectedKey, setSelectedKey] = useState(1);
  const [typeChange, setTypeChange] = useState(false);
  const [providerChange, setProviderChange] = useState(false);
  const [providerPageNumber, setProviderPageNumber] = useState(0);

  useEffect(() => {
    dispatch(fetchAppointmentTypes(currentUser.organization));
    dispatch(fetchProviders(currentUser.organization, providerPageNumber, PROVIDER_PAGE_SIZE));
  }, []);

  useEffect(() => {
    if (!formInProgress) {
      setAppointmentTypes(appointmentTypes);
    }
  }, [loading]);

  useEffect(() => {
    if (!formInProgress) {
      setProviders(providers);
    }
  }, [providers]);

  const handleChange = (nameAndId, value) => {
    const [id, accessor] = (nameAndId || '').split('-');
    setAppointmentTypes((current) => current.map((type) => {
      if (type.id === Number(id)) {
        return {
          ...type,
          [accessor]: accessor === 'ignored' ? !value : value,
        };
      } return type;
    }));
    setTypeChange(true);
  };

  const handleProviderChange = (nameAndId, value) => {
    const [id, accessor] = (nameAndId || '').split('-');
    setProviders((current) => current.map((provider) => ((provider.id === Number(id)) ? {
      ...provider,
      [accessor]: value,
    } : provider)));
    setProviderChange(true);
  };

  const handleTabs = (index) => {
    setSelectedKey(index);
  };

  const handleSubmit = async () => {
    const submitErrors = appointmentTypesCopy.reduce((acc, appointmentType) => {
      const error = ValidationService(ValidationShapers.shapeAppointmentTypes({ ...appointmentType }));
      if (Object.keys(error).length) acc[appointmentType.id] = error;
      return acc;
    }, {});

    if (Object.keys(submitErrors).length > 0) {
      setErrors(submitErrors);
    } else {
      setFormInProgress(true);
      setErrors({});
      const shapedAppointmentTypes = appointmentTypesCopy.map((appointmentType) => ({
        id: appointmentType.id,
        alias: appointmentType.alias || '',
        ignored: !!appointmentType.ignored,
      }));
      await dispatch(updateAppointmentTypes(currentUser.organization, shapedAppointmentTypes));
      setFormInProgress(false);
      setTypeChange(false);
    }
  };

  const handleProviderSubmit = async () => {
    setFormInProgress(true);
    const shapedProvider = providersCopy.map((provider) => ({
      id: provider.id,
      alias: provider.alias || '',
    }));
    await dispatch(updateProviders(currentUser.organization, shapedProvider));
    setFormInProgress(false);
    setProviderChange(false);
  };

  const onScroll = () => {
    const scrollContainer = scrollContainerRef.current.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    if (Math.round(totalScroll) === scrollContainer.scrollHeight && providersCopy.length !== total) {
      scrollContainerRef.current.container.scrollPosition = 'bottom';
      setProviderPageNumber(providerPageNumber + 1);
      dispatch(fetchProviders(currentUser.organization, providerPageNumber + 1, PROVIDER_PAGE_SIZE));
    }
  };

  const tooltipText = 'If you choose to not include (disable) specific types the appointment will still display in Rhinogram, however it will not be possible to send appointment messages about the ‘excluded’ types when setting up appointment reminders.'; // eslint-disable-line max-len
  const appointmentTypeColumns = [
    {
      Header: () => 'TYPE NAME',
      accessor: 'name',
      sortable: false,
      fixed: 'left',
      className: 'u-flex-align-items-center u-flex',
      Cell: (row) => (
        <div>
          {row.value}
        </div>
      ),
    }, {
      Header: () => 'ALIAS',
      accessor: 'alias',
      sortable: false,
      Cell: (row) => (
        <div>
          <Input
            className="appointment-data-setup__input-wrapper"
            validationMessage={errors[appointmentTypesCopy[row.index].id]?.alias}
            placeholder={appointmentTypesCopy[row.index].name}
            initialValue={row.value}
            onChange={handleChange}
            name={`${row.original.id}-alias`}
            type="text"
            disabled={!canEditAppointmentTypes}
            dataFeatureTag={`dataSetupTypesAlias-${row.index}`}
          />
        </div>
      ),
    }, {
      Header: () => (
        <div className="u-text-center">INCLUDE
          &nbsp;
          <Tooltip placement="top" content={tooltipText}>
            <Icon bump="up" icon="info-circle" className="u-text-muted" />
          </Tooltip>
        </div>
      ),
      accessor: 'ignored',
      sortable: false,
      width: 100,
      resizable: false,
      Cell: (row) => (
        <div data-cypress={`dataSetupTypesInclude-${row.original.id}`} className="appointment-data-setup__header--ignored">
          <RhinoSwitch
            disabled={!canEditAppointmentTypes}
            isChecked={!row.value}
            onChange={handleChange}
            name={`${row.original.id}-ignored`}
            dataFeatureTag={`${row.index}-ignored`}
          />
        </div>
      ),
    },
  ];

  const providerColumns = [
    {
      Header: () => 'PROVIDER NAME',
      accessor: 'displayName',
      sortable: false,
      fixed: 'left',
      className: 'u-flex-align-items-center u-flex',
      Cell: (row) => (
        <div>
          {row.value}
        </div>
      ),
    }, {
      Header: () => 'PROVIDER DISPLAY NAME',
      accessor: 'alias',
      sortable: false,
      Cell: (row) => (
        <div>
          <Input
            className="appointment-data-setup__input-wrapper"
            initialValue={row.value}
            onChange={handleProviderChange}
            name={`${row.original.id}-alias`}
            type="text"
            disabled={!canEditAppointmentTypes}
            dataFeatureTag={`dataSetupProviderAlias-${row.index}`}
          />
        </div>
      ),
    },
  ];

  const renderAppointmentTypes = () => (
    <>
      <div className="box__title-wrapper">
        <div className="box__title">Appointment Types</div>
        <div className="box__subtitle">
          This is a list of all appointment types from your PMS/EHR. It is possible to rename these types to give them a display name within Rhinogram.
          The alias will display throughout the Rhinogram app and when sending appointment communication to patients.
        </div>
      </div>

      {appointmentTypesCopy.length > 0 ? (
        <div className="list-panel__outer__body">
          <div className="list-panel__body">
            <Scrollbars className="list-panel__body__scroll">
              <SmartTable
                data={appointmentTypesCopy}
                columns={appointmentTypeColumns}
                pageSize={appointmentTypesCopy?.length}
                minRows={0}
                showPagination={false}
                sortable={false}
                sticky
              />
            </Scrollbars>
          </div>
        </div>
      ) : <EmptyMessage />}
    </>
  );

  const renderProviders = () => (
    <>
      <div className="box__title-wrapper">
        <div className="box__title">Provider Display Name</div>
        <div className="box__subtitle">
          This is a list of all providers from your PMS/EHR. It is possible to give them a display name within Rhinogram.
          The alias will display throughout the Rhinogram app and when sending appointment communication to patients.
        </div>
      </div>

      {providersCopy.length > 0 ? (
        <div className="list-panel__outer__body">
          <div className="list-panel__body">
            <Scrollbars className="list-panel__body__scroll" ref={scrollContainerRef} onScroll={onScroll}>
              <SmartTable
                data={providersCopy}
                columns={providerColumns}
                pageSize={providersCopy?.length}
                minRows={0}
                showPagination={false}
                sortable={false}
                sticky
              />
            </Scrollbars>
          </div>
          <LazyLoader loading={pageLoading} />
        </div>
      ) : <EmptyMessage />}
    </>
  );

  return (
    loading || providerLoading ? <PageLoader /> : (
      <div className="app-page__container">
        <div className="app-page__container__inner">
          <div className="app-page__header">
            <div className="app-page__header__title">
              Appointment Data
            </div>
          </div>
          <div className="convo__tabs nav-tabs__wrapper">
            <NavTabs activeKey={selectedKey} onSelect={handleTabs}>
              <NavTabsItem className="nav-tabs__item--header" id={NAVIGATION_TABS.appointmentType}>Appointment Type</NavTabsItem>
              <NavTabsItem className="nav-tabs__item--header" id={NAVIGATION_TABS.provider}>Provider</NavTabsItem>
            </NavTabs>
          </div>
          <div className="box appointment-data-setup__wrapper">
            {selectedKey === 1 ? renderAppointmentTypes() : renderProviders()}
          </div>

          <div className="appointment-data-setup__footer">
            <div className="u-m-x u-m-y u-text-right">
              {selectedKey === 1 ? (
                <Button
                  data-cypress="appointmentDataTypesSaveButton"
                  loading={formInProgress}
                  onClick={handleSubmit}
                  disabled={!appointmentTypes.length || !canEditAppointmentTypes || !typeChange}
                  type="primary"
                >
                  Save Types Setup
                </Button>
              ) : (
                <Button
                  data-cypress="appointmentDataTypesSaveButton"
                  loading={formInProgress}
                  onClick={handleProviderSubmit}
                  disabled={!providers.length || !canEditAppointmentTypes || !providerChange}
                  type="primary"
                >
                  Save Provider Setup
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

AppointmentDataSetup.propTypes = {
  appointmentTypes: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  providerLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  providers: PropTypes.array,
  total: PropTypes.number,
  pageLoading: PropTypes.bool,
};
const mapStateToProps = (state) => {
  const { appointmentTypes, provider } = state;
  return {
    appointmentTypes: Object.values(appointmentTypes.appointmentTypes).sort((a, b) => compareObjectByKey(a, b, 'name')),
    loading: appointmentTypes.loading,
    pageLoading: provider.pageLoading,
    currentUser: getLoggedInUser(state),
    providers: Object.values(provider.providers).sort((a, b) => compareObjectByKey(a, b, 'fullname')),
    providerLoading: provider.loading,
    total: provider.total,
  };
};
export default connect(mapStateToProps)(AppointmentDataSetup);
