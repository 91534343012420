import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import ConversationActivity from '../components/ConversationActivity';
import {
  fetchClosedConversations,
  fetchOpenConversations,
  handleClearSelectedMembers,
  handleClearSelectedContacts,
  setSelectedMembers,
  setSelectedContacts,
  fetchMedicaidConversationsView,
  fetchFilterData,
  fetchMedicaidConversations,
  handleClearAllFilters,
  fetchCampaignsFailedContacts,
} from '../reducers/analyticsReducer';
import { cloneDeep, hasData } from '../helpers/DataHelpers';
import { ALL, CONTACT, DEFAULT_ANALYTICS_PAGE_SIZE, NONE, PRACTICE } from '../constants/AppConstants';
import {
  OPEN_CONVERSATION_HEADERS_CONFIG,
  CLOSE_CONVERSATION_HEADERS_CONFIG,
  MEDICAID_CONVERSATION_HEADERS_CONFIG,
  NAVIGATION_TABS,
} from '../constants/AnalyticsConstants';
import { getLoggedInUserOrganization } from '../selectors/userSelectors';
import * as AssignmentActions from '../actions/AssignmentActions';
import { fetchInboxSections } from '../reducers/uiReducer';
import { fetchTags } from '../reducers/tagReducer';

const DEFAULT_OPEN_CONVERSATION_FILTERS = {
  filterMemberIds: [],
  filterGroupIds: [],
  filterTagIds: [],
  timeOpen: {
    timeOpenStart: null,
    timeOpenEnd: null,
  },
  lastMessage: {
    lastMessageStart: null,
    lastMessageEnd: null,
  },
};

class ConversationActivityContainer extends Component {
  static initialState = {
    pageItemIndexFrom: 0,
    pageItemIndexTo: 0,
    isPreviousDisabledForMedicaid: true,
    isNextDisabledForMedicaid: false,
    sortOrder: 'descending',
    sortKey: 'timestamp',
    sortBy: '1',
    currentPage: 1,
    medicaidConversationsCurrentPage: 1,
    campaignsSortKey: 'timestamp',
    campaignsSortOrder: 'desc',
    closedConversationsCurrentPage: 1,
    openConversationsCurrentPage: 1,
    isPreviousDisabledForOpen: true,
    isNextDisabledForOpen: false,
    isPreviousDisabledForClose: true,
    isNextDisabledForClose: false,
  }

  static campaignsInitialState = {
    isPreviousDisabledForCampaigns: true,
    isNextDisabledForCampaigns: false,
    campaignsConversationsCurrentPage: 1,
  }

  state = {
    ...ConversationActivityContainer.initialState,
    ...ConversationActivityContainer.campaignsInitialState,
    activeKey: 3,
    activeKeyForMedicaid: 3,
    activeKeyForCampaigns: 3,
    activityHeading: '',
    activitySubHeading: '',
    currentPage: 1,
    closedConversationsCurrentPage: 1,
    closedConversationSortOrder: 'descending',
    closedConversationSortKey: 'timeOpen',
    closedConversationSortBy: '1',
    openConversationsCurrentPage: 1,
    openConversationSortOrder: 'descending',
    openConversationSortKey: 'conversationStartTime',
    openConversationSortBy: '1',
    medicaidConversationsCurrentPage: 1,
    conversationActivities: {},
    closedConversations: {},
    openConversations: {},
    medicaidConversations: {},
    filterByPatient: false,
    filterByPractice: false,
    isNextDisabled: false,
    isPreviousDisabled: true,
    endDate: moment(),
    maxDate: moment().endOf('day'),
    minDate: moment().subtract(3, 'years').startOf('day'),
    minMedicaidDate: moment().subtract(2, 'years').startOf('year'),
    maxMedicaidDate: moment().endOf('year'),
    selectedKey: 1,
    isPreviousDisabledForOpen: true,
    isNextDisabledForOpen: false,
    isPreviousDisabledForClose: true,
    isNextDisabledForClose: false,
    isPreviousDisabledForMedicaid: true,
    isNextDisabledForMedicaid: false,
    startDate: moment().subtract(30, 'days'),
    campaignsStartDate: moment().subtract(30, 'days'),
    campaignsEndDate: moment(),
    medicaidStartDate: moment().startOf('year'),
    medicaidEndDate: moment().endOf('year'),
    isDateSelected: false,
    isValidDate: true,
    tableMinRows: 0,
    isModalOpen: false,
    // openConversationHeadersConfig: cloneDeep(OPEN_CONVERSATION_HEADERS_CONFIG),
    // closeConversationHeadersConfig: cloneDeep(CLOSE_CONVERSATION_HEADERS_CONFIG),
    // medicaidConversationHeadersConfig: cloneDeep(MEDICAID_CONVERSATION_HEADERS_CONFIG),
    datePickerDropdownLabels: [{ id: 1, label: 'Yesterday', duration: 1 }, { id: 2, label: 'Last 7 Days', duration: 6 },
      { id: 3, label: 'Last 30 Days', duration: 29 }, { id: 4, label: 'Last 90 Days', duration: 89 },
      { id: 5, label: 'Last 12 Months', duration: 'year' }, { id: 6, label: 'Custom Date' }],
    datePickerDropdownLabelsForMedicaid: [
      { id: 1, label: moment().format('YYYY') - 2, duration: 'year' },
      { id: 2, label: moment().format('YYYY') - 1, duration: 'year' },
      { id: 3, label: moment().format('YYYY'), duration: 30 }, { id: 6, label: 'Custom Date' }],
    openConversationHeaders: OPEN_CONVERSATION_HEADERS_CONFIG,
    closedConversationHeaders: CLOSE_CONVERSATION_HEADERS_CONFIG,
    medicaidConversationHeaders: MEDICAID_CONVERSATION_HEADERS_CONFIG,
    openConversationFilters: { ...DEFAULT_OPEN_CONVERSATION_FILTERS },
  };

  fetchInitialFilteredRecords = () => {
    const medicaidStartDate = moment().startOf('year');
    const medicaidEndDate = moment().endOf('year');
    this.setState({
      activeKeyForMedicaid: 3,
    });
    this.props.fetchMedicaidConversationsView(this.formattedAnalyticsRequestData({
      medicaidStartDate: medicaidStartDate.format('YYYY-MM-DD'),
      medicaidEndDate: medicaidEndDate.format('YYYY-MM-DD'),
      memberIds: [],
      contactIds: [],
      sortOrder: 'descending',
      sortBy: '1',
    }));
  }

  componentDidMount() {
    this.props.fetchTags();
    this.props.fetchClosedConversations(
      moment(this.state.startDate).format('YYYY-MM-DD'),
      moment(this.state.endDate).format('YYYY-MM-DD'),
      this.state.closedConversationsCurrentPage - 1, DEFAULT_ANALYTICS_PAGE_SIZE,
      'descending',
      1,
    );

    this.setDefaultSortOnConversation('openConversationHeaders', 'conversationStartTime');
    this.props.fetchOpenConversations(this.state.openConversationsCurrentPage - 1,
      DEFAULT_ANALYTICS_PAGE_SIZE,
      'all',
      'descending',
      1,
      this.state.openConversationFilters);

    this.props.fetchMedicaidConversationsView(this.formattedAnalyticsRequestData({}));
  }

  componentWillUnmount() {
    this.props.handleClearAllFilters();
  }

  setDefaultSortOnConversation = (conversationHeaderType, defaultSortColumn) => {
    const headers = this.state[conversationHeaderType];
    Object.keys(headers).forEach((key) => {
      headers[key].direction = -1;
      headers[key].default = false;
    });
    headers[defaultSortColumn].default = true;
    headers[defaultSortColumn].direction = -1;
    this.setState({ [conversationHeaderType]: headers });
  }

  handleAssignmentUpdated = async () => {
    const filterBy = this.getFilterBy(this.state.filterByPatient, this.state.filterByPractice);
    const { openConversationSortOrder, openConversationSortBy } = this.state;
    this.props.fetchOpenConversations(
      this.state.openConversationsCurrentPage - 1,
      DEFAULT_ANALYTICS_PAGE_SIZE,
      filterBy,
      openConversationSortOrder,
      openConversationSortBy,
    );
    this.props.fetchClosedConversations(
      moment(this.state.startDate).format('YYYY-MM-DD'),
      moment(this.state.endDate).format('YYYY-MM-DD'),
      this.state.closedConversationsCurrentPage - 1, DEFAULT_ANALYTICS_PAGE_SIZE,
    );
    this.props.fetchInboxSections();
  }
  handleCloseConversation = async (payload) => {
    await this.props.closeAssignment(payload);
    this.handleAssignmentUpdated();
  }

  formattedAnalyticsRequestData = (filterObj) => ({
    memberIds: filterObj.memberIds ? filterObj.memberIds : this.props.selectedMemberIds,
    contactIds: filterObj.contactIds ? filterObj.contactIds : this.props.selectedContactIds,
    medicaidStartDate: filterObj.medicaidStartDate ? filterObj.medicaidStartDate : moment(this.state.medicaidStartDate).format('YYYY-MM-DD'),
    medicaidEndDate: filterObj.medicaidEndDate ? filterObj.medicaidEndDate : moment(this.state.medicaidEndDate).format('YYYY-MM-DD'),
    sortBy: filterObj.sortBy ? filterObj.sortBy : this.state.sortBy,
    sortOrder: filterObj.sortOrder ? filterObj.sortOrder : this.state.sortOrder,
    pageSize: DEFAULT_ANALYTICS_PAGE_SIZE,
    pageNo: filterObj.pageNo ? filterObj.pageNo : this.props.pageNo,
  })

  fetchFilterData = (searchText, filterName) => {
    this.props.fetchFilterData(searchText, filterName);
  }

  clearAllFilters = () => {
    this.setDefaultSortOnConversation('medicaidConversationHeaders', 'timestamp');
    this.setState({
      ...ConversationActivityContainer.initialState,
      isDateSelected: false,
      medicaidStartDate: moment().startOf('year'),
      medicaidEndDate: moment().endOf('year'),
      // openConversationHeadersConfig: cloneDeep(OPEN_CONVERSATION_HEADERS_CONFIG),
      // closeConversationHeadersConfig: cloneDeep(CLOSE_CONVERSATION_HEADERS_CONFIG),
      // medicaidConversationHeadersConfig: cloneDeep(MEDICAID_CONVERSATION_HEADERS_CONFIG),
    });
    this.props.handleClearAllFilters();
    this.fetchInitialFilteredRecords();
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (hasData(nextProps.openConversations) && state.selectedKey === NAVIGATION_TABS.open) {
      const updatedState = {
        ...ConversationActivityContainer.filterOpenConversation(nextProps, state),
        ...ConversationActivityContainer.getPaginationCurrentPage(state.selectedKey, state) };
      return updatedState;
    } else if (hasData(nextProps.openConversations) && hasData(nextProps.closedConversations)) {
      if (state.selectedKey === NAVIGATION_TABS.closed) {
        return {
          activityHeading: `Total Closed (${cloneDeep(nextProps.closedConversations.total)})`,
          activitySubHeading: 'Conversations closed with contacts.',
          conversationActivities: {
            conversations: cloneDeep(nextProps.closedConversations.closedConversation),
            total: nextProps.closedConversations.total,
          },
          selectedKey: 2,
          ...ConversationActivityContainer.getPaginationCurrentPage(state.selectedKey, state),
        };
      } else if (state.selectedKey === NAVIGATION_TABS.medicaid && nextProps.medicaidConversations && nextProps.medicaidConversations.results) {
        return {
          conversationActivities: {
            conversations: cloneDeep(nextProps.medicaidConversations.results),
            total: nextProps.medicaidConversations.total,
          },
          selectedKey: 3,
          ...ConversationActivityContainer.getPaginationCurrentPage(state.selectedKey, state),
        };
      }
    }
    return null;
  }

  static getPaginationCurrentPage(tab, state) {
    let currentPage;
    let isNextDisabled;
    let isPreviousDisabled;
    if (tab === NAVIGATION_TABS.open) {
      currentPage = state.openConversationsCurrentPage;
      isNextDisabled = state.isNextDisabledForOpen;
      isPreviousDisabled = state.isPreviousDisabledForOpen;
    } else if (tab === NAVIGATION_TABS.closed) {
      currentPage = state.closedConversationsCurrentPage;
      isNextDisabled = state.isNextDisabledForClose;
      isPreviousDisabled = state.isPreviousDisabledForClose;
    } else if (tab === NAVIGATION_TABS.medicaid) {
      currentPage = state.medicaidConversationsCurrentPage;
      isNextDisabled = state.isNextDisabledForMedicaid;
      isPreviousDisabled = state.isPreviousDisabledForMedicaid;
    }
    return {
      currentPage,
      isNextDisabled,
      isPreviousDisabled,
    };
  }

  handleTabs = (index) => {
    if (index === NAVIGATION_TABS.open) {
      this.setDefaultSortOnConversation('openConversationHeaders', 'conversationStartTime');
    } else if (index === NAVIGATION_TABS.closed) {
      this.setDefaultSortOnConversation('closedConversationHeaders', 'timeOpen');
    } else if (index === NAVIGATION_TABS.medicaid) {
      this.clearAllFilters();
      this.setDefaultSortOnConversation('medicaidConversationHeaders', 'timestamp');
    }
    this.setState({
      selectedKey: index,
    });
  };

  getFilterBy = (filterByPatient, filterByPractice) => {
    let filterBy = ALL;

    if (filterByPatient && filterByPractice) {
      filterBy = NONE;
    } else if (filterByPatient && !filterByPractice) {
      filterBy = PRACTICE;
    } else if (!filterByPatient && filterByPractice) {
      filterBy = CONTACT;
    }
    return filterBy;
  };

  sortTable = (state) => {
    const currentPage = 0;
    const {
      selectedKey,
      openConversationSortOrder,
      openConversationSortKey,
      closedConversationSortKey,
      closedConversationSortOrder,
    } = this.state;
    const { sorted } = state;
    if (sorted && sorted.length > 0) {
      const sortKey = sorted[0].id;
      let sortOrder;
      if (selectedKey === NAVIGATION_TABS.open) {
        if (openConversationSortKey !== sortKey) {
          sortOrder = 'ascending';
        } else {
          sortOrder = openConversationSortOrder === 'ascending' ? 'descending' : 'ascending';
        }
        const sortBy = this.getSortColumnForOpenTab(sortKey);
        const filterBy = this.getFilterBy(this.state.filterByPatient, this.state.filterByPractice);
        this.props.fetchOpenConversations(currentPage,
          DEFAULT_ANALYTICS_PAGE_SIZE,
          filterBy,
          sortOrder,
          sortBy,
          this.state.openConversationFilters);
        this.setState({
          ...ConversationActivityContainer.initialState,
          openConversationSortKey: sortKey,
          openConversationSortBy: sortBy,
          openConversationSortOrder: sortOrder,
        });
      } else if (selectedKey === NAVIGATION_TABS.closed) {
        if (closedConversationSortKey !== sortKey) {
          sortOrder = 'ascending';
        } else {
          sortOrder = closedConversationSortOrder === 'ascending' ? 'descending' : 'ascending';
        }
        const sortBy = this.getSortColumnForClosedTab(sortKey);
        this.props.fetchClosedConversations(
          moment(this.state.startDate).format('YYYY-MM-DD'),
          moment(this.state.endDate).format('YYYY-MM-DD'),
          currentPage,
          DEFAULT_ANALYTICS_PAGE_SIZE,
          sortOrder,
          sortBy,
        );
        this.setState({
          ...ConversationActivityContainer.initialState,
          closedConversationSortKey: sortKey,
          closedConversationSortBy: sortBy,
          closedConversationSortOrder: sortOrder,
        });
      } else if (selectedKey === NAVIGATION_TABS.medicaid) {
        if (this.state.sortKey !== sortKey) {
          sortOrder = 'ascending';
        } else {
          sortOrder = this.state.sortOrder === 'ascending' ? 'descending' : 'ascending';
        }
        const sortBy = this.getSortColumnForMedicaidTab(sortKey);
        this.props.fetchMedicaidConversations(this.formattedAnalyticsRequestData({ sortBy, sortOrder }));
        this.setState({ ...ConversationActivityContainer.initialState, sortKey, sortBy, sortOrder });
      }
    }
  };

  getSortColumnForMedicaidTab = (key) => {
    if (key === 'timestamp') {
      return 1;
    } else if (key === 'member') {
      return 2;
    } else if (key === 'messageSentTo') {
      return 3;
    } else {
      return 4;
    }
  }

  getSortColumnForOpenTab = (key) => {
    if (key === 'conversationStartTime') {
      return 1;
    } else if (key === 'lastConversationTime') {
      return 2;
    } else if (key === 'assignment') {
      return 3;
    } else {
      return 4;
    }
  }

  getSortColumnForClosedTab = (key) => {
    if (key === 'timeOpen') {
      return 1;
    } else if (key === 'closedTime') {
      return 2;
    } else if (key === 'closedBy') {
      return 3;
    } else {
      return 4;
    }
  }

  handlePagination = (direction, selectedKey) => {
    if (selectedKey === NAVIGATION_TABS.open) {
      let { openConversationsCurrentPage } = this.state;
      const { filterByPatient,
        filterByPractice,
        openConversationSortBy,
        openConversationSortOrder,
        openConversationFilters,
      } = this.state;

      if (direction === 'previous') {
        openConversationsCurrentPage -= 1;
      } else {
        openConversationsCurrentPage += 1;
      }

      const filterBy = this.getFilterBy(filterByPatient, filterByPractice);
      this.props.fetchOpenConversations(
        openConversationsCurrentPage - 1,
        DEFAULT_ANALYTICS_PAGE_SIZE,
        filterBy,
        openConversationSortOrder,
        openConversationSortBy,
        openConversationFilters,
      );

      const isPreviousDisabledForOpen = (openConversationsCurrentPage === 1);
      const isNextDisabledForOpen = (openConversationsCurrentPage >= Math.ceil(this.state.conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE));
      this.setState({
        openConversationsCurrentPage,
        isPreviousDisabledForOpen,
        isNextDisabledForOpen,
      });
    } else if (selectedKey === NAVIGATION_TABS.closed) {
      let {
        closedConversationsCurrentPage,
      } = this.state;
      const {
        closedConversationSortOrder,
        closedConversationSortBy,
      } = this.state;

      if (direction === 'previous') {
        closedConversationsCurrentPage -= 1;
      } else {
        closedConversationsCurrentPage += 1;
      }

      this.props.fetchClosedConversations(
        this.state.startDate.format('YYYY-MM-DD'),
        this.state.endDate.format('YYYY-MM-DD'),
        closedConversationsCurrentPage - 1,
        DEFAULT_ANALYTICS_PAGE_SIZE,
        closedConversationSortOrder,
        closedConversationSortBy,
      );

      const isPreviousDisabledForClose = (closedConversationsCurrentPage === 1);
      const isNextDisabledForClose = (closedConversationsCurrentPage >= Math.ceil(this.state.conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE));
      this.setState({
        closedConversationsCurrentPage,
        isPreviousDisabledForClose,
        isNextDisabledForClose,
      });
    } else if (selectedKey === NAVIGATION_TABS.medicaid) {
      let { medicaidConversationsCurrentPage } = this.state;
      if (direction === 'previous') {
        medicaidConversationsCurrentPage -= 1;
      } else {
        medicaidConversationsCurrentPage += 1;
      }
      this.props.fetchMedicaidConversations(
        this.formattedAnalyticsRequestData({ pageNo: medicaidConversationsCurrentPage - 1 }),
      );

      const isPreviousDisabledForMedicaid = (medicaidConversationsCurrentPage === 1);
      const isNextDisabledForMedicaid = (medicaidConversationsCurrentPage >= Math.ceil(this.state.conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE));

      this.setState({
        medicaidConversationsCurrentPage,
        isPreviousDisabledForMedicaid,
        isNextDisabledForMedicaid,
      });
    }
  };

  selectDate = (date) => {
    const pageNo = 1;
    this.props.fetchClosedConversations(
      date.startDate.format('YYYY-MM-DD'),
      date.endDate.format('YYYY-MM-DD'),
      pageNo - 1,
      DEFAULT_ANALYTICS_PAGE_SIZE,
    ).then(() => {
      this.setState({
        startDate: date.startDate,
        endDate: date.endDate,
        activeKey: date.activeKey,
        closedConversationsCurrentPage: pageNo,
        isPreviousDisabledForClose: true,
        isNextDisabledForClose: (pageNo >= Math.ceil(this.state.conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE)),
      });
      this.setDefaultSortOnConversation('closedConversationHeaders', 'timeOpen');
    });
  };

  handleFilterOpenConversations = (key, value) => {
    let changeObj = { [key]: value };
    if (['filterMemberIds', 'filterGroupIds', 'filterTagIds'].includes(key)) {
      changeObj = {
        filterMemberIds: [],
        filterGroupIds: [],
        filterTagIds: [],
        [key]: value,
      };
    }

    const updatedFilters = {
      ...this.state.openConversationFilters,
      ...changeObj,
    };

    if (JSON.stringify(updatedFilters) !== JSON.stringify(this.state.openConversationFilters)) {
      this.setState({ openConversationFilters: updatedFilters });

      this.props.fetchOpenConversations(
        this.state.openConversationsCurrentPage - 1,
        DEFAULT_ANALYTICS_PAGE_SIZE,
        this.getFilterBy(this.state.filterByPatient, this.state.filterByPractice),
        this.state.openConversationSortOrder,
        this.state.openConversationSortBy,
        updatedFilters,
      );
    }
  }

  handleClearOpenConversationFilters = () => {
    this.setState({
      openConversationFilters: { ...DEFAULT_OPEN_CONVERSATION_FILTERS },
    });
    this.props.fetchOpenConversations(
      this.state.openConversationsCurrentPage - 1,
      DEFAULT_ANALYTICS_PAGE_SIZE,
      this.getFilterBy(this.state.filterByPatient, this.state.filterByPractice),
      this.state.openConversationSortOrder,
      this.state.openConversationSortBy,
      DEFAULT_OPEN_CONVERSATION_FILTERS,
    );
  }

  selectDateForMedicaid = (date) => {
    if (date.startDate > date.endDate) {
      this.setState({ isValidDate: false });
    } else {
      this.props.fetchMedicaidConversations(
        this.formattedAnalyticsRequestData(
          {
            medicaidStartDate: date.startDate.format('YYYY-MM-DD'),
            medicaidEndDate: date.endDate.format('YYYY-MM-DD'),
            sortOrder: 'descending',
            sortBy: 1,
            pageNo: 0,
          },
        ),
      ).then(() => {
        const pageNo = 1;
        const disable = (pageNo >= Math.ceil(this.state.conversationActivities.total / DEFAULT_ANALYTICS_PAGE_SIZE));
        this.setState({
          medicaidStartDate: date.startDate,
          medicaidEndDate: date.endDate,
          isValidDate: true,
          isDateSelected: true,
          activeKeyForMedicaid: date.activeKey,
          fetchMedicaidConversationsCurrentPage: pageNo,
          isPreviousDisabledForMedicaid: true,
          isNextDisabledForMedicaid: disable,
          currentPage: 1,
          medicaidConversationsCurrentPage: 1,
        });
      });
    }
  };

  getFilteredOpenConversations = (state) => {
    const currentPage = 0;
    const filterBy = this.getFilterBy(state.filterByPatient, state.filterByPractice);
    const { openConversationSortOrder, openConversationSortBy, openConversationFilters } = this.state;

    this.props.fetchOpenConversations(
      currentPage,
      DEFAULT_ANALYTICS_PAGE_SIZE,
      filterBy,
      openConversationSortOrder,
      openConversationSortBy,
      openConversationFilters,
    );
  };

  filterToggle = (filterBy) => {
    if (filterBy === PRACTICE) {
      this.setState({
        filterByPractice: !this.state.filterByPractice,
        openConversationsCurrentPage: 1,
        isPreviousDisabledForOpen: true,
        isNextDisabledForOpen: false,
      }, () => {
        this.getFilteredOpenConversations(this.state);
      });
    } else if (filterBy === CONTACT) {
      this.setState({
        filterByPatient: !this.state.filterByPatient,
        openConversationsCurrentPage: 1,
        isPreviousDisabledForOpen: true,
        isNextDisabledForOpen: false,
      }, () => {
        this.getFilteredOpenConversations(this.state);
      });
    }
    this.setDefaultSortOnConversation('openConversationHeaders', 'conversationStartTime');
  };

  navigateToConversation = (userId) => {
    this.props.history.push(`/inbox/all/user/${userId}`);
  };

  compareObjectByKey = (a, b, key) => {
    if (a[key] === null) {
      return 1;
    } else if (b[key] === null) {
      return -1;
    }
    const valueA = a[key]?.firstName ? `${a[key].lastName} ${a[key].firstName}`.toLowerCase() : a[key].toLowerCase();
    const valueB = b[key]?.firstName ? `${b[key].lastName} ${b[key].firstName}`.toLowerCase() : b[key].toLowerCase();

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  };

  static filterOpenConversation = (props, state) => {
    const { openConversations, total, practiceConversationCount, contactConversationCount } = cloneDeep(props.openConversations);
    const { filterByPatient, filterByPractice } = state;
    let totalConversations = 0;

    if (filterByPatient && filterByPractice) {
      totalConversations = 0;
    } else if (filterByPatient && !filterByPractice) {
      totalConversations = practiceConversationCount;
    } else if (!filterByPatient && filterByPractice) {
      totalConversations = contactConversationCount;
    } else {
      totalConversations = total;
    }

    return {
      activityHeading: `Total Open (${total})`,
      activitySubHeading: 'All conversations currently open with contacts.',
      conversationActivities: {
        total: totalConversations,
        conversations: openConversations,
        contactCount: contactConversationCount,
        practiceCount: practiceConversationCount,
      },
    };
  };

  calculateDateRange = (duration, selectedLabelIndex) => {
    let fromDate = moment().subtract(duration, 'days');
    let toDate = moment();

    if (selectedLabelIndex === 1) {
      toDate = moment().subtract(1, 'days');
    }
    if (duration === 'year') {
      fromDate = moment().subtract(1, 'years').add(1, 'days');
    }
    return { startDate: fromDate, endDate: toDate };
  }

  calculateDateRangeForMedicaid = (duration, selectedLabelIndex) => {
    let toDate = moment().endOf('year');
    let fromDate = moment().startOf('year');

    if (selectedLabelIndex === 1) {
      fromDate = moment().subtract(2, duration).startOf('year');
      toDate = moment().subtract(2, duration).endOf('year');
      this.state.activeKeyForMedicaid = 1;
    } else if (selectedLabelIndex === 2) {
      fromDate = moment().subtract(1, duration).startOf('year');
      toDate = moment().subtract(1, duration).endOf('year');
      this.state.activeKeyForMedicaid = 2;
    }
    return { startDate: fromDate, endDate: toDate };
  }

  currentPageItemRange = (currentPage, itemCount) => {
    let pageItemIndexFrom = ((currentPage - 1) * DEFAULT_ANALYTICS_PAGE_SIZE) + 1;
    const pageItemIndexTo = itemCount >= currentPage * DEFAULT_ANALYTICS_PAGE_SIZE ? currentPage * DEFAULT_ANALYTICS_PAGE_SIZE : pageItemIndexFrom + itemCount - 1;
    if (pageItemIndexTo === 0) pageItemIndexFrom = 0;
    return `${pageItemIndexFrom} - ${pageItemIndexTo}`;
  };

  formatData = (selectedIds, selectedItems) => ({
    selectedIds,
    selectedItems,
  })

  sortSelectedItems = (usersArray) => function sortItems(a, b) {
    const nameA = usersArray[a].firstName ? usersArray[a].firstName.toLowerCase() : usersArray[a].lastName.toLowerCase();
    const nameB = usersArray[b].firstName ? usersArray[b].firstName.toLowerCase() : usersArray[b].lastName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  };

  handleContactRoutes = (id) => {
    this.props.history.push(`/inbox/all/user/${id}`);
  }

  handleModalToggle = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handleUpdateSelectedIds = (selectedIds, selectedUsers, filterName) => {
    this.resetState();
    const formattedData = this.formatData([...selectedIds].sort(this.sortSelectedItems(selectedUsers)), selectedUsers);
    if (filterName === 'contacts') {
      this.props.setSelectedContacts(formattedData);
      this.props.fetchMedicaidConversations(this.formattedAnalyticsRequestData({ contactIds: selectedIds }));
    } else if (filterName === 'members') {
      this.props.setSelectedMembers(formattedData);
      this.props.fetchMedicaidConversations(this.formattedAnalyticsRequestData({ memberIds: selectedIds }));
    }
  }

  handleClearSelectedMembers = () => {
    this.resetState();
    this.props.handleClearSelectedMembers();
    this.props.fetchMedicaidConversations(this.formattedAnalyticsRequestData({ memberIds: [], sortOrder: 'descending' }));
  }

  handleClearSelectedContacts = () => {
    this.resetState();
    this.props.handleClearSelectedContacts();
    this.props.fetchMedicaidConversations(this.formattedAnalyticsRequestData({ contactIds: [], sortOrder: 'descending' }));
  }

  resetState() {
    this.setState({ ...ConversationActivityContainer.initialState });
  }

  render() {
    const props = {
      activeKey: this.state.activeKey,
      activityHeading: this.state.activityHeading,
      activitySubHeading: this.state.activitySubHeading,
      conversationActivities: this.state.conversationActivities,
      currentPage: this.state.currentPage,
      isNextDisabled: this.state.isNextDisabled,
      isPreviousDisabled: this.state.isPreviousDisabled,
      currentPageItemRange: this.currentPageItemRange,
      filterToggle: this.filterToggle,
      filterByPatient: this.state.filterByPatient,
      filterByPractice: this.state.filterByPractice,
      handleToggle: this.handleToggle,
      handleTabs: this.handleTabs,
      hasPagination: false,
      handlePagination: this.handlePagination,
      selectedKey: this.state.selectedKey,
      isClosedActivitiesLoading: this.props.isClosedActivitiesLoading,
      isMedicaidActivitiesLoading: this.props.isMedicaidActivitiesLoading,
      isOpenActivitiesLoading: this.props.isOpenActivitiesLoading,
      isCampaignsActivitiesLoading: this.props.isCampaignsActivitiesLoading,
      compareObjectByKey: this.compareObjectByKey,
      startDate: this.state.startDate,
      calculateDateRange: this.calculateDateRange,
      selectDate: this.selectDate,
      endDate: this.state.endDate,
      minDate: this.state.minDate,
      minMedicaidDate: this.state.minMedicaidDate,
      maxMedicaidDate: this.state.maxMedicaidDate,
      maxDate: this.state.maxDate,
      navigateToConversation: this.navigateToConversation,
      datePickerDropdownLabels: this.state.datePickerDropdownLabels,
      datePickerDropdownLabelsForMedicaid: this.state.datePickerDropdownLabelsForMedicaid,
      fetchFilterData: this.fetchFilterData,
      handleUpdateSelectedIds: this.handleUpdateSelectedIds,
      sortTable: this.sortTable,
      sortCampaignsTable: this.sortCampaignsTable,
      isPageLoading: this.props.isPageLoading,
      members: this.props.members,
      selectedMembers: { ...this.props.selectedMembers },
      selectedMemberIds: this.props.selectedMemberIds,
      membersIds: this.props.membersIds,
      handleClearSelectedMembers: this.handleClearSelectedMembers,
      getContacts: this.props.getContacts,
      selectedContacts: { ...this.props.selectedContacts },
      selectedContactIds: this.props.selectedContactIds,
      handleClearSelectedContacts: this.handleClearSelectedContacts,
      contactsIds: this.props.contactsIds,
      selectDateForMedicaid: this.selectDateForMedicaid,
      selectCampaignsDate: this.selectCampaignsDate,
      calculateDateRangeForMedicaid: this.calculateDateRangeForMedicaid,
      clearAllFilters: this.clearAllFilters,
      isValidDate: this.state.isValidDate,
      isDateSelected: this.state.isDateSelected,
      medicaidStartDate: this.state.medicaidStartDate,
      medicaidEndDate: this.state.medicaidEndDate,
      campaignsStartDate: this.state.campaignsStartDate,
      campaignsEndDate: this.state.campaignsEndDate,
      tableMinRows: this.state.tableMinRows,
      openConversationHeadersConfig: OPEN_CONVERSATION_HEADERS_CONFIG,
      closeConversationHeadersConfig: CLOSE_CONVERSATION_HEADERS_CONFIG,
      medicaidConversationHeadersConfig: MEDICAID_CONVERSATION_HEADERS_CONFIG,
      activeKeyForMedicaid: this.state.activeKeyForMedicaid,
      activeKeyForCampaigns: this.state.activeKeyForCampaigns,
      isModalOpen: this.state.isModalOpen,
      campaignsFailedContacts: this.props.campaignsFailedContacts,
      handleModalToggle: this.handleModalToggle,
      handleContactRoutes: this.handleContactRoutes,
      isCampaignsFailedContactsLoading: this.props.isCampaignsFailedContactsLoading,
      userOrganization: this.props.userOrganization,
      handleCloseConversation: this.handleCloseConversation,
      handleAssignmentUpdated: this.handleAssignmentUpdated,
      location: this.props.location,
      history: this.props.history,
      handleFilterOpenConversations: this.handleFilterOpenConversations,
      openConversationFilters: this.state.openConversationFilters,
      handleClearOpenConversationFilters: this.handleClearOpenConversationFilters,
    };

    return <ConversationActivity {...props} />;
  }
}

ConversationActivityContainer.propTypes = {
  fetchClosedConversations: PropTypes.func.isRequired,
  fetchOpenConversations: PropTypes.func.isRequired,
  medicaidConversations: PropTypes.object.isRequired,
  fetchFilterData: PropTypes.func.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  members: PropTypes.object.isRequired,
  selectedMemberIds: PropTypes.array.isRequired,
  membersIds: PropTypes.array.isRequired,
  handleClearSelectedMembers: PropTypes.func.isRequired,
  getContacts: PropTypes.object.isRequired,
  selectedContactIds: PropTypes.array.isRequired,
  handleClearSelectedContacts: PropTypes.func.isRequired,
  contactsIds: PropTypes.array.isRequired,
  handleClearAllFilters: PropTypes.func.isRequired,
  fetchMedicaidConversationsView: PropTypes.func,
  isClosedActivitiesLoading: PropTypes.bool,
  isOpenActivitiesLoading: PropTypes.bool,
  isMedicaidActivitiesLoading: PropTypes.bool,
  isCampaignsActivitiesLoading: PropTypes.bool,
  isCampaignsFailedContactsLoading: PropTypes.bool,
  fetchMedicaidConversations: PropTypes.func,
  setSelectedMembers: PropTypes.func,
  setSelectedContacts: PropTypes.func,
  selectedMembers: PropTypes.object,
  selectedContacts: PropTypes.object,
  pageNo: PropTypes.number,
  closedConversations: PropTypes.object,
  openConversations: PropTypes.object,
  campaignsConversations: PropTypes.object,
  campaignsFailedContacts: PropTypes.object,
  fetchCampaignsFailedContacts: PropTypes.func.isRequired,
  userOrganization: PropTypes.object.isRequired,
  history: PropTypes.object,
  closeAssignment: PropTypes.func.isRequired,
  fetchInboxSections: PropTypes.func.isRequired,
  createAssignmentMember: PropTypes.func.isRequired,
  createAssignmentGroup: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { analytics } = state;

  return {
    closedConversations: analytics.closedConversations,
    openConversations: analytics.openConversations,
    isClosedActivitiesLoading: analytics.isClosedActivitiesLoading,
    isCampaignsActivitiesLoading: analytics.isCampaignsActivitiesLoading,
    isOpenActivitiesLoading: analytics.isOpenActivitiesLoading,
    isMedicaidActivitiesLoading: analytics.isMedicaidActivitiesLoading,
    isCampaignsFailedContactsLoading: analytics.isCampaignsFailedContactsLoading,
    isPageLoading: analytics.loading,
    members: analytics.members,
    selectedMembers: analytics.selectedMembers,
    selectedMemberIds: analytics.selectedMemberIds,
    membersIds: analytics.membersIds,
    getContacts: analytics.getContacts,
    selectedContacts: analytics.selectedContacts,
    selectedContactIds: analytics.selectedContactIds,
    contactsIds: analytics.contactsIds,
    medicaidConversations: analytics.medicaidConversations,
    campaignsConversations: analytics.campaignsConversations,
    fetchFilterData: analytics.fetchFilterData,
    campaignsFailedContacts: analytics.campaignsFailedContacts,
    userOrganization: getLoggedInUserOrganization(state),
  };
};

const actions = {
  fetchClosedConversations,
  fetchOpenConversations,
  handleClearSelectedMembers,
  handleClearSelectedContacts,
  setSelectedMembers,
  setSelectedContacts,
  fetchMedicaidConversationsView,
  fetchFilterData,
  fetchMedicaidConversations,
  handleClearAllFilters,
  fetchCampaignsFailedContacts,
  closeAssignment: AssignmentActions.closeAssignment,
  fetchInboxSections,
  createAssignmentMember: AssignmentActions.createAssignmentMember,
  createAssignmentGroup: AssignmentActions.createAssignmentGroup,
  fetchTags,
};

export default connect(mapStateToProps, actions)(ConversationActivityContainer);
