/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import * as UserReducer from '../reducers/userReducer';
import UserSearch from '../components/UserSearch';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';

class UserSearchContainer extends Component {
  state = {
    searchText: '',
    searchFocus: !!this.props.focus,
    contactProfileContainerOpen: false,
    searchObject: {},
    usersLoading: false,
  }

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
  }

  componentDidMount() {
    this.props.clearUserSearch();

    if (this.props.type === 'preloadedMembers') {
      this.fetchActiveMembers();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isHidden && !prevProps.isHidden && this.state.searchText.length > 2 && this.props.type === 'preloadedMembers') {
      this.handleClearSearch();
    }
  }

  handleClearSearch() {
    this.props.clearUserSearch();
    this.fetchActiveMembers();
    this.setState({ searchText: '' });
  }

  preSelectedForwardingUserId = this.props.selectedUserId;

  clearFocus = () => {
    this.setState({
      searchText: '',
      searchFocus: false,
    });
  }

  fetchActiveMembers = () => {
    this.props.fetchActiveMembers();
  }

  onScroll = () => {
    const {
      type,
      fetchConnectedPartySearch,
      selectedUserIds = [],
      coverUserId = '',
      searchSize,
      page,
    } = this.props;
    const { searchText } = this.state;
    const pageNo = page + searchSize;
    let scope;
    if (type === 'preloadedMembers') scope = 'members';
    else if (type === 'connectedParty') scope = 'nonMembers';
    const scrollContainer = this.userSearchContainerRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    if (totalScroll === scrollContainer.scrollHeight) {
      if (type === 'connectedParty' && scope) {
        fetchConnectedPartySearch(searchText, scope, 0, coverUserId, selectedUserIds, pageNo);
      }
    }
    if (totalScroll === scrollContainer.scrollHeight && this.state.searchText === '') {
      this.setState({ usersLoading: true });
      this.props.fetchActiveMembers(this.props.pageNo + 1)
        .then(() => this.setState({ usersLoading: false }));
    }
  }

  sortMemberSearchIds = (a, b) => {
    // Put preselectedForwardingUserId to top of the list
    if (a === this.preSelectedForwardingUserId) {
      return -1;
    }

    if (b === this.preSelectedForwardingUserId) {
      return 1;
    }

    // Sort the rest by lastName
    const nameA = this.props?.users[a]?.lastName?.toLowerCase();
    const nameB = this.props?.users[b]?.lastName?.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  }

  handleSearch = (id, searchValue) => {
    const {
      type,
      userSearchIds,
      fetchUserSearch,
      fetchMemberSearch,
      fetchConnectedPartySearch,
      clearUserSearch,
      selectedUserIds = [],
      coverUserId = '',
    } = this.props;
    const source = 'modalSearch';
    let scope;
    if (type === 'preloadedMembers') scope = 'members';
    else if (type === 'connectedParty') scope = 'nonMembers';

    if (searchValue && searchValue.length > 2) {
      if (type === 'preloadedMembers' && scope) {
        fetchMemberSearch({ search: searchValue, scope });
      } else if (type === 'connectedParty' && scope) {
        fetchConnectedPartySearch(searchValue, scope, 0, coverUserId, selectedUserIds);
      } else {
        fetchUserSearch({ searchValue, scope, source });
      }
    } else if (type === 'preloadedMembers' && !searchValue) {
      this.fetchActiveMembers();
    } else if (userSearchIds.length) {
      clearUserSearch();
    }

    this.setState({ searchText: searchValue });
  };

  render() {
    if (this.props.isHidden) return null;
    const props = {
      defaultRouteMemberId: this.props.defaultRouteMemberId,
      disabledUserIds: this.props.disabledUserIds,
      connectedPartySearchIds: this.props.connectedPartySearchIds,
      connectedPartySearchLoading: this.props.connectedPartySearchLoading,
      contactProfileContainerOpen: this.state.contactProfileContainerOpen,
      coverUserId: this.props.coverUserId,
      handleSearch: this.handleSearch,
      handleSearchSelect: this.props.handleSearchSelect,
      interfaceMode: this.props.interfaceMode,
      isAssignmentSearch: this.props.isAssignmentSearch,
      loggedInUser: this.props.user,
      memberSearchIds: [...this.props.memberSearchIds].sort(this.sortMemberSearchIds),
      memberSearchLoading: this.props.memberSearchLoading,
      onScroll: this.onScroll,
      searchFocus: this.state.searchFocus,
      searchObject: this.state.searchObject,
      searchText: this.state.searchText,
      selectedUserId: this.props.selectedUserId,
      selectedUserIds: this.props.selectedUserIds,
      type: this.props.type,
      userSearchIds: this.props.userSearchIds,
      userSearchLoading: this.props.userSearchLoading,
      userSearchContainerRef: (userSearchContainerRef) => (this.userSearchContainerRef = userSearchContainerRef),
      users: this.props.users,
      usersLoading: this.state.usersLoading,
      isLimitedProvider: this.props.isLimitedProvider,
      handleClearAll: this.props.handleClearAll,
      handleSelectAllItems: this.props.handleSelectAll,
      showViewSelected: this.props.showViewSelected,
    };

    return (
      <UserSearch {...props} />
    );
  }
}

UserSearchContainer.propTypes = {
  clearUserSearch: PropTypes.func,
  coverUserId: PropTypes.number,
  connectedPartySearchIds: PropTypes.array.isRequired,
  connectedPartySearchLoading: PropTypes.bool.isRequired,
  defaultRouteMemberId: PropTypes.number,
  disabledUserIds: PropTypes.array,
  excludedMemberIds: PropTypes.array,
  fetchMemberSearch: PropTypes.func.isRequired,
  fetchActiveMembers: PropTypes.func.isRequired,
  fetchConnectedPartySearch: PropTypes.func.isRequired,
  fetchUserSearch: PropTypes.func,
  fetchUsers: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  handleSearchSelect: PropTypes.func.isRequired,
  interfaceMode: PropTypes.oneOf(['checkbox', 'radio']),
  isAssignmentSearch: PropTypes.bool,
  isHidden: PropTypes.bool,
  memberSearchIds: PropTypes.array.isRequired,
  memberSearchLoading: PropTypes.bool.isRequired,
  selectedUserId: PropTypes.number,
  selectedUserIds: PropTypes.array,
  type: PropTypes.oneOf(['connectedParty', 'preloadedMembers']).isRequired,
  user: PropTypes.object.isRequired,
  userSearchIds: PropTypes.array.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  pageNo: PropTypes.number,
  searchSize: PropTypes.number,
  page: PropTypes.number,
  isLimitedProvider: PropTypes.bool,
  handleClearAll: PropTypes.func,
  handleSelectAll: PropTypes.func,
  showViewSelected: PropTypes.bool,
};

UserSearchContainer.defaultProps = {
  excludedMemberIds: [],
  interfaceMode: 'checkbox',
  isAssignmentSearch: false,
  selectedUserIds: [],
  selectedUserId: -1,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    connectedPartySearchIds: user.connectedPartySearchIds,
    connectedPartySearchLoading: user.connectedPartySearchLoading,
    memberSearchIds: user.memberSearchIds,
    memberSearchLoading: user.memberSearchLoading,
    user: getLoggedInUser(state),
    users: user.users,
    pageNo: user.pageNo,
    userSearchIds: user.userSearchIds,
    userSearchLoading: user.userSearchLoading,
    page: user.page,
    searchSize: user.searchSize,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

const actions = {
  clearUserSearch: UserReducer.clearUserSearch,
  fetchMemberSearch: UserReducer.fetchMemberSearch,
  fetchActiveMembers: UserReducer.fetchActiveMembers,
  fetchConnectedPartySearch: UserReducer.fetchConnectedPartySearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
  fetchUsers: UserReducer.fetchUsers,
};

export default connect(mapStateToProps, actions)(UserSearchContainer);
