import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
} from 'rhinostyle';
import AppointmentCampaignRule from './AppointmentCampaignRule';
import AppointmentCampaignRuleModal from './AppointmentCampaignRuleModal';
import ExpandableBox from './ExpandableBox';
import { TYPE_ATTACHMENT_FORM } from '../constants/Types';
import { generateUUID } from '../helpers/DataHelpers';

const AppointmentCampaignRules = ({
  campaignRules,
  enabled,
  handleChange,
  appointmentEventTypeId,
}) => {
  const [currentCampaignRules, setCurrentCampaignRules] = useState(campaignRules);
  const [activeRuleId, setActiveRuleId] = useState(null);
  const formTemplates = useSelector((state) => state.form.formTemplates);
  const filteredRules = currentCampaignRules?.filter((rule) => rule.appointmentEventTypeId === appointmentEventTypeId) ?? [];

  useEffect(() => {
    setCurrentCampaignRules(campaignRules);
  }, [campaignRules]);

  useEffect(() => {
    handleChange('campaignRules', currentCampaignRules);
  }, [currentCampaignRules]);

  function handleSaveCriteria(criteriaRules) {
    const ruleIndex = currentCampaignRules.findIndex((rule) => rule.id === activeRuleId);
    setCurrentCampaignRules((prevRules) => {
      const updatedRules = [...prevRules];
      const rule = { ...updatedRules[ruleIndex] };
      rule.criteria = criteriaRules;
      updatedRules[ruleIndex] = rule;
      return updatedRules;
    });
    setActiveRuleId(null);
  }

  const handleInputChange = (name, value) => {
    const [field, ruleIdString, subfield] = name.split('.'); // i.e., 'greaterThan-0-years'
    // check if ruleId is uuid or numberic id
    const ruleId = /^\d+$/.test(ruleIdString) ? parseInt(ruleIdString, 10) : ruleIdString;
    const index = currentCampaignRules.findIndex((rule) => rule.id === ruleId);
    const rule = { ...currentCampaignRules[index] };
    setCurrentCampaignRules((prevRules) => {
      const updatedRules = [...prevRules];
      if (field === 'condition') {
        rule.condition = value;
      } else if (field === 'formTemplate') {
        const formTemplate = formTemplates.find((template) => template.formId === value);
        rule.appointmentRuleResults = [{
          attachmentUrl: formTemplate.formId,
          name: formTemplate.title,
          type: 'form',
          attachmentTypeId: TYPE_ATTACHMENT_FORM,
          bytes: 0,
          appointmentEventTypeId,
        }];
      } else if (field === 'criteria') {
        const criteria = { ...rule.criteria };
        if (subfield === 'years' || subfield === 'months' || subfield === 'days') {
          criteria[field] = {
            ...criteria[field],
            [subfield]: value,
          };
        }
        rule.criteria = criteria;
      }

      updatedRules[index] = rule;
      handleChange('campaignRules', updatedRules);

      return updatedRules;
    });
  };

  const handleAddRule = () => {
    const newRule = {
      attribute: 'Age',
      condition: 'greaterThan',
      criteria: {
        begin: {},
        end: {},
      },
      appointmentEventTypeId,
      appointmentRuleResults: [],
      id: generateUUID(),
    };
    setCurrentCampaignRules((prevRules) => [...prevRules || [], newRule]);
  };

  const handleRemoveRule = (ruleId) => {
    const index = currentCampaignRules.findIndex((rule) => rule.id === ruleId);
    const newRules = [...currentCampaignRules];
    newRules.splice(index, 1);
    setCurrentCampaignRules(newRules);
  };

  const handleOpenModal = (ruleId) => {
    setActiveRuleId(ruleId);
  };

  const handleCloseModal = () => {
    setActiveRuleId(null);
  };

  const description = (
    <div className="u-text-secondary u-text-small">
      {`${filteredRules.length} Rule${filteredRules.length === 1 ? '' : 's'} Active`}
    </div>
  );
  return (
    <ExpandableBox
      title="Rules"
      className="appointment_campaign_rules"
      subtitle="Forms will be attached based on the following."
      startOpen={enabled}
      description={description}
    >
      <div className="u-m-t-xsmall">
        {filteredRules.map((rule) => (
          <AppointmentCampaignRule
            key={`rule.${rule.id}`}
            appointmentCampaignRule={rule}
            handleInputChange={handleInputChange}
            handleRemoveRule={handleRemoveRule}
            handleOpenModal={handleOpenModal}
            appointmentEventTypeId={appointmentEventTypeId}
            handleCloseModal={handleCloseModal}
          />
        ))}
        <div className="u-flex-row u-flex-align-items-center u-flex u-m-t-xsmall">
          <Button
            onClick={handleAddRule}
            iconOnly
            size="small"
            type="secondary"
            title="Create rule"
            className="u-border-radius u-m-r"
            data-feature-tag="appointment-campaign-add-rule"
          >
            <Icon icon="add" />
          </Button>
          Add {filteredRules?.length > 0 ? 'another' : 'first'} rule
        </div>
      </div>
      <AppointmentCampaignRuleModal
        activeRuleId={activeRuleId}
        currentCampaignRules={filteredRules}
        handleCloseModal={handleCloseModal}
        handleSaveCriteria={handleSaveCriteria}
        activeRule={filteredRules[activeRuleId]}
      />
    </ExpandableBox>
  );
};

AppointmentCampaignRules.propTypes = {
  handleChange: PropTypes.func,
  campaignRules: PropTypes.array,
  enabled: PropTypes.bool,
  appointmentEventTypeId: PropTypes.number,
};

export default AppointmentCampaignRules;
