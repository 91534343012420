import React, { useState, useEffect } from 'react';
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UtilityInlineGrid,
  Button,
} from 'rhinostyle';

const MAX_AGE_YEARS = '120';
const MAX_AGE_DAYS = '31';
const MAX_AGE_MONTHS = '12';

const AppointmentCampaignRuleModal = ({
  activeRuleId,
  handleCloseModal,
  handleSaveCriteria,
  currentCampaignRules,
}) => {
  const activeRule = currentCampaignRules.find((rule) => rule.id === activeRuleId);
  const [criteriaRules, setCriteriaRules] = useState(activeRule?.criteria || { begin: {}, end: {} });
  const [error, setError] = useState(null);

  const getCriteriaValue = (obj) => obj?.years ?? obj?.months ?? obj?.days;

  function hasCriteriaValues() {
    if (activeRule?.condition === 'greaterThan') {
      return !!getCriteriaValue(criteriaRules.begin);
    } else if (activeRule?.condition === 'lessThan') {
      return !!getCriteriaValue(criteriaRules.end);
    } else if (activeRule?.condition === 'between') {
      return !!(getCriteriaValue(criteriaRules.end) && getCriteriaValue(criteriaRules.begin));
    }
    return false;
  }

  useEffect(() => {
    if (activeRule) {
      setCriteriaRules(activeRule?.criteria);
    } else {
      setError(null);
      setCriteriaRules({ begin: {}, end: {} });
    }
  }, [activeRule, activeRule?.criteria, activeRuleId]);

  function handleInputChange(name, value) {
    const [field, subfield] = name.split('.'); // i.e., 'begin.years'
    setCriteriaRules((prev) => ({
      ...prev,
      [field]: {
        ...prev?.[field] || {},
        [subfield]: value,
      },
    }));
  }

  function getTotalDays(criteria) {
    return (parseInt(criteria.years || 0, 10) * 365)
      + (parseInt(criteria.months || 0, 10) * 30)
      + parseInt(criteria.days || 0, 10);
  }

  function validateBetweenCriteria() {
    if (criteriaRules.begin && criteriaRules.end) {
      const begin = getTotalDays(criteriaRules.begin);
      const end = getTotalDays(criteriaRules.end);
      return begin < end;
    }
    return false;
  }

  function handleSave() {
    if (activeRule.condition === 'between' && !validateBetweenCriteria()) {
      setError('Ending criteria must be greater than beginning criteria');
    } else {
      setError(null);
      handleSaveCriteria(criteriaRules);
      handleCloseModal();
    }
  }

  const renderAgeRules = () => {
    if (activeRuleId === null) return null;
    if (activeRule.condition === 'greaterThan') {
      return renderGreaterThanRule(activeRuleId, criteriaRules.begin || {});
    } else if (activeRule.condition === 'lessThan') {
      return renderLessThanRule(activeRuleId, criteriaRules.end || {});
    } else if (activeRule.condition === 'between') {
      return renderBetweenRule(activeRuleId, criteriaRules || {});
    }
    return null;
  };

  function handleAgeChange(name, value, max) {
    const numericValue = parseInt(value, 10);
    const numericMax = parseInt(max, 10);
    if (numericValue > numericMax) {
      handleInputChange(name, max);
    } else if (value < 0) {
      handleInputChange(name, '0');
    } else {
      handleInputChange(name, value);
    }
  }

  function renderInput(inputName, label, inputValue, max) {
    return (
      <div className="u-flex u-flex-row u-flex-direction-row u-flex-justify-center u-m-t-small u-flex-align-items-center">
        <div className="u-text-left u-p-r u-font-weight-bold appointment-campaign-rule__input">
          {label}
        </div>
        <div className="">
          <Input
            name={inputName}
            type="number"
            initialValue={inputValue}
            value={inputValue}
            onChange={(name, value) => handleAgeChange(name, value, max)}
            customHTMLAttributes={{
              max,
              min: '0',
            }}
            className="appointment-campaign-rule__input"
          />
        </div>
      </div>
    );
  }

  function renderGreaterThanRule(index, beginCriteria = {}, isBetween = false) {
    return (
      <div key={`greaterThan-${index}`} className="u-flex-direction-column u-flex-align-items-center">
        {isBetween && (<div className="u-text-center u-m-b">BEGINNING</div>)}
        {renderInput('begin.years', 'Years', beginCriteria.years, MAX_AGE_YEARS)}
        {renderInput('begin.months', 'Months', beginCriteria.months, MAX_AGE_MONTHS)}
        {renderInput('begin.days', 'Days', beginCriteria.days, MAX_AGE_DAYS)}
      </div>
    );
  }

  function renderLessThanRule(index, endCriteria = {}, isBetween = false) {
    return (
      <div key={`lessThan-${index}`} className="u-flex-direction-column u-flex-align-items-center">
        {isBetween && (<div className="u-text-center u-m-b">ENDING</div>)}
        {renderInput('end.years', 'Years', endCriteria.years, MAX_AGE_YEARS)}
        {renderInput('end.months', 'Months', endCriteria.months, MAX_AGE_MONTHS)}
        {renderInput('end.days', 'Days', endCriteria.days, MAX_AGE_DAYS)}
      </div>
    );
  }

  function renderBetweenRule(index, criteria) {
    return (
      <div key={`between-${index}`} className="row">
        <div className="column-6@small">
          {renderGreaterThanRule(index, criteria.begin, true)}
        </div>
        <div className="column-6@small">
          {renderLessThanRule(index, criteria.end, true)}
        </div>
      </div>
    );
  }

  function convertCamelCaseToTitleCase(text = '') {
    return text?.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()) || '';
  }

  const conditionTitle = convertCamelCaseToTitleCase(activeRule?.condition || '');

  const titleSub = activeRule?.condition === 'between' ? '' : `${activeRule?.condition === 'greaterThan' ? 'Greater' : 'Less'} than or equal to`;

  const renderInsideModal = () => {
    if (!currentCampaignRules) return null;
    return (
      <div>
        <ModalHeader
          titleClass="u-text-primary"
          titleSub={titleSub}
          title={`Age ${conditionTitle} Criteria`}
          onClose={handleCloseModal}
        />
        {error && <div className="u-text-danger u-text-center">{error}</div>}
        <ModalBody>
          {renderAgeRules()}
        </ModalBody>
        <ModalFooter>
          <UtilityInlineGrid align="between">
            <Button type="input" onClick={handleCloseModal}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSave}
              className="button button--primary"
              disabled={!hasCriteriaValues()}
            >
              Save
            </Button>
          </UtilityInlineGrid>
        </ModalFooter>
      </div>
    );
  };

  return (
    <Modal open={activeRuleId !== null} onClose={handleCloseModal}>
      {renderInsideModal()}
    </Modal>
  );
};

export default AppointmentCampaignRuleModal;
