import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
  SmartTable,
} from 'rhinostyle';
import cx from 'classnames';

import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import NotificationService from '../services/NotificationService';
import EmptyMessage from '../components/EmptyMessage';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { PRESCRIPTION_NOTIFICATION_EDIT } from '../constants/UserPermissionsConstants';
import PageLoader from '../components/PageLoader';
import { useQueryParams, usePrescriptionCampaigns } from '../hooks';
import { QUERY_STATUS_FULFILLED } from '../constants/v3';
import SmartTableHeader from '../components/SmartTableHeader';
import { PRESCRIPTION_NOTIFICATION_HEADERS } from '../helpers/PrescriptionHelpers';
import { cloneDeep } from '../helpers/DataHelpers';
import { numericDateFormatter } from '../helpers/DateHelpers';
import FilterPage from '../components/FilterPage';
import PrescriptionCampaignSummaryPanel from '../components/PrescriptionCampaignSummaryPanel';
import { APPOINTMENTS_PAGE_SIZE } from '../constants/AppConstants';

const PrescriptionCampaignsView = (props) => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const prescriptionCampaignId = Number(params?.prescriptionCampaignId);
  const {
    loggedInUserPermissions,
  } = props;

  const initialValue = {
    sortBy: 'name',
    sortOrder: 'asc',
    pageNo: 0,
  };

  const [headersConfig] = useState({ ...PRESCRIPTION_NOTIFICATION_HEADERS });
  const [keys, onSetKeys] = useQueryParams({ initialValue });

  const { data, error, status } = usePrescriptionCampaigns(keys);
  const prescriptionCampaigns = data?.results || [];

  useEffect(() => {
    if (status === QUERY_STATUS_FULFILLED) {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (error) {
      NotificationService('fetchRoutes', error);
    }
  }, [error]);

  const handleCreateClick = () => {
    history.push('/settings/organization/prescription-notifications/create');
  };

  const appPanelClasses = cx('app-panels', {
    'app-panels--profile': prescriptionCampaignId,
  });

  function handleTogglePanel(id) {
    const shouldClose = prescriptionCampaignId === id;
    if (shouldClose) {
      history.push('/settings/organization/prescription-notifications');
    } else {
      history.push(`/settings/organization/prescription-notifications/${id}`);
    }
  }
  function getOpposite(sortDirection) {
    if (sortDirection === 'asc') return 'desc';
    return 'asc';
  }

  function handleSort(type) {
    onSetKeys(({
      sortBy: type,
      sortOrder: type === keys.sortBy ? getOpposite(keys.sortOrder) : 'desc',
    }));
  }

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      handleTogglePanel(rowInfo?.original?.id);
    },
    style: {
      cursor: 'pointer',
    },
    className: rowInfo?.id === prescriptionCampaignId ? 'selected-prescription-template' : 'form-row',
  });

  const headerStyle = {
    padding: 0,
    backgroundColor: '#f9f9f9',
  };

  const columns = [
    {
      Header: () => (
        <SmartTableHeader
          headerName="Title"
          sortKey="name"
          headers={cloneDeep(headersConfig)}
        />
      ),
      headerStyle,
      accessor: 'name',
      Cell: (row) => (
        <div>
          <div className="title-text">
            {row.original.name}
            {row.original.default && (
            <span className="u-text-small u-text-accent u-p-l">Default</span>
            )}
          </div>
        </div>
      ),
      fixed: 'left',
      sortMethod: () => handleSort('name'),
    },
    {
      Header: () => (
        <SmartTableHeader
          headerName="Updated"
          sortKey="lastUpdatedAt"
          headers={cloneDeep(headersConfig)}
        />
      ),
      headerStyle,
      accessor: 'lastUpdatedAt',
      Cell: (row) => (
        <div>{`${numericDateFormatter(row.original.lastUpdatedAt)}`}</div>
      ),
      sortMethod: () => handleSort('lastUpdatedAt'),
    },
  ];

  const renderList = () => (prescriptionCampaigns?.length > 0 ? (
    <SmartTable
      data={prescriptionCampaigns}
      getTrProps={rowProps}
      showPagination={false}
      pageSize={prescriptionCampaigns?.length}
      sticky
      columns={columns}
      sortable
      manual={false}
      striped
    />
  ) : (
    <div className="form-list__noforms_wrapper">
      <EmptyMessage section="Prescription Notifications" />
    </div>
  ));

  function handlePagination(type) {
    if ((type === 'previous' && keys.pageNo > 0) || type === 'next') {
      onSetKeys({
        pageNo: type === 'previous' ? keys.pageNo - 1 || 0 : keys.pageNo + 1 || 0,
      });
    }
  }

  if (loading) {
    return <PageLoader />;
  }
  return (
    <div className="app-page">
      <div className={appPanelClasses}>
        <div className="list-panel__wrapper">
          <div className="list-panel">
            <div className="app-page__header">
              <div className="app-page__header__title">
                Prescription Notifications
              </div>
              {loggedInUserPermissions.includes(PRESCRIPTION_NOTIFICATION_EDIT) && (
              <div className="app-page__header__action">
                <Button
                  onClick={handleCreateClick}
                  iconOnly
                  type="secondary"
                  title="Create Prescription Notification"
                  data-feature-tag="prescription-notification_create-route"
                >
                  <Icon icon="add" />
                </Button>
              </div>
              )}
            </div>
            <FilterPage
              hasPanels
              hasFilters={false}
              header={prescriptionCampaigns?.length > 0 ? 'Total Prescription Notifications' : ''}
              totalCount={data.total}
              showClearAll={false}
              pageNumber={keys.pageNo}
              handlePagination={handlePagination}
              pageItemCount={prescriptionCampaigns?.length}
              pageSize={APPOINTMENTS_PAGE_SIZE}
              type="prescription-notifications"
              showPageHeader
            >
              <div className="list-panel__body" data-feature-tag="prescriptionCampaignList">
                <div className="list-panel" />
                {prescriptionCampaigns?.length > 0 ? renderList() : <EmptyMessage section="Prescription Notifications" />}
              </div>
            </FilterPage>
          </div>
        </div>
        {!!prescriptionCampaignId && (<PrescriptionCampaignSummaryPanel />)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedInUserPermissions: getLoggedInUserPermissionNames(state),
});

PrescriptionCampaignsView.propTypes = {
  loggedInUserPermissions: PropTypes.array,
};

export default connect(mapStateToProps)(PrescriptionCampaignsView);
