import * as StringHelpers from './StringHelpers';
import store from '../store';

export function formatPhone(phone) {
  if (!phone) {
    return '';
  }
  let formattedPhone = phone;
  const digitsOnly = formattedPhone.replace(/[^0-9]/g, '');
  const hasUSCountryCode = phone && phone.length && (phone.startsWith('1') || phone.startsWith('+1')) && digitsOnly.length === 11;
  const hasAreaCode = phone && phone.length && phone.length === 10 && digitsOnly.length === 10;
  // valid number with country code
  if (hasUSCountryCode) {
    formattedPhone = digitsOnly.substring(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  // valid number with area code
  if (hasAreaCode) {
    formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return formattedPhone;
}

export function isPhoneNumberValid(phone) {
  if (phone === '') {
    return true;
  } if (!phone) {
    return false;
  }
  const digitsOnly = phone.replace(/[^0-9]/g, '');
  const hasUSCountryCode = phone && phone.length && (phone.startsWith('1') || phone.startsWith('+1')) && digitsOnly.length === 11;
  const hasAreaCode = phone && phone.length && phone.length === 10 && digitsOnly.length === 10;
  return !!hasUSCountryCode || !!hasAreaCode;
}

/**
 * Remove any non digit character, and add '+1' to beginning of number.
 */
export function normalizePhone(phone) {
  let returnVal = phone.replace(/[^0-9]/g, '');

  if (returnVal[0] === '1') {
    returnVal = `+${returnVal}`;
  } else {
    returnVal = `+1${returnVal}`;
  }

  return returnVal;
}

export function formatTypes(phone) {
  const currentState = store.getState();
  const { types } = currentState.type;
  return phone.label || (phone.typeId && StringHelpers.capitalize(types[phone.typeId].value));
}

export function formatPhoneIfValid(phoneNumber) {
  let validPhoneNumber = null;
  if (phoneNumber?.length > 0) {
    const formattedNumber = normalizePhone(phoneNumber);
    if (isPhoneNumberValid(formattedNumber)) {
      validPhoneNumber = formattedNumber;
    }
  } return validPhoneNumber;
}

// format phone number with area code but without +
export function PhoneDigits(phone) {
  if (!phone) {
    return '';
  }
  const digitsOnly = phone.replace(/[^0-9]/g, '');
  return digitsOnly;
}
