import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Dropdown,
  DropdownMenuHeader,
  DropdownMenuItem,
} from 'rhinostyle';

import { updateUserPreferences } from '../reducers/userReducer';
import { getLoggedInUser } from '../selectors/userSelectors';

function GroupSortMenu({ section, ...props }) {
  const dispatch = useDispatch();
  const { currentUser } = props;
  const { preferences } = currentUser;
  const { isInboxSortedAz, isChatSortedAz } = preferences;
  const [selectedId, setSelectedId] = useState();

  const SECTION_INBOX = 'inbox';
  const SECTION_CHAT = 'chat';
  const SORT_CHRONOLOGICALLY = 1;
  const SORT_AZ = 2;

  const menuOptions = [
    { label: 'Date Created', id: SORT_CHRONOLOGICALLY },
    { label: 'A-Z', id: SORT_AZ },
  ];

  function handleSelect(id) {
    if (id !== selectedId) {
      let payload;

      if (section === SECTION_INBOX) {
        payload = { ...preferences, isInboxSortedAz: id === SORT_AZ };
      } else if (section === SECTION_CHAT) {
        payload = { ...preferences, isChatSortedAz: id === SORT_AZ };
      }

      setSelectedId(id);

      if (payload) dispatch(updateUserPreferences(currentUser.id, payload));
    }
  }

  useEffect(() => {
    const pref = section === SECTION_INBOX ? isInboxSortedAz : isChatSortedAz;
    setSelectedId(pref ? SORT_AZ : SORT_CHRONOLOGICALLY);
  }, []);

  function renderMenuItem(option, idx) {
    return (
      <DropdownMenuItem
        label={option.label}
        id={option.id}
        key={idx}
        onClick={() => handleSelect(option.id)}
      />
    );
  }

  return (
    <Dropdown
      title="Sort groups"
      dataCypress={`sort-${section}-groups`}
      type="link"
      icon="dots-vertical"
      position="right"
      activeKey={selectedId}
      hideCaret
      lockLabel
    >
      <DropdownMenuHeader label="Sort by:" />
      {menuOptions.map((option, idx) => renderMenuItem(option, idx))}
    </Dropdown>
  );
}

GroupSortMenu.propTypes = {
  currentUser: PropTypes.object,
  section: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
});

export default connect(mapStateToProps)(GroupSortMenu);
