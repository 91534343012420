import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import NewConnectedPartyContactModal from '../components/NewConnectedPartyContactModal';
import { Types } from '../constants';
import { userSelectors } from '../selectors';
import * as UserReducer from '../reducers/userReducer';
import { capitalizeProp } from '../helpers/StringHelpers';
import { PhoneHelpers, ValidationHelpers, DataHelpers } from '../helpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import {
  TYPE_RHINOPAY_CONSENT_PENDING,
  TYPE_HIPAA_CONSENT_PENDING,
  TYPE_MARKETING_CONSENT_PENDING,
} from '../constants/Types';

class NewConnectedPartyContactModalContainer extends Component {
  static initialState = {
    create: true,
    formInProgress: false,
    firstName: '',
    lastName: '',
    middleName: '',
    birthday: null,
    note: '',
    userPhones: { 0: {} },
    userEmails: { 0: {} },
    errors: {},
    displayId: '',
    hipaaStatusId: null,
    hipaaStatusTypeId: TYPE_HIPAA_CONSENT_PENDING,
    rhinopayConsentStatusTypeId: TYPE_RHINOPAY_CONSENT_PENDING,
    marketingConsentStatusTypeId: TYPE_MARKETING_CONSENT_PENDING,
    id: -1,
    integrated: false,
    lastIntegrationUpdate: '',
    mode: 'create',
    noteIsImportant: false,
    preferredName: '',
    prefixId: -1,
    roles: [],
    sex: '',
    suffixId: -1,
    typeId: Types.TYPE_PATIENT,
    searchText: '',
    phoneSearchIdx: -1,
  };

  state = {
    ...NewConnectedPartyContactModalContainer.initialState,
  };

  get payload() {
    const reduceUserPhonesFromObject = (acc, currVal) => {
      const phone = { ...this.state.userPhones[currVal] };

      if (!phone.markedToDelete && phone.value) {
        phone.value = PhoneHelpers.normalizePhone(phone.value);
        return acc.concat(phone);
      }

      return acc;
    };

    const reduceUserEmailsFromObject = (acc, currVal) => {
      const email = { ...this.state.userEmails[currVal] };

      if (!email.markedToDelete && email.value) {
        return acc.concat(email);
      }

      return acc;
    };

    // Birthday is cleared if invalid, so translate to proper format if we have valid moment object
    const birthday = this.state.birthday ? moment(this.state.birthday).format('YYYY-MM-DD') : '';

    const payload = {
      birthday,
      emails: Object.keys(this.state.userEmails).reduce(reduceUserEmailsFromObject, []),
      externalIds: this.state.displayId ? { displayId: this.state.displayId } : null,
      firstName: this.state.firstName,
      hipaaStatus: this.getHipaaStatus(),
      id: this.state.id,
      lastName: this.state.lastName,
      marketingConsentStatus: this.getMarketingConsentStatus(),
      middleName: this.state.middleName,
      note: this.state.note,
      noteIsImportant: this.state.noteIsImportant,
      phones: Object.keys(this.state.userPhones).reduce(reduceUserPhonesFromObject, []),
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      rhinopayConsentStatus: this.getRhinopayConsentStatus(),
      roles: this.state.roles,
      sex: this.state.sex,
      suffixId: this.state.suffixId,
      typeId: this.state.typeId,
    };

    if (payload.suffixId === -1) payload.suffixId = '';
    if (payload.prefixId === -1) payload.prefixId = '';

    return payload;
  }

  getHipaaStatus = () => ({
    trusteeId: this.state.hipaaStatusTypeId !== TYPE_HIPAA_CONSENT_PENDING ? this.props.user.id : null,
    typeId: this.state.hipaaStatusTypeId,
    changed: this.state.hipaaStatusTypeId !== TYPE_HIPAA_CONSENT_PENDING,
  });

  getRhinopayConsentStatus = () => ({
    trusteeId: this.state.rhinopayConsentStatusTypeId !== TYPE_RHINOPAY_CONSENT_PENDING ? this.props.user.id : null,
    typeId: this.state.rhinopayConsentStatusTypeId,
    changed: this.state.rhinopayConsentStatusTypeId !== TYPE_RHINOPAY_CONSENT_PENDING,
  });

  getMarketingConsentStatus = () => ({
    trusteeId: this.state.marketingConsentStatusTypeId !== Types.TYPE_MARKETING_CONSENT_PENDING ? this.props.user.id : null,
    typeId: this.state.marketingConsentStatusTypeId,
    changed: this.state.marketingConsentStatusTypeId !== Types.TYPE_MARKETING_CONSENT_PENDING,
  });

  resetState = () => {
    this.setState(NewConnectedPartyContactModalContainer.initialState);
  };

  handleReceiveContactData = (key, data, dict) => {
    const newState = {
      [dict]: JSON.parse(JSON.stringify(this.state[dict])),
    };

    newState[dict][key] = data;

    this.setState(newState);
  }

  handleRemoveContactData = (key, dict) => {
    const contactData = { ...this.state[dict] };
    const contactKey = { ...contactData[key] };

    contactKey.markedToDelete = true;

    contactData[key] = contactKey;

    this.setState({ [dict]: contactData });
  }

  handleBirthdayChange = (date) => {
    this.setState({
      birthday: date,
    });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  handleUpdateOwnership = (phonesArray) => phonesArray.map((p) => {
    const newPhone = DataHelpers.cloneDeep(p);
    if (newPhone.newOwnerId) {
      newPhone.ownerId = newPhone.newOwnerId;
    }
    return newPhone;
  });

  handleSearch = (value) => {
    if (value && value.length === 14) {
      this.props.fetchPhoneSearch(value, 'nonMembers', 'phone', true);
    } else if (this.props.modalPhoneSearchIds.length) {
      this.props.clearUserSearch();
    }

    this.setState({ searchText: value });
  }

  handlePhoneSearchIdx = (idx) => {
    this.setState({ phoneSearchIdx: idx, searchText: '' });
  }

  handleSubmit = () => {
    const { payload } = this;
    const errors = ValidationService(ValidationShapers.shapeContactProfile(payload));
    const errorCount = Object.keys(errors).length;
    if (payload.phones) {
      payload.phones = this.handleUpdateOwnership(payload.phones);
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.modalContainer);
      });
    } else {
      this.props.createUser(payload, true, true)
        .then((response) => {
          if (this.props.error && this.props.error.data) {
            this.setState({
              errors: ValidationHelpers.handleServerError(this.props.error.data),
            }, () => {
              ValidationHelpers.handleValidationErrors(this.state.errors, this.modalContainer);
            });
          }
          this.props.handleSearchSelect(response.payload.userId, true, null, response.connectionTypeId);
          this.props.closeModal();
        });
    }
  }

  // set the ref to the modal container created by ReactDOM.createPortal (Rhinostyle)
  handleSetModalContainer = (modalContainer) => {
    if (modalContainer && modalContainer.modal) this.modalContainer = modalContainer.modal;
  }

  render() {
    const props = {
      actionTitle: this.state.mode === 'update' ? 'Update Contact' : 'Create Contact',
      actionType: this.state.mode === 'update' ? 'primary' : 'secondary',
      birthday: this.state.birthday,
      closeModal: this.props.closeModal,
      emailTypes: this.props.emailTypes.map(capitalizeProp('value')),
      errors: this.state.errors,
      displayId: this.state.displayId,
      firstName: this.state.firstName,
      formInProgress: this.props.formInProgress,
      handleBirthdayChange: this.handleBirthdayChange,
      handleChange: this.handleChange,
      handleFormChanges: this.props.handleFormChanges,
      handleReceiveContactData: this.handleReceiveContactData,
      handleRemoveContactData: this.handleRemoveContactData,
      handleSearch: this.handleSearch,
      handleSetModalContainer: this.handleSetModalContainer,
      handleSubmit: this.handleSubmit,
      hipaaStatusTypeId: this.state.hipaaStatusTypeId,
      integrated: this.state.integrated,
      lastName: this.state.lastName,
      marketingConsentStatusTypeId: this.state.marketingConsentStatusTypeId,
      middleName: this.state.middleName,
      note: this.state.note,
      noteIsImportant: this.state.noteIsImportant,
      open: this.props.open,
      modalPhoneSearchLoading: this.props.modalPhoneSearchLoading,
      phoneTypes: this.props.phoneTypes.map(capitalizeProp('value')),
      preferredName: this.state.preferredName,
      prefixId: this.state.prefixId,
      prefixOpts: this.props.prefixOpts,
      resetState: this.resetState,
      rhinopayConsentStatusTypeId: this.state.rhinopayConsentStatusTypeId,
      sex: this.state.sex,
      searchText: this.state.searchText,
      suffixId: this.state.suffixId,
      suffixOpts: this.props.suffixOpts,
      typeId: this.state.typeId,
      userEmails: this.state.userEmails,
      userId: this.state.id,
      userPhones: this.state.userPhones,
      phones: this.props.phones,
      modalPhoneSearchIds: this.props.modalPhoneSearchIds,
      clearUserSearch: this.props.clearUserSearch,
      users: this.props.users,
      handlePhoneSearchIdx: this.handlePhoneSearchIdx,
      phoneSearchIdx: this.state.phoneSearchIdx,
      isRhinopayEnabled: this.props.isRhinopayEnabled,
    };

    return <NewConnectedPartyContactModal {...props} />;
  }
}

NewConnectedPartyContactModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  emailTypes: PropTypes.array.isRequired,
  error: PropTypes.object,
  formInProgress: PropTypes.bool.isRequired,
  handleFormChanges: PropTypes.func,
  handleSearchSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalPhoneSearchLoading: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool.isRequired,
  phoneTypes: PropTypes.array.isRequired,
  prefixes: PropTypes.object.isRequired,
  prefixIds: PropTypes.array.isRequired,
  prefixOpts: PropTypes.array,
  suffixes: PropTypes.object.isRequired,
  suffixIds: PropTypes.array,
  suffixOpts: PropTypes.array,
  searchText: PropTypes.string,
  user: PropTypes.object,
  users: PropTypes.object,
  phones: PropTypes.object,
  phoneSearchIdx: PropTypes.number,
  handlePhoneSearchIdx: PropTypes.func,
  clearUserSearch: PropTypes.func,
  fetchPhoneSearch: PropTypes.func,
  modalPhoneSearchIds: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { ui, user, prefix, suffix, phone } = state;

  return {
    error: ui.error,
    formInProgress: ui.modalFormInProgress,
    prefixes: prefix.prefixes,
    prefixIds: prefix.prefixIds,
    suffixes: suffix.suffixes,
    suffixIds: suffix.suffixIds,
    user: userSelectors.getLoggedInUser(state),
    users: user.users,
    phones: phone.phones,
    modalPhoneSearchIds: user.modalPhoneSearchIds,
    modalPhoneSearchLoading: user.modalPhoneSearchLoading,
  };
};

const actions = {
  createUser: UserReducer.createUser,
  fetchPhoneSearch: UserReducer.fetchPhoneSearch,
  clearUserSearch: UserReducer.clearUserSearch,
};

export default connect(mapStateToProps, actions)(NewConnectedPartyContactModalContainer);
