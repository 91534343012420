import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Close,
  Select,
  Button,
} from 'rhinostyle';

const defaultOption = { id: -1, value: '' };

const ATTRIBUTE_OPTIONS = [{
  id: 1,
  value: 'Age',
}];

const CONDITION_OPTIONS = [
  defaultOption,
  {
    id: 'greaterThan',
    value: 'Greater Than',
  }, {
    id: 'lessThan',
    value: 'Less Than',
  }, {
    id: 'between',
    value: 'Between',
  }];

const AppointmentCampaignRule = ({
  appointmentEventTypeId,
  handleOpenModal,
  handleRemoveRule,
  appointmentCampaignRule,
  handleInputChange,
}) => {
  const formTemplates = useSelector((state) => state.form.formTemplates);
  const {
    id,
    attribute,
    condition,
    criteria,
    appointmentRuleResults,
  } = appointmentCampaignRule;

  const formTemplateOptions = [defaultOption,
    ...formTemplates?.map((template) => ({ id: template.formId, value: template.title })) ?? []];

  const formatUnit = (value, type) => {
    const num = Number(value) || 0;
    return num ? `${num} ${type}` : '';
  };

  function handleSelect(name, value) {
    if (value !== -1) {
      handleInputChange(name, value);
    }
  }

  const formatLabel = (time = {}) => [
    formatUnit(time.years, 'Y'),
    formatUnit(time.months, 'M'),
    formatUnit(time.days, 'D'),
  ].join(' ');

  function renderCriteriaButton() {
    const hasAnyCriteria = Object.values(criteria).some((group) =>
      Object.values(group).some((v) => Number(v) > 0));

    const greaterThanLabel = formatLabel(criteria.begin);
    const lessThanLabel = formatLabel(criteria.end);
    const betweenLabel = `${greaterThanLabel} - ${lessThanLabel}`;

    let label = 'Select Criteria';

    if (hasAnyCriteria) {
      if (condition === 'greaterThan') {
        label = greaterThanLabel;
      } else if (condition === 'lessThan') {
        label = lessThanLabel;
      } else if (condition === 'between') {
        label = betweenLabel;
      }
    }

    return (
      <div className="form__group u-text-small">
        <label>Criteria</label>
        <Button
          onClick={() => handleOpenModal(id)}
          name={`criteria-button.${id}.${appointmentEventTypeId}`}
          label={label}
          outlined={hasAnyCriteria}
          type={hasAnyCriteria ? 'primary' : 'default'}
          size="small"
          block
          className="form__group form__group--wrapper form__control u-text-small"
        >
          {label}
        </Button>
      </div>
    );
  }

  return (
    <div key={`rule.${id}`} className="u-m-b-small">
      <div className="row">
        <div className="column-2@small column-6@xsmall">
          <Select
            onSelect={handleSelect}
            selected={ATTRIBUTE_OPTIONS.find((option) => option.value === attribute)?.id}
            name={`attribute.${id}`}
            label="When"
            options={ATTRIBUTE_OPTIONS}
            dataCypress="attribute"
            className="u-text-small u-p-r-small"
          />
        </div>

        <div className="column-3@small column-6@xsmall">
          <Select
            onSelect={handleSelect}
            selected={CONDITION_OPTIONS.find((option) => option.id === condition)?.id}
            name={`condition.${id}`}
            label="Is"
            options={CONDITION_OPTIONS}
            dataCypress="condition"
            className="u-text-small"
          />
        </div>
        <div className="column-3@small column-6@xsmall">
          {renderCriteriaButton()}
        </div>
        <div className="column-4@small column-6@xsmall">
          <div className="u-flex-row u-flex-align-items-center u-flex">
            <div className="column-11@xsmall">
              <Select
                onSelect={handleSelect}
                selected={formTemplateOptions.find((option) => option.id === appointmentRuleResults?.[0]?.attachmentUrl ||
              option.id === appointmentRuleResults?.[0]?.attachmentUrl)?.id}
                name={`formTemplate.${id}`}
                label="Form Template"
                options={formTemplateOptions}
                dataCypress="form-template"
                className="u-text-small"
              />
            </div>
            <div className="column-1xsmall">
              <Close
                onClick={() => handleRemoveRule(id)}
                className="u-m-l-small"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AppointmentCampaignRule.propTypes = {
  appointmentCampaignRule: PropTypes.object,
  handleInputChange: PropTypes.func,
  appointmentEventTypeId: PropTypes.number,
  handleOpenModal: PropTypes.func,
  handleRemoveRule: PropTypes.func,
};

export default AppointmentCampaignRule;
