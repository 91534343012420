import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAppointmentForms } from '../services/RhinoformService';
import { getLoggedInUserPermissionNames } from '../selectors/userSelectors';
import { getAppointmentCampaignByAppointmentType } from '../selectors/appointmentCampaignSelectors';
import {
  TYPE_APPOINTMENT_EVENT_REMINDER1,
  TYPE_APPOINTMENT_EVENT_SCHEDULED,
  TYPE_APPOINTMENT_EVENT_REMINDER2,
  TYPE_APPOINTMENT_EVENT_REMINDER3,
  TYPE_APPOINTMENT_EVENT_REMINDER4,
} from '../constants/Types';
import AppointmentFormStatus from './AppointmentsFormStatus';
import { FORM_VIEW } from '../constants/UserPermissionsConstants';

const AppointmentForms = (props) => {
  const {
    appointmentHash,
    appointmentCampaign,
  } = props;
  const { campaignRules } = appointmentCampaign;
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (appointmentCampaign?.attachments?.some((attachment) => isForm(attachment)) || campaignRules?.length > 0) {
      fetchAppointmentForms();
    }
    return () => setLoading(false);
  }, [appointmentCampaign]);

  async function fetchAppointmentForms() {
    const attachedForms = appointmentCampaign.attachments?.filter((attachment) => isForm(attachment));
    if (attachedForms?.length > 0 || campaignRules?.length > 0) {
      const response = await getAppointmentForms(appointmentHash);
      if (loading && response?.data?.forms) {
        setForms(response.data.forms);
      }
    }

    setLoading(false);
  }

  function isForm(attachment) {
    return attachment.type?.name === 'form';
  }

  function isMessageType(attachment, type) {
    return attachment.appointmentEventTypeId === type;
  }

  function getAttachedFormsByMessageType(type, isEnabled) {
    let attachedForms = [];
    if (isEnabled) {
      attachedForms = appointmentCampaign.attachments?.filter((attachment) => isForm(attachment) && isMessageType(attachment, type));
      if (campaignRules?.length > 0) {
        campaignRules.forEach((rule) => {
          const { appointmentRuleResults } = rule;
          if (appointmentRuleResults?.length > 0 && rule.appointmentEventTypeId === type) {
            appointmentRuleResults.forEach((result) => attachedForms.push(result));
          }
        });
      }
    }
    return attachedForms;
  }

  function getSentMessageForms(appointmentEventTypeId, isEnabled) {
    const attachedMessageForms = getAttachedFormsByMessageType(appointmentEventTypeId, isEnabled);
    const sentForms = attachedMessageForms.length > 0 ? forms?.filter((form) => isMessageType(form.appointment, appointmentEventTypeId)) : [];
    return sentForms?.length > 0 ? sentForms : attachedMessageForms;
  }

  function renderFormStatusByMessageType(appointmentEventTypeId, messageEnabled) {
    const messageForms = getSentMessageForms(appointmentEventTypeId, messageEnabled);
    return (
      <div className="appointment-forms__row">
        {messageForms?.length > 0 && messageForms.map((form) => (
          <AppointmentFormStatus
            form={form}
            key={form.formExternalId || form.attachmentUrl}
          />
        ))}
      </div>
    );
  }

  return !loading && (
    <div className="appointment-forms">
      {renderFormStatusByMessageType(TYPE_APPOINTMENT_EVENT_SCHEDULED, appointmentCampaign.appointmentScheduledEnabled)}
      {renderFormStatusByMessageType(TYPE_APPOINTMENT_EVENT_REMINDER1, appointmentCampaign.appointmentReminder1Enabled)}
      {renderFormStatusByMessageType(TYPE_APPOINTMENT_EVENT_REMINDER2, appointmentCampaign.appointmentReminder2Enabled)}
      {appointmentCampaign.appointmentReminder3Enabled && (
        renderFormStatusByMessageType(TYPE_APPOINTMENT_EVENT_REMINDER3, appointmentCampaign.appointmentReminder3Enabled)
      )}
      {appointmentCampaign.appointmentReminder4Enabled && (
        renderFormStatusByMessageType(TYPE_APPOINTMENT_EVENT_REMINDER4, appointmentCampaign.appointmentReminder4Enabled)
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const isRhinoformEnabled = !!state.form.org?.isRhinoformEnabled;
  return {
    appointmentCampaign: getAppointmentCampaignByAppointmentType(state, props),
    hasRhinoformPermission: !!(isRhinoformEnabled && getLoggedInUserPermissionNames(state)?.includes(FORM_VIEW)),
  };
};

export default connect(mapStateToProps)(AppointmentForms);
