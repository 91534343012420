import moment from 'moment-timezone';
import store from '../store';
import * as StringHelpers from './StringHelpers';
import * as DataHelpers from './DataHelpers';
import * as DateHelpers from './DateHelpers';
import * as PhoneHelpers from './PhoneHelpers';
import * as NumberHelpers from './NumberHelpers';
import { Types } from '../constants';
import { getLoggedInUserPermissionNames, getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { isMobile } from './BrowserHelpers';
import {
  THREAD_VIEW,
  TEAM_THREAD_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  CONVERSATION_TEAM_MOBILE,
  LIMITED_PROVIDER_ROLE,
} from '../constants/UserPermissionsConstants';

/**
 * If user has first and last name then do normal formatting, if unknown then display the formatted phone
 * @param  {object}  user
 * @param  {Boolean} [firstNameOnly=false]
 * @return {string}
 */
export function formatName(user, firstNameOnly = false, endUser = false, fromPhone = null) {
  let retVal = '';
  if (!user) return retVal;

  const currentState = store.getState();
  const { prefixes } = currentState.prefix;
  const { suffixes } = currentState.suffix;
  const { phones } = currentState.phone;
  const preferredName = isShowPreferredName(user) ? `(${user.preferredName})` : '';
  const suffix = user.suffixId ? suffixes[user.suffixId].value : '';

  if (firstNameOnly && user.firstName) {
    retVal = user.firstName;
  } else if (endUser && (DataHelpers.hasData(prefixes) && DataHelpers.hasData(user))) { // end user format
    retVal = user.prefixId === 4 ? `${prefixes[user.prefixId].value} ${user.lastName}` : `${user.firstName}`;
  } else if (DataHelpers.hasData(prefixes) && DataHelpers.hasData(suffixes) && DataHelpers.hasData(user) && (user.firstName || user.lastName)) {
    retVal = [user.prefixId ? prefixes[user.prefixId].value : '', user.firstName || '', user.lastName || '', suffix, preferredName].join(' ').trim();
  } else if (DataHelpers.hasData(phones) && DataHelpers.hasData(user) && user.phones && user.phones.length && phones[user.phones[0]]) {
    retVal = PhoneHelpers.formatPhone(phones[user.phones[0]].value);
  } else if (DataHelpers.hasData(user) && user.externalIds?.displayId) {
    retVal = `#${user.externalIds.displayId}`;
  } else if (DataHelpers.hasData(user) && user.externalIds && user.externalIds.emrId) {
    retVal = `#${user.externalIds.emrId}`;
  } else if (!retVal && fromPhone) {
    retVal = PhoneHelpers.formatPhone(fromPhone);
  }

  return retVal;
}

export function formatNamesForDynamicChat(users, currentUserId) {
  // remove the user you're logged in with, since you're not chatting with yourself...
  const usersWithoutCurrentUser = users.filter((user) => user.id !== currentUserId);
  const names = usersWithoutCurrentUser.map((user) => formatName(user)).join(', ');
  return names;
}

export function formatUserLabel(user, orgPhones = {}) {
  const { firstName, lastName, phones } = user;
  let userLabel = '';

  if (firstName || lastName) {
    userLabel = `${firstName || ''} ${lastName || ''}`;
  } else if (!firstName && !lastName && phones && phones.length > 0) {
    const userPhone = phones[0];
    userLabel = getPhoneLabel(userPhone) || getPhoneLabel(orgPhones[userPhone]);
  }

  return userLabel;
}

function getPhoneLabel(phone = {}) {
  return phone.number || phone.value;
}

/**
 * Member name formatting with lastName, prefix, firstName, suffix
 * @param  {object}  user
 * @return {string}
 */
export function formatMemberNameWithPrefixAndSuffix(user) {
  let memberName = '';
  if (!user) return memberName;

  const currentState = store.getState();
  const { prefixes } = currentState.prefix;
  const { suffixes } = currentState.suffix;
  const suffix = user.suffixId ? ` ${suffixes[user.suffixId].value}` : '';
  const firstName = suffix ? `${user.firstName},` : user.firstName;
  const prefix = user.prefixId ? ` ${prefixes[user.prefixId].value}` : '';
  if (DataHelpers.hasData(prefixes) && DataHelpers.hasData(suffixes) && DataHelpers.hasData(user) && user.firstName && user.lastName) {
    memberName = `${user.lastName},${prefix} ${firstName}${suffix}`;
  }
  return memberName;
}

/**
 * Member name formatting with lastName, prefix, firstName, suffix for all members, this is for all members in dropdown eg. member dropdown in audit log, analytics etc.
 * @param  {object}  user
 * @return {object}
 */
export const formatMemberNames = (members) => {
  const clonedMembers = DataHelpers.cloneDeep(members);
  Object.keys(clonedMembers).forEach((key) => {
    clonedMembers[key].memberName = formatMemberNameWithPrefixAndSuffix(clonedMembers[key]);
  });
  return clonedMembers;
};

/**
 * A slightly different implementation of `formatName` that only checks against the first/last name of a user and displays `Unknown` as the fallback
 *
 * @param  {object} user
 * @return {string}
 */
export function formatNameOrUnknown(user) {
  if (!user) return '';

  const {
    firstName,
    lastName,
    preferredName,
  } = user;

  const preferredNameFormatted = isShowPreferredName(user) ? ` (${preferredName})` : '';

  return firstName && lastName ? `${firstName} ${lastName}${preferredNameFormatted}` : 'Unknown';
}

/**
 * A slightly different implementation of `formatName` used in phone ownership display
 *
 * @param  {object} user
 * @return {string}
 */
export function formatPhoneOwnershipName(user) {
  if (!user) return '';

  const {
    userId,
    firstName,
    lastName,
    preferredName,
  } = user;

  const preferredNameFormatted = isShowPreferredName(user) ? ` (${preferredName})` : '';
  const formattedUnknown = userId > 0 ? 'Unknown' : '[This Contact]';

  return firstName ? `${firstName} ${lastName}${preferredNameFormatted}` : formattedUnknown;
}

export function formatFacebookName(user, facebookId, channelName) {
  // Set the return to facebookId in the case that an unknown user does not provide our app with first and last name.
  let formattedFacebookName = facebookId;

  if (DataHelpers.hasData(user) && user.firstName && user.lastName) {
    formattedFacebookName = [user.firstName, user.lastName].join(' ').trim();
  }

  if (channelName) { // append channel name to end of facebook name (used when user has multiple facebooks)
    formattedFacebookName = `${formattedFacebookName} (${channelName})`;
  }

  return formattedFacebookName;
}

export function formatInstagramName(user, instagramData, channelName) {
  // Set the return to instagramId in the case that an unknown user does not provide our app with name.
  let formattedInstagramName = instagramData?.username ? instagramData.username : instagramData?.value;

  if (!formattedInstagramName && DataHelpers.hasData(user) && user.firstName) {
    formattedInstagramName = `${user.firstName} ${user.lastName ? ` ${user.lastName}` : ''}`;
  }

  if (channelName) { // append channel name to end of facebook name (used when user has multiple facebooks)
    formattedInstagramName = `${formattedInstagramName} (${channelName})`;
  }

  return formattedInstagramName;
}

export function formatRhinogramName(user, rhinogram) {
  const formattedRhiongramName = rhinogram.name;
  return formattedRhiongramName;
}

export function formatTypes(user) {
  const currentState = store.getState();
  const { types } = currentState.type;

  return user.typeId ? StringHelpers.capitalize(types[user.typeId].value) : null;
}

export function isCCR(user) {
  let returnVal = false;

  if (user && user.isCcr) returnVal = true;

  return returnVal;
}

export function hasLimitedProviderRole(user) {
  return !!user?.roles?.some((role) => role.name === LIMITED_PROVIDER_ROLE);
}

export function formatHipaaStatus(hipaaStatusTypeId) {
  let hipaaStatus;

  if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_PENDING) {
    hipaaStatus = 'Unknown';
  } else if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_GRANTED) {
    hipaaStatus = 'Granted';
  } else if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_DECLINED) {
    hipaaStatus = 'Denied';
  }

  return hipaaStatus;
}

export function formatRhinoPayStatus(rhinopayStatusTypeId) {
  let rhinopayStatus;

  if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_PENDING) {
    rhinopayStatus = 'Unknown';
  } else if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_GRANTED) {
    rhinopayStatus = 'Granted';
  } else if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_DECLINED) {
    rhinopayStatus = 'Denied';
  }

  return rhinopayStatus;
}

export function formatMarketingStatus(rhinopayStatusTypeId) {
  let marketingStatus;

  if (rhinopayStatusTypeId === Types.TYPE_MARKETING_CONSENT_PENDING) {
    marketingStatus = 'Unknown';
  } else if (rhinopayStatusTypeId === Types.TYPE_MARKETING_CONSENT_GRANTED) {
    marketingStatus = 'Granted';
  } else if (rhinopayStatusTypeId === Types.TYPE_MARKETING_CONSENT_DECLINED) {
    marketingStatus = 'Denied';
  }

  return marketingStatus;
}

export function formatConsentStatus(consentStatus) {
  const consentStatuses = {
    Pending: 'Unknown',
    Declined: 'Denied',
    Granted: 'Granted',
  };
  return consentStatuses[consentStatus] || consentStatus || '';
}

export function formatEventConsentStatus(event) {
  if (event.modelVersion === '3.0' && event.consentStatus) {
    return formatConsentStatus(event.consentStatus);
  } else if (event.hipaaStatusTypeId) {
    return (formatHipaaStatus(event.hipaaStatusTypeId));
  } else if (event.rhinopayConsentStatusTypeId) {
    return (formatRhinoPayStatus(event.rhinopayConsentStatusTypeId));
  } else if (event.marketingConsentStatusTypeId) {
    return (formatMarketingStatus(event.marketingConsentStatusTypeId));
  } return '';
}

export function formatAppointmentStatus(appointmentStatusTypeId) {
  let appointmentStatus;

  if (appointmentStatusTypeId === Types.TYPE_APPOINTMENT_UNCONFIRMED) {
    appointmentStatus = 'Unconfirmed';
  } else if (appointmentStatusTypeId === Types.TYPE_APPOINTMENT_CONFIRMED) {
    appointmentStatus = 'Confirmed';
  } else if (appointmentStatusTypeId === Types.TYPE_APPOINTMENT_CANCELLED) {
    appointmentStatus = 'Cancel';
  }

  return appointmentStatus;
}

export function getHipaaDescription(hipaaStatusTypeId) {
  let descriptorText;

  if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_PENDING) {
    descriptorText = 'Before sending ePHI through texts, email, or social messaging, which may or may not be secure, click below to request consent from your patient or their legal guardian. You do not need consent to send ePHI through RhinoSecure.'; // eslint-disable-line max-len
  } else if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_GRANTED) {
    descriptorText = 'You can share ePHI through any available channel.';
  } else if (hipaaStatusTypeId === Types.TYPE_HIPAA_CONSENT_DECLINED) {
    descriptorText = 'You can use all channels, but ePHI should only be shared via RhinoSecure.';
  }

  return descriptorText;
}

export function getRhinoPayDescription(rhinopayStatusTypeId) {
  let descriptorText;

  if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_PENDING) {
    descriptorText = 'Before sending payment requests through texts, request consent from your patient or their legal guardian.';
  } else if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_GRANTED) {
    descriptorText = 'Payment requests can be sent to patient.';
  } else if (rhinopayStatusTypeId === Types.TYPE_RHINOPAY_CONSENT_DECLINED) {
    descriptorText = 'Payment requests cannot be sent to patient.';
  }

  return descriptorText;
}

export function getMarketingDescription(marketingConsentStatusTypeId) {
  let descriptorText;

  if (marketingConsentStatusTypeId === Types.TYPE_MARKETING_CONSENT_PENDING) {
    descriptorText = 'Before sending advertisements or telemarketing messages through texts, you must obtain written consent from your patient or their legal guardian.';
  } else if (marketingConsentStatusTypeId === Types.TYPE_MARKETING_CONSENT_GRANTED) {
    descriptorText = 'You can send advertisements or telemarketing messages.';
  } else if (marketingConsentStatusTypeId === Types.TYPE_MARKETING_CONSENT_DECLINED) {
    descriptorText = 'You cannot send advertisements or telemarketing messages.';
  }

  return descriptorText;
}

export function isMember(user) {
  if (!user) return false;

  return user.typeId ? user.typeId === Types.TYPE_MEMBER : false;
}

export function isPatient(user) {
  if (!user) return false;

  return user.typeId ? user.typeId === Types.TYPE_PATIENT : false;
}

export function isIntegrated(user) {
  if (!user) return false;

  return user.integrated;
}

export function isOrganization(user) {
  if (!user) return false;

  return user.typeId ? user.typeId === Types.TYPE_ORGANIZATION : false;
}

export function isUnknown(user) {
  if (!user) return false;

  return user.typeId ? user.typeId === Types.TYPE_UNKNOWN : false;
}

export function isOther(user) {
  if (!user) return false;

  return user.typeId ? user.typeId === Types.TYPE_USER_OTHER : false;
}

export function isContact(user) {
  return isPatient(user) || isOther(user) || isUnknown(user);
}

export function isMinor(user) {
  if (!user) return false;

  return moment().diff(moment(user.birthday, 'YYYY-MM-DD'), 'years') < 18;
}

export function formatAvatarName(firstName, lastName) {
  if (!firstName || !lastName) return null;

  return `${firstName[0]} ${lastName[0]}`;
}

/**
 * Determine if user has necessary permissions to access a resource.
 * @param {array} requiredPermissions
 * @param {array} [userPermissions] // will default to permissions for currentUser in the store.
 * @returns {boolean}
 * */
export function userHasAnyOfPermissions(requiredPermissions, userPermissions) {
  if (!requiredPermissions) return false;

  const currentUserPermissionNames = getLoggedInUserPermissionNames(store.getState());
  if (typeof userPermissions === 'undefined' && currentUserPermissionNames) {
    return requiredPermissions.some((permission) => currentUserPermissionNames.includes(permission));
  } else if (userPermissions) {
    return requiredPermissions.some((permission) => userPermissions.includes(permission));
  } else {
    return false;
  }
}

/**
 * Determine if user has all necessary permissions to access a resource.
 * @param {array} requiredPermissions
 * @param {array} [userPermissions] // will default to permissions for currentUser in the store.
 * @returns {boolean}
 * */
export function userHasAllOfPermissions(requiredPermissions, userPermissions) {
  if (!requiredPermissions) return false;

  const currentUserPermissionNames = getLoggedInUserPermissionNames(store.getState());
  if (typeof userPermissions === 'undefined' && currentUserPermissionNames) {
    return requiredPermissions.every((permission) => currentUserPermissionNames.includes(permission));
  } else if (userPermissions) {
    return requiredPermissions.every((permission) => userPermissions.includes(permission));
  } else {
    return false;
  }
}

export function generateTempPassword() {
  const passwordLength = 8;
  const minNumbers = 4;
  const maxNumbers = 5;
  const numberCount = NumberHelpers.randomIntInRange(minNumbers, maxNumbers + 1);
  const uppercaseLettersMin = 1;
  const uppercaseLettersMax = 2;
  const uppercaseLetterCount = NumberHelpers.randomIntInRange(uppercaseLettersMin, uppercaseLettersMax + 1);
  const uppercaseLetters = Array(26).fill().map((_, i) => String.fromCharCode('A'.charCodeAt(0) + i));
  const lowercaseLetters = Array(26).fill().map((_, i) => String.fromCharCode('a'.charCodeAt(0) + i));
  const numbers = Array(10).fill().map((_, i) => String.fromCharCode('0'.charCodeAt(0) + i)); // 0-9 as strings

  // Random letters
  let tempPassword = Array(passwordLength)
    .fill()
    .map(() => lowercaseLetters[NumberHelpers.randomIntInRange(0, lowercaseLetters.length)]);

  // Set 'numberCount' random indexes to a random, single digit number
  const numberIndexes = NumberHelpers.randomUniqueInt(numberCount, 0, numberCount + 1);
  numberIndexes.forEach((index) => { tempPassword[index] = numbers[NumberHelpers.randomIntInRange(0, 10)]; });

  // Set 'uppercaseLetterCount' random indexes to a random uppercase letter
  const uppercaseLetterIndexes = NumberHelpers.randomUniqueInt(uppercaseLetterCount, 0, uppercaseLetters.length);
  uppercaseLetterIndexes.forEach((index) => {
    const randomUppercaseLetter = uppercaseLetters[index];
    const randomTempPasswordIndex = NumberHelpers.randomIntInRange(0, passwordLength);

    tempPassword[randomTempPasswordIndex] = randomUppercaseLetter;
  });

  tempPassword = tempPassword.join('');

  return tempPassword;
}

// ^                   Beginning of expression
// (?=.*[a-z])         At least one lower case
// (?=.*[A-Z])         At least one upper case
// (?=.*[^a-zA-Z])     At least one non-letter
// (.{8})              At least 8 characters
// $                   End of expression
export function isSecurePassword(password) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])(.{8,})$/;

  return regex.test(password);
}

const groupMentionRegex = (groupId = null) => new RegExp(`{groupId-${groupId || '(.*?)'}}`, 'g');
const userMentionRegex = (userId = null) => new RegExp(`{userId-${userId || '(.*?)'}}`, 'g');

export function getExternalId(user) {
  if (!user || !user.externalIds) {
    return null;
  } else if (user.externalIds.displayId) {
    return user.externalIds.displayId;
  } else if (user.externalIds.emrId) {
    return user.externalIds.emrId;
  }

  return null;
}

export const formatMentionString = (text, eventMentions = []) => {
  let message = text;
  const regex = new RegExp('{@All}', 'g');
  message = message.replace(regex, '@All');
  const groupMentionStrings = text.match(groupMentionRegex());
  const userMentionStrings = text.match(userMentionRegex());
  if (groupMentionStrings?.length > 0) {
    groupMentionStrings.forEach((mentionString) => {
      const groupId = mentionString.replace(groupMentionRegex(), '$1');
      const groupName = eventMentions?.find((eventMention) => Number(eventMention.groupId) === Number(groupId))?.groupName;
      message = message.replace(groupMentionRegex(groupId), `@${groupName || ''}`);
    });
  } if (userMentionStrings?.length > 0) {
    userMentionStrings.forEach((mentionString) => {
      const userId = mentionString.replace(userMentionRegex(), '$1');
      const mentionedUser = eventMentions?.find((eventMention) => eventMention.userId === Number(userId));
      const replaceValue = mentionedUser ? `@${mentionedUser.userFirstName} ${mentionedUser.userLastName}` : '@';
      message = message.replace(userMentionRegex(userId), replaceValue);
    });
  }

  return message;
};

export const getMention = (mentionIds, mentions, userOrGroupId) => {
  const currentMentionId = mentionIds?.find((mentionId) => mentions[mentionId]?.userId === userOrGroupId || mentions[mentionId]?.groupId === userOrGroupId);
  return mentions[currentMentionId];
};

export const getCurrentUserMentionId = (mentionIds, mentions, currentUserId) => {
  const mention = mentionIds?.find((mentionId) => mentions[mentionId]?.userId === currentUserId);
  return mention;
};

export const getMentionIds = (eventIds, events, userId) => eventIds?.reduce((filtered, eventId) => {
  const eventMentions = events[eventId]?.eventMentions || [];
  const foundMention = eventMentions.find((mention) => mention.userId === userId);
  if (foundMention) {
    filtered.push(foundMention.id);
  }
  return filtered;
}, []);

export const fetchSignedText = (trustee, lastUpdatedAtTimestamp) => {
  if (!trustee) {
    return `Consent granted prior to ${DateHelpers.formatTimestamp(lastUpdatedAtTimestamp)}`;
  }

  return `Last updated: ${DateHelpers.formatTimestamp(lastUpdatedAtTimestamp)} by ${trustee.firstName} ${trustee.lastName}`;
};

export const fetchSignedTextMarketing = (trustee, lastUpdatedAtTimestamp) => {
  if (!lastUpdatedAtTimestamp) {
    return 'Consent never requested';
  }
  if (!trustee) {
    return `Consent granted prior to ${DateHelpers.formatTimestamp(lastUpdatedAtTimestamp)}`;
  }
  return `Last updated: ${DateHelpers.formatTimestamp(lastUpdatedAtTimestamp)} by ${trustee.firstName} ${trustee.lastName}`;
};

export const userHasVideoConferences = () => {
  let retval = false;
  const {
    video,
    auth: { currentUser },
  } = store.getState();
  if (currentUser) {
    const conferences = video?.conferences ?? [];
    const myConferences = conferences.filter((v) => v.memberIds.includes(currentUser));
    if (myConferences.length > 0) {
      retval = true;
    }
  }
  return retval;
};

export const getIncomingSoundPreference = (currentState, options) => {
  const orgSoundPreferencesEnabled = getLoggedInUserOrganization(currentState).isSoundPreferencesEnabled;
  const currentUserPreferences = getLoggedInUser(currentState)?.preferences;
  const currentUserSoundPreference = orgSoundPreferencesEnabled && !currentUserPreferences.userSoundPreferencesEnabled;
  // play sound if custom sound preferences aren't enabled
  if (!currentUserSoundPreference) return true;
  // if custom sound preferences are enabled, play sound if any qualifying preference is enabled
  const { mention, direct, groupIds, assigned, messageType, following } = options;
  const devicePreference = isMobile() ? 'mobile' : 'desktop';
  const assignmentPreference = assigned ? currentUserPreferences.inbox.find((preference) => preference.type === 'assigned')?.[devicePreference] : null;
  const followingPreference = following ? currentUserPreferences.inbox.find((preference) => preference.type === 'following')?.[devicePreference] : null;
  const mentionPreference = mention ? currentUserPreferences[messageType].find((preference) => preference.type === 'mentions')?.[devicePreference] : null;
  const directPreference = direct ? currentUserPreferences[messageType].find((preference) => preference.type === 'direct')?.[devicePreference] : null;
  const groupPreference = groupIds?.length > 0 ? (
    currentUserPreferences[messageType].find((preference) => groupIds.includes(preference.userGroupId))?.[devicePreference] ||
  currentUserPreferences.inboxAndChat.find((preference) => groupIds.includes(preference.userGroupId))?.[devicePreference]) : null;
  return (assignmentPreference || mentionPreference || directPreference || groupPreference || followingPreference);
};

export function hasContactThreadViewPermissions() {
  return isMobile() ?
    userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) :
    userHasAnyOfPermissions([THREAD_VIEW]);
}

export function hasTeamThreadViewPermissions() {
  return isMobile() ? userHasAllOfPermissions([TEAM_THREAD_VIEW, CONVERSATION_TEAM_MOBILE]) :
    userHasAnyOfPermissions([TEAM_THREAD_VIEW]);
}

export function hasAnyInboxViewPermissions() {
  return (hasContactThreadViewPermissions() || hasTeamThreadViewPermissions());
}

export function userHasLimitedProviderRole(roles) {
  if (!(roles && roles.length > 0)) return false;
  return roles.some((role) => role.name === LIMITED_PROVIDER_ROLE);
}

// No need to show preferred name if it is same as first name
function isShowPreferredName(user) {
  return user.preferredName && user.preferredName.trim().toUpperCase() !== user.firstName?.trim().toUpperCase();
}
