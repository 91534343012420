// session
export const SESSION_TIMEOUT_ALERT_BUFFER = 120; // seconds
export const SESSION_TIMEOUT_STORAGE_KEY = 'isSessionTimeoutModalVisible';
export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_EVENT_PAGE_SIZE = 20;

// event
export const THREAD_SIZE = DEFAULT_EVENT_PAGE_SIZE;
export const EVENT_SIZE = DEFAULT_EVENT_PAGE_SIZE;

// user
export const USER_RESULT_SIZE = 30;
export const SYSTEM_USER_ID = 50000;
export const CONTACT_RESULT_SIZE = DEFAULT_PAGE_SIZE;

export const GROUP_RESULT_SIZE = DEFAULT_PAGE_SIZE;

export const RHINOBLAST_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const RHINOBLAST_MAX_PAGE_SIZE = 250;
export const BULK_TAG_MAX_CONTACTS = 2000;
// upload
export const UPLOAD_INTERNAL_FILE_TYPES = '*';
export const UPLOAD_FILE_TYPES = 'image/jpeg,.jpg,.jpeg,image/gif,.gif,image/bmp,.bmp,image/png,.png,application/pdf,.pdf,text/vcard';
export const SUPPORTED_FILE_TYPES_FOR_PREVIEW = 'image/jpeg,.jpg,.jpeg,image/gif,.gif,image/bmp,.bmp,image/png,.png,application/pdf,text/x-vcard';
export const THUMBNAIL_FILE_EXTENSIONS = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/tif', 'image/webp'];
export const IMPORT_FILE_TYPES = '.txt,.csv,.xlsx';
export const MAX_MMS_FILE_SIZE = 1000000; // 1MB in bytes
export const MAX_STANDARD_FILE_SIZE = 5000000000; // 5 GB in bytes
export const MAX_FACEBOOK_FILE_SIZE = 26214400; // 25 MB in bytes
export const MAX_STANDARD_FILE_SIZE_TEXT = '5 GB';

// file and image
export const AVATAR_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/s3/download/avatar/`;
export const FILE_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/s3/download/file/`;
export const SAVED_CONTENT_FILE_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/s3/download/savedContentPDF/`;
export const RHINOFORM_BASE_URL = `${process.env.REACT_APP_URL}/settings/organization/library/forms/`;

// mime type
export const MIME_TYPE_APPLICATION_PDF = 'application/pdf';

// vcf type
export const VCARD_FILE_TYPE = 'text/x-vcard';

// Support email
export const SUPPORT_EMAIL = `${process.env.REACT_APP_SUPPORT_EMAIL}`;

// Billing
export const salesReps = [
  { id: -1, value: '--' },
  { id: 1, type: 'Sales', code: 'House', value: 'Chris Hare' },
  { id: 3, type: 'Sales', code: 'ZB', value: 'Zack Butterfield' },
];

// Payment
export const PAYMENT_TYPE_PAYROC = 'payroc';
export const PAYMENT_TYPE_BREEZE = 'breeze';
export const BREEZE_SAVING_ACCOUNT_TYPE = 'S';
export const BREEZE_CHECKING_ACCOUNT_TYPE = 'C';

export const RHINOPAY_TYPE = [
  { id: -1, value: '--' },
  { id: 1, value: 'Payroc', Key: PAYMENT_TYPE_PAYROC },
  { id: 2, value: 'Breeze', Key: PAYMENT_TYPE_BREEZE },
];

export const BREEZE_RESPONSE_CODE = 'A01';
export const BREEZE_EXCEPTION = 'exception';
export const PAYMENT_ERRORS = ['paymentType', 'merchantId', 'merchantToken', 'paymentApiUsername', 'paymentApiPassword', 'paymentGatewayId'];

// Password verbiage
export const PASSWORD_EXPLANATION_MESSAGE = 'Password must contain at least 8 characters; one uppercase, one lowercase, and one non-letter';
export const PASSWORD_STRENGTH_VALIDATION_MESSAGE = 'Password isn\'t secure enough!';
export const bankAccountTypes = [{ id: 1, value: 'Checking', code: 'CHQ' }, { id: 2, value: 'Saving', code: 'SAV' }];
export const PAGE_SIZE = 5;
export const RISK_JS_URL = 'https://static.wepay.com/min/js/risk.1.latest.js';

// Addon/Overages
export const MEMBER_PRODUCT = 'members';
export const CHANNEL_PRODUCT = 'businessline';
export const MESSAGE_PRODUCT = 'messages';
export const BASE_PRODUCT = 'rhinogram';

// Session timeout
export const SESSION_TIMEOUT_MINUTES_MIN = 5;
export const SESSION_TIMEOUT_MINUTES_MAX = 1440;

// Form placeholders
export const PHONE_PLACEHOLDER = '(xxx) xxx-xxxx';

// Messages
export const SMS_MESSAGE_LENGTH_WARNING = 'Messages over 160 characters may result in a split message based on the patient\'s carrier';
export const SMS_MESSAGE_LENGTH_WARNING_VARIABLES = `${SMS_MESSAGE_LENGTH_WARNING}. Message length including variables is estimated.`;
export const SMS_MESSAGE_LENGTH_MAX = 161;

// Error messages
export const CUSTOM_ERROR_TYPES = ['Billing API'];
export const BILLING_ERROR_MESSAGE = 'We\'re currently experiencing issues with our billing system. We are sorry for the inconvenience. Please try again or contact us if the issue persists.'; // eslint-disable-line max-len
// Widget
export const WIDGET_BASE_URL = `${process.env.REACT_APP_WIDGET_URL}/embed.js`;
// Organization Payment Methods
export const PAYMENT_METHODS = {
  CREDIT_CARD: 'credit',
  BANK: 'bank',
  OFFLINE_PAYMENT: 'offline_payment',
};

// Video conference limit for member
export const VIDEO_CONFERENCE_MEMBER_LIMIT = 5;

export const CALL_CHANNEL_LIMIT = 1;

// profile panel
export const NOTE_TRUNCATE_LENGTH = 210;
export const PROFILE_CONNECTED_PARTY_NOTE_TRUNCATE_LENGTH = 105;

// Prescriptions Panel
export const PRESCRIPTION_TEMPLATE_TRUNCATE_LENGTH = 10;

// dropdown checkbox items
export const conversationActionArray = [
  { id: 1, code: 'assignToMe', label: 'Assign To Me' },
  { id: 2, code: 'assign', label: 'Assign' },
  { id: 3, code: 'closeBulkConversations', label: 'Close Conversations' },
  { id: 4, code: 'follow', label: 'Follow' },
  { id: 5, code: 'unFollow', label: 'Unfollow' },
  { id: 6, code: 'markAsRead', label: 'Mark as Read' },
  { id: 7, code: 'markAsUnread', label: 'Mark as Unread' },
  { id: 8, code: 'completeAssignment', label: 'Assignment Complete' },
  { id: 9, code: 'closeMention', label: 'Close' },
  { id: 10, code: 'closeMentionForAllMembers', label: 'Close for All', description: 'Removes mention for all members - patient notes only' },
  {
    id: 11,
    code: 'rhinoBlast',
    label: 'RhinoBlast',
    labelDisabled: 'RhinoBlast (unavailable)',
    description: `Max of ${RHINOBLAST_DEFAULT_PAGE_SIZE} Contacts at a time`,
    descriptionDisabled: `Max of ${RHINOBLAST_DEFAULT_PAGE_SIZE} Contacts exceeded`,
    disabled: false,
  },
];

export const conversationTypeList = [
  { id: 1, label: 'All', description: 'All Selected', isChecked: true, associatedLabel: 'All Selected' },
  { id: 2, label: 'None', description: 'None', isChecked: false, actions: [], associatedLabel: '' },
  { id: 3, label: 'Read', description: 'Read', isChecked: true, associatedLabel: '' },
  { id: 4, label: 'Unread', description: 'Unread', isChecked: true, associatedLabel: '' },
  { id: 5, label: 'Assigned', description: 'Assigned', isChecked: true, associatedLabel: '' },
  { id: 6, label: 'Not Assigned', description: 'Not Assigned', isChecked: true, associatedLabel: '' },
  { id: 7, label: 'Following', description: 'Following', isChecked: true, associatedLabel: '' },
  { id: 8, label: 'Not Following', description: 'Not Following', isChecked: true, associatedLabel: '' },
];

// PLAN Categories
export const PLAN_LEGACY = 'legacy_plans';
export const PLAN_SEPTEMBER_2018 = 'rhinogram_sept_2018';

// Product Categories
export const VARIABLE_QUANTITY = 'variable_quantity';
export const FIXED_QUANTITY = 'fixed_quantity';

// Bottom Pagination Thread Size
export const BOTTOM_PAGINATION_CONTROLS_VISIBILITY_THRESHOLD = 8;
// record locking types
export const USER_RECORD_TYPE_ID = 75;

// analytics
export const SECONDS_TO_MINUTES = 60;

export const DEFAULT_ANALYTICS_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const APPOINTMENTS_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const PROVIDER_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const RHINOPAY_REQUESTS_PAGE_SIZE = DEFAULT_PAGE_SIZE;
export const FORMS_MANAGER_PAGE_SIZE = DEFAULT_PAGE_SIZE;

export const CONTACT = 'contact';
export const PRACTICE = 'practice';
export const NONE = 'none';
export const ALL = 'all';

export const USER_PRESENCE = { // leaving flexibility for adding more status types
  ONLINE: 'online',
  OFFLINE: 'offline',
};

// RhinoPay CC type
export const RHINOPAY_CREDIT_CARD_TYPE_UNKNOWN = 'unknown';

// RhinoPay CC length
export const CREDIT_CARD_LENGTH = 13;

// Audit Log page size
export const AUDIT_LOG_PAGE_SIZE = DEFAULT_EVENT_PAGE_SIZE;

// Appointment type list size
export const CAMPAIGN_SELECT_LIST_SIZE = 10;

// APPOINTMENT MANAGER COLUMN WIDTH
export const CHECKBOX_COLUMN_WIDTH = 34;
export const APPOINTMENT_STATUS_CLASS_ID = 22;

export const HIPAA = 'hipaa';
export const RHINOPAY = 'rhinopay';
export const MARKETING = 'marketing';
export const ENGLISH_LANGUAGE_ID = 38;

export const BREAKPOINT_SMALL = 767;
export const MINIMUM_SYSTEM_ALERT_HEIGHT = 38;
export const COPY_DOCUMENT_DEFAULT_LIMIT = 6;

// Rhinopay error message
export const RHINOPAY_ERROR_MESSAGE = 'An error occured processing your payment. Please check the fields and try again.';

export const BREEZE_PAYMENT_TYPE = 'breeze';

// These are some system roles that are not visible to member in Member Profile
export const INTERNAL_ROLES = ['CSR', 'Patient'];

export const FUSEBILL_RECAPTCHA_SITE_KEY = process.env.REACT_APP_FUSEBILL_RECAPTCHA_SITE_KEY;

// file types that are not supported for save content.
export const NON_SUPPORTED_CONTENT = ['gif'];

// Selection actions for direct chats
export const chatconversationTypeList = [
  { id: 1, label: 'All', description: 'All Selected', isChecked: true, associatedLabel: 'All Selected' },
  { id: 2, label: 'None', description: 'None', isChecked: false, actions: [], associatedLabel: '' },
  { id: 3, label: 'Read', description: 'Read', isChecked: true, associatedLabel: '' },
  { id: 4, label: 'Unread', description: 'Unread', isChecked: true, associatedLabel: '' },
];

// dropdown checkbox items
export const chatConversationActionArray = [
  { id: 1, code: 'markAsRead', label: 'Mark as Read' },
  { id: 2, code: 'markAsUnread', label: 'Mark as Unread' },
  { id: 3, code: 'closeChat', label: 'Close' },
];

export const iconTypeSignature = 'signature';
export const iconTypeAttachment = 'attachment';
export const iconTypeVCard = 'vcard';

// list of cookies that we got from jotform to authenticate the user.
export const JOTFORM_COOKIES = ['jotApi_ent', 'jcm', 'jtuc_ent', 'ENTERPRISE_SESSION', 'hipaaLogin'];

// search default size
export const SEARCH_SIZE = DEFAULT_PAGE_SIZE;

export const CONTACT_PAGE_OPTIONS = {
  contacts: 'contacts',
  contactList: 'contactList',
};

export const MODAL_OPTIONS = {
  bulkTags: 'isBulkTagModalOpen',
  bulkMessage: 'isBulkMessageModalOpen',
};

export const PROFILE_SUMMARY_APPOINTMENTS_LIST_SIZE = 3;

export const defaultCopyLinkText = 'copy link';
export const clickedCopyLinkText = 'copied!';

export const DRAFT_STORAGE_KEYS = {
  default: 'draft',
  attachments: 'attachments',
  mentions: 'mentions',
  messageForms: 'messageForms',
  sharelinkFiles: 'sharelinkFiles',
  optimisticEvents: 'optimisticEvents',
};

export const accountPriorities = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
];

export const PRESCRIPTION_FILE_UPLOAD_INFO = 'Please Note: Attachments will be sent for both New Prescription and Refill notifications.';
export const OPT_OUT_LANGUAGE = 'Text STOP to opt out.';

// Toast notification dismiss times.
// 3000ms we used as default in whole application.
export const NORMAL_DISMISS_TIME = 3000;

export const RHINOFORM_ADMIN_ROLE_ID = 9;
export const INTEGRATED_CONTACT_EDITOR_ROLE_ID = 10;
export const LIMITED_PROVIDER_ROLE_ID = 11;
