import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DropdownMultiSelectAdvanced } from 'rhinostyle';
import { useDropdownMultiSelect } from '../hooks';
import { syncTags } from '../helpers/TagHelpers';

const TagsDropdown = ({
  disabled,
  value: selectedIds,
  handleChange,
  inline,
}) => {
  const tags = useSelector((state) => state.tag.tags);
  const itemIds = useSelector((state) => state.tag.tagIds) || [];
  const items = syncTags(tags, itemIds);

  const {
    shapedItems,
    filteredIds,
    sortDirection,
    filterItems,
    handleUpdateSelectedIds,
    handleClearSelectedIds,
    handleSort,
  } = useDropdownMultiSelect({
    name: 'tagIds',
    items,
    itemIds,
    updateFilters: handleChange,
    titleField: 'name',
    shapeLabel,
  });
  function getDropdownLabel() {
    if (selectedIds.length > 0 && itemIds.length > 0) {
      return selectedIds.map((tagId) => `#${items[tagId].name}`).join(', ');
    }
    return 'Tags';
  }

  function shapeLabel(title) {
    return (
      <span className="u-p-l-small">{title ? `#${title}` : ''}
      </span>
    );
  }

  return (
    <DropdownMultiSelectAdvanced
      itemSearchLoading={!itemIds.length}
      handleUpdateSelectedIds={handleUpdateSelectedIds}
      items={shapedItems}
      selectedItems={shapedItems}
      selectedItemsIds={selectedIds}
      handleClearAllSelectedItems={handleClearSelectedIds}
      itemsIds={filteredIds}
      fetchAllItems={filterItems}
      handleSelectAllItems={handleUpdateSelectedIds}
      showSelectAll
      dropdownLabel={getDropdownLabel()}
      interfaceLeft
      filterName="Tags"
      disabled={disabled}
      blockGroup={false}
      dropDownItemClass="u-p-t-small"
      dropDownClass="filter-page__dropdown filter-page__dropdown--wide"
      wide
      dataCypress="dropdownMultiSelect-Tags"
      sortable
      handleSort={handleSort}
      sortDirection={sortDirection}
      inline={inline}
    />
  );
};
TagsDropdown.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};
export default TagsDropdown;
