import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Icon,
  UtilitySystem,
} from 'rhinostyle';
import cx from 'classnames';

import Navigation from './Navigation';
import SettingsMenu from './SettingsMenu';
import ManagerMenu from './ManagerMenu';
import ContactsMenu from './ContactsMenu';
import AnalyticsMenu from './AnalyticsMenu';
import SecureNavigation from './SecureNavigation';
import RhinophoneMenu from './RhinophoneMenu';
import { changePatientExperienceUserAndOrg } from '../reducers/authReducer';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import StorageService from '../services/StorageService';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { uiSelectors } from '../selectors';
import { getMentionBadgeCount } from '../helpers/UIHelpers';
import {
  AUTOMATED_MESSAGE_VIEW,
  CHANNEL_VIEW,
  CONTACT_VIEW,
  GROUP_VIEW,
  MEMBER_VIEW,
  OUT_OF_OFFICE_VIEW,
  OFFICE_VIEW,
  ROLE_VIEW,
  TAG_VIEW,
  TEMPLATE_MESSAGE_VIEW,
  BILLING_VIEW,
  ANALYTICS_VIEW,
  AUDIT_LOG_VIEW,
  ORGANIZATION_PREFERENCES_VIEW,
  ORGANIZATION_PROFILE_VIEW,
  APPOINTMENT_MANAGER_VIEW,
  RHINOPAY_MANAGER_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  APPOINTMENT_TYPE_VIEW,
  FORM_VIEW,
  ROUTING_MANAGER_VIEW,
  PRESCRIPTION_NOTIFICATION_VIEW,
  PRESCRIPTION_MANAGER_VIEW,
  THREAD_VIEW,
} from '../constants/UserPermissionsConstants';
import { UserHelpers, UIHelpers } from '../helpers';
import Panels from '../helpers/PanelHelpers';
import { isMobile } from '../helpers/BrowserHelpers';
import rhinoTansparentTextLogo from '../assets/images/rhinogram-logo-reversed.svg';
import RhinocallStatus from './RhinocallStatus';
import AccessibeButton from './AccessibeButton';

const { userHasAnyOfPermissions, userHasAllOfPermissions, hasAnyInboxViewPermissions } = UserHelpers;

// This list should match 1:1 with the items in the 'cog' dropdown menu.
// If a user does not have at least one of these permissions we hide the 'cog' altogether since their dropdown will be empty.
const headerDropdownPermissions = [
  AUTOMATED_MESSAGE_VIEW,
  CHANNEL_VIEW,
  GROUP_VIEW,
  MEMBER_VIEW,
  OUT_OF_OFFICE_VIEW,
  OFFICE_VIEW,
  ROLE_VIEW,
  TAG_VIEW,
  TEMPLATE_MESSAGE_VIEW,
  BILLING_VIEW,
  AUDIT_LOG_VIEW,
  ORGANIZATION_PREFERENCES_VIEW,
  ORGANIZATION_PROFILE_VIEW,
  APPOINTMENT_MANAGER_VIEW,
  RHINOPAY_MANAGER_VIEW,
  APPOINTMENT_TYPE_VIEW,
  PRESCRIPTION_NOTIFICATION_VIEW,
  FORM_VIEW,
];

const NavigationWrapper = (props) => {
  // Declarations
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const settingsRef = useRef();

  // Props
  const {
    activeVideos,
    assignedSection,
    channels,
    chatSection,
    currentOrganization,
    currentUser,
    followingSection,
    inboxSection,
    isRhinoformEnabled,
    isRhinopayEnabled,
    isRhinophoneEnabled,
    mentionsSection,
    myUsersRaw,
    organizations,
    patientMenuOrganizationIds,
    patientMenuOrganizations,
    totalUnreadCount,
    isLimitedProvider,
  } = props;

  const isBulkMessagingEnabled = currentOrganization?.isBulkMessagingEnabled;

  // State
  const [selectedParentNav, setSelectedParentNav] = useState(null);
  const [selectedSubMenuNav, setSelectedSubMenuNav] = useState(null);
  const [selectedInboxSubMenuNav, setSelectedInboxSubMenuNav] = useState(null);

  // Effects
  useEffect(() => {
    const currentURL = location ? location.pathname : window.location.pathname;
    Panels.navInit();
    setSelectedParentNav(currentURL.substring(1).split('/')[0]);
  }, []);

  useEffect(() => {
    const currentURL = location ? location.pathname : window.location.pathname;
    const pathName = currentURL.substring(1).split('/');
    // for member profile/preferences Pages
    const memberSubpages = (pathName.includes('profile') || pathName.includes('preferences')) && !pathName.includes('organization');
    if (pathName.includes('managers')) {
      setSelectedSubMenuNav(pathName[1] || null);
      setSelectedParentNav('managers');
    } else if (!memberSubpages) {
      setSelectedSubMenuNav(pathName[2] || null);
      setSelectedInboxSubMenuNav(pathName[1] || null);
      setSelectedParentNav(pathName[0]);
    } else {
      setSelectedSubMenuNav(null);
      setSelectedInboxSubMenuNav(null);
    }
  }, [window.location, location]);

  // Handlers
  const handleNavigationClick = checkOpenNav;

  const handleChangeOrg = (orgId, userId, channelId) => {
    dispatch(changePatientExperienceUserAndOrg(orgId, userId))
      .then(() => {
        if (channelId) {
          history.push(`/secure/org/${orgId}/channel/${channelId}`);
        } else {
          history.push(`/secure/org/${orgId}/channel/all`);
        }
      });
    checkOpenNav();
  };

  const handleSidebarClick = (route, name) => {
    const parentNav = route.split('/')[0];
    setSelectedParentNav(parentNav);
    if (isLimitedProvider) {
      handleNavigationClick(name);
      if (name === 'assign-to-me') {
        history.replace('/axium/inbox');
      } else if (name === 'mentions') {
        history.replace('/axium/inbox/mentions');
      } else if (name === 'teamDirect') {
        history.replace('/chat');
      }
    }
  };

  const handleVideoStatusClick = () => {
    const video = activeVideos.find((v) => v.memberIds.includes(currentUser.id));
    if (video?.userIds) history.push(`/inbox/all/user/${video.userIds[0]}`);
  };

  // Functions
  function checkOpenNav() {
    if (Panels.mobileNavTimeline.progress() === 1) {
      Panels.toggleNav();
    }
  }

  function getNavLinkClass(path) {
    const route = getRouteOfNav(path);
    return cx('app-navigation__sidebar__button', {
      [UtilitySystem.config.classes.active]: selectedParentNav === route,
    });
  }

  function memberHasActiveVideo() {
    return activeVideos.find((v) => v.memberIds.includes(currentUser.id));
  }

  function getVideoStatusClass() {
    const video = memberHasActiveVideo();
    let status = 'Connected';
    if (!video.participantCount || video.participantCount < 2) {
      status = 'Waiting...';
    }
    return cx('app-navigation__sidebar__button__video', {
      'u-bg-success': status === 'Connected',
      'u-bg-warning': status === 'Waiting...',
    });
  }

  function getVideoStatus() {
    const video = memberHasActiveVideo();
    let status = 'Connected';
    if (!video.participantCount || video.participantCount < 2) {
      status = 'Waiting...';
    }
    return status;
  }

  function getRouteOfNav(path) {
    const currentPath = window.location.pathname;
    // to handle member subpages(profile and preference)
    const isMemberSubPage = currentPath.includes('settings');
    if (path === 'contacts') {
      if (isMemberSubPage) {
        return selectedParentNav === 'contacts' ? 'contacts' : 'contact-list';
      } else {
        return currentPath.includes('contact-list') ? 'contact-list' : 'contacts';
      }
    } else if (path === 'inbox') {
      if (isMemberSubPage) {
        return selectedParentNav === 'inbox' ? 'inbox' : 'chat';
      } else {
        return currentPath.includes('chat') ? 'chat' : 'inbox';
      }
    } else {
      return path;
    }
  }

  const showRhinophoneMenu = isRhinophoneEnabled && (
    isMobile() ? userHasAllOfPermissions([THREAD_VIEW, CONVERSATION_CONTACT_MOBILE]) : userHasAnyOfPermissions([THREAD_VIEW]));

  const renderSubMenu = () => {
    const pathName = selectedParentNav;
    const secureProps = {
      channels,
      organizationIds: patientMenuOrganizationIds,
      organizations: patientMenuOrganizations,
      changeOrg: handleChangeOrg,
    };

    const threadCreatePermission = isMobile() ? userHasAllOfPermissions(['THREAD_MESSAGE_CREATE', 'CONVERSATION_CONTACT_MOBILE']) :
      userHasAnyOfPermissions(['THREAD_MESSAGE_CREATE']);
    const navigationProps = {
      currentUser,
      handleNavigationClick,
      inboxSection,
      chatSection,
      followingSection,
      assignedSection,
      organizations,
      mentionsSection,
      isBulkMessagingEnabled,
      isRhinophoneEnabled,
      hasBulkMessagingPermission: userHasAnyOfPermissions(['BULK_MESSAGING_EDIT']) && threadCreatePermission,
      selectedSubMenuNav,
      selectedParentNav,
      selectedInboxSubMenuNav,
      settingsRef,
      history,
      location,
      myUsersRaw,
    };
    let subMenu;
    if (StorageService.readEntry('patientExperience')) {
      subMenu = <SecureNavigation {...secureProps} />;
    } else if (pathName === 'managers') {
      const hasManagersPermission = userHasAnyOfPermissions([APPOINTMENT_MANAGER_VIEW,
        RHINOPAY_MANAGER_VIEW, FORM_VIEW, ROUTING_MANAGER_VIEW, PRESCRIPTION_MANAGER_VIEW]) || isBulkMessagingEnabled;
      subMenu = hasManagersPermission ? <ManagerMenu {...navigationProps} /> : <Navigation {...navigationProps} />;
    } else if (pathName === 'phone' && showRhinophoneMenu) {
      subMenu = <RhinophoneMenu {...navigationProps} />;
    } else if (
      pathName === 'settings'
      // || window.location.pathname.includes('organization')
    ) {
      subMenu = <SettingsMenu {...navigationProps} />;
    } else if (pathName === 'contacts' || pathName === 'contact-list') {
      subMenu = <ContactsMenu {...navigationProps} />;
    } else if (pathName === 'analytics') {
      const hasAnalyticsViewPermission = userHasAnyOfPermissions(['ANALYTICS_VIEW']);
      subMenu = hasAnalyticsViewPermission ? <AnalyticsMenu {...navigationProps} /> : <Navigation {...navigationProps} />;
      // eslint-disable-next-line max-len
    } else if (['inbox', 'chat', 'selectorg', 'accountsetup', 'systemalert', 'saved-content'].includes(pathName)) {
      subMenu = <Navigation {...navigationProps} />;
    }
    return subMenu;
  };

  const hasContactPermission = isMobile() ? userHasAllOfPermissions([CONTACT_VIEW, CONVERSATION_CONTACT_MOBILE]) : userHasAnyOfPermissions([CONTACT_VIEW]);
  const isPatientExperience = StorageService.readEntry('patientExperience');

  const appNavClass = cx('app-navigation-sidebar', {
    'app-navigation-sidebar-limited': (isLimitedProvider),
    'app-navigation-sidebar--analytics': (selectedParentNav === 'analytics'),
    'app-navigation-sidebar--settings': (selectedParentNav === 'settings'),
    'app-navigation-sidebar--managers': (selectedParentNav === 'managers'),
    'app-navigation-sidebar--contacts': (selectedParentNav === 'contacts' || selectedParentNav === 'contact-list'),
    'app-navigation-sidebar--phone': (selectedParentNav === 'phone'),
  });

  function showManagersTab() {
    return (
      !isPatientExperience && (
        (userHasAnyOfPermissions([APPOINTMENT_MANAGER_VIEW]) && !!currentOrganization?.isAppointmentManagerEnabled) ||
        (userHasAnyOfPermissions([PRESCRIPTION_MANAGER_VIEW]) && !!currentOrganization?.isPrescriptionManagerEnabled) ||
        (userHasAnyOfPermissions([FORM_VIEW]) && isRhinoformEnabled) ||
        (userHasAnyOfPermissions([RHINOPAY_MANAGER_VIEW]) && isRhinopayEnabled) ||
        (userHasAnyOfPermissions([ROUTING_MANAGER_VIEW]) && !!currentOrganization?.isRoutingManagerEnabled) ||
        isBulkMessagingEnabled)
    );
  }

  const limitedNavOptions = [
    {
      name: 'assign-to-me',
      title: 'Inbox',
      id: 'sidebar-nav-inbox',
      route: 'inbox',
      icon: 'inbox',
      dataFeatureTag: 'nav-inbox-main',
      isVisible: true,
    },
    {
      name: 'mentions',
      title: 'Inbox',
      id: 'sidebar-nav-mentions',
      route: 'Inbox',
      icon: 'mentions',
      dataFeatureTag: 'nav-mentions-main',
      isVisible: true,
    },
    {
      name: 'teamDirect',
      title: 'Inbox',
      id: 'sidebar-nav-teamDirect',
      route: 'chat',
      icon: 'team-inbox',
      dataFeatureTag: 'nav-teamDirect-main',
      isVisible: true,
    },
  ];

  const navOptions = [
    {
      name: 'inbox',
      title: 'Inbox',
      id: 'sidebar-nav-inbox',
      route: 'inbox',
      icon: 'inbox',
      dataFeatureTag: 'nav-inbox-main',
      isVisible: hasAnyInboxViewPermissions() && !isPatientExperience,
    },
    {
      name: 'contacts',
      title: 'Contacts',
      id: 'sidebar-nav-contacts',
      route: 'contacts',
      icon: 'contacts',
      dataFeatureTag: 'nav-contacts-main',
      isVisible: hasContactPermission,
    },
    {
      name: 'managers',
      title: 'Managers',
      id: 'sidebar-nav-manager',
      route: 'managers',
      icon: 'manager',
      dataFeatureTag: 'nav-manager-main',
      isVisible: showManagersTab(),
    },
    {
      name: 'settings',
      title: 'Settings',
      id: 'sidebar-nav-settings',
      route: 'settings',
      icon: 'cog',
      dataFeatureTag: 'nav-settings-main',
      isVisible: userHasAnyOfPermissions(headerDropdownPermissions) && !isPatientExperience,
    },
    {
      name: 'analytics',
      title: 'Analytics',
      id: 'sidebar-nav-analytics',
      route: 'analytics/messaging',
      icon: 'analytics',
      dataFeatureTag: 'nav-analytics-main',
      isVisible: userHasAnyOfPermissions([ANALYTICS_VIEW]),
    },
    {
      name: 'phone',
      title: 'RhinoPhone',
      id: 'sidebar-nav-phone',
      route: 'phone',
      icon: 'phone',
      dataFeatureTag: 'nav-phone-main',
      isVisible: showRhinophoneMenu,
    },
  ];

  const renderTotalUnreadCount = (name) => {
    if (name === 'inbox' && totalUnreadCount) {
      return (<span className="app-navigation__sidebar__button__unread-counts">{totalUnreadCount}</span>);
    } return null;
  };

  const renderTotalUnreadCountLimited = (name) => {
    const mentionBadgeCount = getMentionBadgeCount(mentionsSection);
    const directChatUnreadCount = chatSection.find((section) => section.type === 'direct')?.unreadCount;
    if (name === 'assign-to-me' && assignedSection?.unreadUserIds?.length > 0) {
      return (<span className="app-navigation__sidebar__button__unread-counts">{assignedSection?.unreadUserIds?.length}</span>);
    } else if (name === 'mentions' && mentionBadgeCount > 0) {
      return (<span className="app-navigation__sidebar__button__unread-counts">{mentionBadgeCount}</span>);
    } else if (name === 'teamDirect' && directChatUnreadCount > 0) {
      return (<span className="app-navigation__sidebar__button__unread-counts">{directChatUnreadCount}</span>);
    } return null;
  };

  const renderNavIcons = () =>
    navOptions
      .filter((option) => option.isVisible)
      .map((option) => (
        <div
          id={option.id}
          className={getNavLinkClass(option.name)}
          onClick={() => handleSidebarClick(option.route)}
          data-feature-tag={option.dataFeatureTag}
          key={option.id}
        >
          {renderTotalUnreadCount(option.name)}
          <Icon icon={option.icon} />
        </div>
      ));

  const renderLimitedNavIcons = () =>
    limitedNavOptions
      .filter((option) => option.isVisible)
      .map((option) => (
        <div
          id={option.id}
          className={getNavLinkClass(option.name)}
          onClick={() => handleSidebarClick(option.route, option.name)}
          data-feature-tag={option.dataFeatureTag}
          key={option.id}
        >
          {renderTotalUnreadCountLimited(option.name)}
          <Icon icon={option.icon} />
        </div>
      ));

  const renderVideoStatusIcon = () => {
    if (memberHasActiveVideo()) {
      return (
        <>
          <div onClick={handleVideoStatusClick} id="sidebar-nav-video" className={getVideoStatusClass()}>
            <Icon icon="video" />
          </div>
          <div className="app-navigation__sidebar__button__video-status">{getVideoStatus()}</div>
        </>
      );
    } return null;
  };

  return (
    <div className={appNavClass}>
      <div className="app-navigation__top">
        <div className="app-navigation__logo">
          <img className="u-img-fluid" src={rhinoTansparentTextLogo} alt="Rhinogram" />
        </div>
        {isLimitedProvider ? renderLimitedNavIcons() : renderNavIcons()}
        {renderVideoStatusIcon()}
        <RhinocallStatus />
      </div>
      <div className="app-navigation__bottom">
        <AccessibeButton />
      </div>
      {!isLimitedProvider && renderSubMenu()}
    </div>
  );
};

NavigationWrapper.propTypes = {
  activeVideos: PropTypes.array,
  assignedSection: PropTypes.object.isRequired,
  billing: PropTypes.object,
  channels: PropTypes.object.isRequired,
  chatSection: PropTypes.array.isRequired,
  currentOrganization: PropTypes.object,
  currentUser: PropTypes.object,
  followingSection: PropTypes.object.isRequired,
  history: PropTypes.object,
  inboxSection: PropTypes.array.isRequired,
  isRhinoformEnabled: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool,
  isRhinophoneEnabled: PropTypes.bool,
  location: PropTypes.object,
  mentionsSection: PropTypes.object,
  myUserIds: PropTypes.array,
  myUsers: PropTypes.object,
  myUsersRaw: PropTypes.array.isRequired,
  navigationOpen: PropTypes.bool.isRequired,
  organizationIds: PropTypes.array.isRequired,
  organizations: PropTypes.object.isRequired,
  patientMenuOrganizationIds: PropTypes.array.isRequired,
  patientMenuOrganizations: PropTypes.object.isRequired,
  totalUnreadCount: PropTypes.number,
  isLimitedProvider: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { ui, organization, secure, user, billing, pay, rhinovideo, form, rhinocall } = state;
  const activeVideos = rhinovideo.conferences.filter((v) => !v.durationMs);

  return {
    activeVideos,
    assignedSection: uiSelectors.getAssignedSection(state),
    billing: billing.billing,
    channels: secure.channels,
    chatSection: uiSelectors.getChatSection(state),
    convoOpen: ui.convoOpen,
    currentOrganization: getCurrentOrg(state),
    currentUser: getLoggedInUser(state),
    followingSection: uiSelectors.getFollowingSection(state),
    inboxSection: uiSelectors.getInboxSection(state),
    isRhinoformEnabled: !!form.org?.isRhinoformEnabled,
    isRhinopayEnabled: pay.merchant.isRhinopayEnabled,
    isRhinophoneEnabled: rhinocall?.org?.isRhinophoneEnabled,
    mentionsSection: uiSelectors.getMentionsSection(state),
    myUserIds: user.myUserIds,
    myUsers: user.myUsers,
    myUsersRaw: user.myUsersRaw,
    navigationOpen: ui.navigationOpen,
    organizationIds: organization.organizationIds,
    organizations: organization.organizations,
    patientMenuOrganizationIds: secure.organizationIds,
    patientMenuOrganizations: secure.organizations,
    profileOpen: ui.profileOpen,
    totalUnreadCount: UIHelpers.getTotalUnreadCount(state),
    users: user.users,
    isLimitedProvider: userHasLimitedProviderRole(state),
  };
};

export default connect(mapStateToProps)(NavigationWrapper);
